// src/components/CustomInputField.js

import React, { useState, useEffect } from 'react';

const CustomFieldInput = ({ fieldId, initialValue, onChange, onBlur, isEditing, dataType }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChange && isEditing) {
      onChange(fieldId, newValue);
    }
  };

  const handleFieldBlur = () => {
    if (onBlur && isEditing) {
      onBlur(fieldId);
    }
  };

  if (!isEditing) {
    return <div className="field-value">{value || 'No value'}</div>;
  }

  switch (dataType) {
    case 'SINGLE_LINE':
    case 'TEXT':
      return (
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleFieldBlur}
          className="form-control"
        />
      );
    case 'MULTI_LINE':
    case 'LARGE_TEXT':
      return (
        <textarea
          value={value}
          onChange={handleChange}
          onBlur={handleFieldBlur}
          className="form-control"
        />
      );
    case 'NUMBER':
      return (
        <input
          type="number"
          value={value}
          onChange={handleChange}
          onBlur={handleFieldBlur}
          className="form-control"
        />
      );
    default:
      return (
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleFieldBlur}
          className="form-control"
        />
      );
  }
};

export default CustomFieldInput;
