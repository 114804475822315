import React from 'react';
import PropTypes from 'prop-types';

const CallList = ({ calls, onSelectContact }) => {
  if (!calls || calls.length === 0) {
    return <div className="no-calls-message">No contacts found for the selected date.</div>;
  }

  // Sort the calls array in descending order based on lastMessageDate
  const sortedCalls = [...calls].sort((a, b) => {
    return new Date(b.lastMessageDate) - new Date(a.lastMessageDate);
  });

  return (
    <div className="call-list-container">
      <ul className="call-list">
        {sortedCalls.map((call) => (
          <li 
            key={call.id} 
            onClick={() => onSelectContact(call.contactId)}
            className="call-list-item"
          >
            <div className="call-list-item-avatar">
              {call.contactName ? call.contactName.charAt(0).toUpperCase() : '?'}
            </div>
            <div className="call-list-item-content">
              <div className="call-list-item-name">{call.contactName || 'Unknown'}</div>
              <div className="call-list-item-date">{new Date(call.lastMessageDate).toLocaleString()}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

CallList.propTypes = {
  calls: PropTypes.array.isRequired,
  onSelectContact: PropTypes.func.isRequired,
};

export default CallList;