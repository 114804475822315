// /components/TaskBoard/TaskBoard.js

import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Snackbar, Alert, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { FaPlus } from 'react-icons/fa';
import Pipeline from './PipelineTaskBoard';
import { useTaskBoard } from '../context/TaskBoardContext';
import { prefixId } from '../utils/prefixIdTaskboard';
import styles from '../styles/TaskBoard.module.css';
import { auth } from '../firebase';

const TaskBoard = () => {
  const { state, dispatch, isLoading, error, clearError } = useTaskBoard();
  const { pipelines, cards } = state;
  const [user, setUser] = useState(auth.currentUser);
  const [showAddPipelineDialog, setShowAddPipelineDialog] = useState(false);
  const [newPipelineName, setNewPipelineName] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const onDragEnd = (result) => {
    const { source, destination, draggableId, type } = result;

    if (!destination) return;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Implement drag and drop logic here
    // This will dispatch actions to update the state based on the drag and drop operation
    // You'll need to handle different types: 'PIPELINE', 'STAGE', 'BUCKET', 'CARD'

    dispatch({ type: 'MOVE_CARD', payload: { source, destination, draggableId, type } });
  };

  const handleAddPipeline = () => {
    if (newPipelineName) {
      dispatch({ type: 'ADD_PIPELINE', payload: newPipelineName });
      setNewPipelineName('');
      setShowAddPipelineDialog(false);
    }
  };

  if (!user) {
    return (
      <div className={styles['task-board']}>
        <div className={styles['board-content']}>
          <Alert severity="info">Please log in to use the Task Board.</Alert>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles['task-board']}>
        <div className={styles['board-content']} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className={styles['task-board']}>
      <div className={styles['pipelineHeader']}>
        <h1>Task Board</h1>
        <Button
          onClick={() => setShowAddPipelineDialog(true)}
          variant="contained"
          startIcon={<FaPlus />}
          className={`${styles['pipeline-action-button']}`}
          aria-label="Add Pipeline"
        />
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className={styles['board-content']}>
          <Droppable
            droppableId="all-pipelines"
            direction="horizontal"
            type="PIPELINE"
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles['pipelinesContainer']}
                aria-label="All Pipelines"
              >
                {pipelines.map((pipeline, index) => (
                  <Pipeline
                    key={prefixId('pipeline', pipeline.id)}
                    pipeline={pipeline}
                    index={index}
                    editPipeline={(id, name) => dispatch({ type: 'EDIT_PIPELINE', payload: { id, name } })}
                    deletePipeline={(id) => dispatch({ type: 'DELETE_PIPELINE', payload: id })}
                    addStage={(pipelineId, name) => dispatch({ type: 'ADD_STAGE', payload: { pipelineId, name } })}
                    editStage={(pipelineId, stageId, name) => 
                      dispatch({ type: 'EDIT_STAGE', payload: { pipelineId, stageId, name } })}
                    deleteStage={(pipelineId, stageId) => 
                      dispatch({ type: 'DELETE_STAGE', payload: { pipelineId, stageId } })}
                    addBucket={(pipelineId, stageId, name) => 
                      dispatch({ type: 'ADD_BUCKET', payload: { pipelineId, stageId, name } })}
                    editBucket={(pipelineId, stageId, bucketId, name) => 
                      dispatch({ type: 'EDIT_BUCKET', payload: { pipelineId, stageId, bucketId, name } })}
                    deleteBucket={(pipelineId, stageId, bucketId) => 
                      dispatch({ type: 'DELETE_BUCKET', payload: { pipelineId, stageId, bucketId } })}
                    addCard={(pipelineId, clientName, description, priority) => 
                      dispatch({ type: 'ADD_CARD', payload: { pipelineId, clientName, description, priority } })}
                    editCard={(cardId, clientName, description, priority) => 
                      dispatch({ type: 'EDIT_CARD', payload: { id: cardId, clientName, description, priority } })}
                    deleteCard={(cardId) => dispatch({ type: 'DELETE_CARD', payload: cardId })}
                    cards={cards}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      {/* Add Pipeline Dialog */}
      <Dialog
        open={showAddPipelineDialog}
        onClose={() => setShowAddPipelineDialog(false)}
        aria-labelledby="add-pipeline-dialog-title"
      >
        <DialogTitle id="add-pipeline-dialog-title">Add New Pipeline</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Pipeline Name"
            type="text"
            fullWidth
            value={newPipelineName}
            onChange={(e) => setNewPipelineName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && newPipelineName.trim()) {
                handleAddPipeline();
              }
            }}
            aria-label="Pipeline Name"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setNewPipelineName('');
            setShowAddPipelineDialog(false);
          }}>
            Cancel
          </Button>
          <Button 
            onClick={handleAddPipeline}
            disabled={!newPipelineName.trim()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={Boolean(error)} 
        autoHideDuration={6000} 
        onClose={clearError}
      >
        <Alert 
          onClose={clearError} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TaskBoard;