// src/components/SearchClients.js
import React, { useState, useRef, useEffect } from 'react';
import useFetchClients from '../hooks/useFetchClients';
import PropTypes from 'prop-types';

const SearchClients = ({ onClientSelect }) => {
  const [searchTerm, setSearchTerm] = useState(''); // Holds the current input value or selected client name
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { results: clients, loading, error } = useFetchClients(searchTerm); // Get results from hook
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsDropdownOpen(true);
  };

  const handleClientClick = (client) => {
    onClientSelect(client.id);
    setIsDropdownOpen(false);
    const displayName = getDisplayName(client);
    setSearchTerm(displayName); // Set the search term to the selected client's name
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getDisplayName = (client) => {
    if (client.firstNameLowerCase && client.lastNameLowerCase) {
      return `${capitalizeFirstLetter(client.firstNameLowerCase)} ${capitalizeFirstLetter(client.lastNameLowerCase)}`;
    } else if (client.firstNameLowerCase) {
      return capitalizeFirstLetter(client.firstNameLowerCase);
    } else if (client.lastNameLowerCase) {
      return capitalizeFirstLetter(client.lastNameLowerCase);
    } else if (client.phone) {
      return client.phone;
    } else if (client.email) {
      return client.email;
    }
    return null; // If no valid display name is available
  };

  const getAvatarLetter = (client) => {
    if (client.firstNameLowerCase) {
      return client.firstNameLowerCase.charAt(0).toUpperCase();
    } else if (client.lastNameLowerCase) {
      return client.lastNameLowerCase.charAt(0).toUpperCase();
    } else if (client.phone) {
      return '#';
    } else if (client.email) {
      return '@';
    }
    return '?';
  };

  return (
    <div className="search-clients-container" ref={dropdownRef}>
      <h2>Search Clients</h2>
      <input
        type="text"
        className="search-input"
        placeholder="Search Clients..."
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={() => setIsDropdownOpen(true)}
      />
      {isDropdownOpen && (
        <div className="client-dropdown">
          {loading && <div className="loading-message">Loading...</div>}
          {error && <div className="error-message">{error}</div>}
          {!loading && !error && clients && clients.length > 0 && (
            <ul className="client-list">
              {clients.map((client) => {
                const displayName = getDisplayName(client);
                if (!displayName) return null; // Don't render clients without a display name

                return (
                  <li key={client.id} className="client-list-item" onClick={() => handleClientClick(client)}>
                    <div className="client-avatar">
                      {getAvatarLetter(client)}
                    </div>
                    <div className="client-info">
                      <div className="client-name">{displayName}</div>
                      {client.email && client.email !== displayName && <div className="client-email">{client.email}</div>}
                      {client.phone && client.phone !== displayName && <div className="client-phone">{client.phone}</div>}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {!loading && !error && clients && clients.length === 0 && searchTerm && (
            <div className="no-results">No clients found</div>
          )}
        </div>
      )}
    </div>
  );
};

SearchClients.propTypes = {
  onClientSelect: PropTypes.func.isRequired,
};

export default SearchClients;
