// src/hooks/useCurrentClientsData.js
import { useState, useEffect } from 'react';

const useCurrentClientData = () => {
  const [clients, setClientsState] = useState([]);

  useEffect(() => {
    const storedClients = localStorage.getItem('clients');
    if (storedClients) {
      try {
        const parsedClients = JSON.parse(storedClients);
        if (Array.isArray(parsedClients)) {
          setClientsState(parsedClients);
        } else {
          console.error('Stored clients is not an array:', parsedClients);
          localStorage.removeItem('clients'); // Clear corrupted data
          setClientsState([]);
        }
      } catch (error) {
        console.error('Error parsing stored clients:', error);
        localStorage.removeItem('clients'); // Clear corrupted data
        setClientsState([]);
      }
    }
  }, []);

  const setClients = (newClients) => {
    if (Array.isArray(newClients)) {
      setClientsState(newClients);
      localStorage.setItem('clients', JSON.stringify(newClients));
    } else {
      console.error('Attempted to set invalid client data:', newClients);
    }
  };

  const clearClients = () => {
    setClientsState([]);
    localStorage.removeItem('clients');
  };

  return { clients, setClients, clearClients };
};

export default useCurrentClientData;
