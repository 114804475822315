// /src/components/CreateRoomModal.js

import React, { useState } from 'react';
import {
  Modal, Box, Typography, TextField, Button, IconButton, List, ListItem, ListItemText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const CreateRoomModal = ({ open, handleClose, handleCreateRoom }) => {
  const [roomName, setRoomName] = useState('');
  const [tasks, setTasks] = useState(['']);

  // Handle changes to room name
  const handleRoomNameChange = (e) => {
    setRoomName(e.target.value);
  };

  // Handle changes to tasks
  const handleTaskChange = (index, value) => {
    const updatedTasks = [...tasks];
    updatedTasks[index] = value;
    setTasks(updatedTasks);
  };

  // Add a new task field
  const addTaskField = () => {
    setTasks([...tasks, '']);
  };

  // Remove a task field
  const removeTaskField = (index) => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);
  };

  // Handle form submission
  const onSubmit = (e) => {
    e.preventDefault();
    // Basic validation
    if (roomName.trim() === '') {
      alert('Room name is required.');
      return;
    }
    const cleanedTasks = tasks.filter(task => task.trim() !== '').map(task => ({ description: task, completed: false }));
    const newRoom = {
      name: roomName,
      tasks: cleanedTasks,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    handleCreateRoom(newRoom);
    // Reset fields
    setRoomName('');
    setTasks(['']);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="create-room-modal-title"
      aria-describedby="create-room-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography id="create-room-modal-title" variant="h6" component="h2">
            Create New Room Type
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={onSubmit}>
          <TextField
            label="Room Name"
            value={roomName}
            onChange={handleRoomNameChange}
            fullWidth
            required
            margin="normal"
          />
          <Typography variant="subtitle1" gutterBottom>
            Tasks
          </Typography>
          <List>
            {tasks.map((task, index) => (
              <ListItem key={index} disableGutters>
                <TextField
                  label={`Task ${index + 1}`}
                  value={task}
                  onChange={(e) => handleTaskChange(index, e.target.value)}
                  fullWidth
                  required
                />
                <IconButton
                  onClick={() => removeTaskField(index)}
                  disabled={tasks.length === 1}
                  color="error"
                >
                  <CloseIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          <Button
            onClick={addTaskField}
            startIcon={<AddIcon />}
            variant="outlined"
            sx={{ mt: 1, mb: 2 }}
          >
            Add Task
          </Button>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Create Room
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateRoomModal;
