import React, { useState } from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Card from './CardsTaskBoard';
import { prefixId } from '../utils/prefixIdTaskboard';
import styles from '../styles/TaskBoard.module.css';

const Bucket = React.memo(
  ({
    bucket,
    index,
    stageId,
    pipelineId,
    editBucket,
    deleteBucket,
    editCard,
    deleteCard,
    handleBreakAway,
    isBreakAwayStage,
    cards,
  }) => {
    // Ensure bucket has all required properties with defaults
    const safeBucket = {
      id: bucket?.id || '',
      name: bucket?.name || '',
      description: bucket?.description || '',
      cards: bucket?.cards || [],
      ...bucket
    };

    const [showEditBucketDialog, setShowEditBucketDialog] = useState(false);
    const [editedBucketName, setEditedBucketName] = useState(safeBucket.name);
    const [editedBucketDescription, setEditedBucketDescription] = useState(safeBucket.description);

    const handleEditBucket = () => {
      const trimmedName = editedBucketName?.trim() || '';
      const trimmedDescription = editedBucketDescription?.trim() || '';
      
      if (trimmedName) {
        editBucket(
          pipelineId,
          stageId,
          safeBucket.id,
          trimmedName,
          trimmedDescription
        );
        setShowEditBucketDialog(false);
      }
    };

    return (
      <Draggable draggableId={prefixId('bucket', safeBucket.id)} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`${styles.bucket} ${snapshot.isDragging ? styles['is-dragging'] : ''}`}
            aria-label={`Bucket: ${safeBucket.name}`}
          >
            <div className={styles['bucket-header']}>
              <div 
                className={styles['bucket-title-row']}
                {...provided.dragHandleProps}
                aria-label={`Drag Handle for Bucket: ${safeBucket.name}`}
              >
                <h4>{safeBucket.name}</h4>
              </div>
              <div className={styles['bucket-actions']}>
                <button
                  onClick={() => setShowEditBucketDialog(true)}
                  className={styles['edit-button']}
                  aria-label={`Edit Bucket: ${safeBucket.name}`}
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => deleteBucket(pipelineId, stageId, safeBucket.id)}
                  className={styles['delete-button']}
                  aria-label={`Delete Bucket: ${safeBucket.name}`}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
            
            <Droppable droppableId={prefixId('bucket', safeBucket.id)} type="CARD">
              {(droppableProvided, droppableSnapshot) => (
                <div
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  className={`${styles.cards} ${droppableSnapshot.isDraggingOver ? styles['dragging-over'] : ''}`}
                  aria-label={`Cards in Bucket: ${safeBucket.name}`}
                >
                  {safeBucket.cards.map((cardId, cardIndex) => {
                    const card = cards.find((c) => c.id === cardId);
                    return card ? (
                      <Card
                        key={prefixId('card', card.id)}
                        card={card}
                        index={cardIndex}
                        editCard={editCard}
                        deleteCard={deleteCard}
                      />
                    ) : null;
                  })}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>

            {isBreakAwayStage && safeBucket.cards.length > 0 && (
              <button
                onClick={() => handleBreakAway(pipelineId, stageId, safeBucket.id, safeBucket.cards[0])}
                className={styles['break-away-button']}
                aria-label={`Break Away Card from Bucket: ${safeBucket.name}`}
              >
                Break Away
              </button>
            )}
    
            <Dialog
              open={showEditBucketDialog}
              onClose={() => setShowEditBucketDialog(false)}
              aria-labelledby="edit-bucket-dialog-title"
            >
              <DialogTitle id="edit-bucket-dialog-title">Edit Bucket</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Bucket Name"
                  type="text"
                  fullWidth
                  value={editedBucketName || ''}
                  onChange={(e) => setEditedBucketName(e.target.value)}
                  aria-label="Bucket Name"
                />
                <TextField
                  margin="dense"
                  label="Bucket Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  value={editedBucketDescription || ''}
                  onChange={(e) => setEditedBucketDescription(e.target.value)}
                  aria-label="Bucket Description"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowEditBucketDialog(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={handleEditBucket}
                  disabled={!editedBucketName?.trim()}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Draggable>
    );
  }
);

export default Bucket;