// /src/components/CustomFieldsManager.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomFieldsSection from './CustomFieldsSection';
import { useUserPreferences } from '../hooks/useUserPreferences';
import {
  getAllCustomFields,
  getClientCustomFields,
  updateClientCustomField,
} from '../utils/api';

const CustomFieldsManager = ({ clientId, updateCustomFields }) => {
  const {
    sections,
    fieldPreferences,
    loading: preferencesLoading,
    error: preferencesError,
    updateSections,
    updateFieldPreferences,
  } = useUserPreferences();

  const [isEditingSections, setIsEditingSections] = useState(false);
  const [isEditingFields, setIsEditingFields] = useState(false);
  const [globalFields, setGlobalFields] = useState([]);
  const [clientFields, setClientFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [allFields, clientData] = await Promise.all([
          getAllCustomFields(),
          getClientCustomFields(clientId),
        ]);
        setGlobalFields(allFields);

        // Process clientData to extract field values
        const clientFieldValues = clientData.contact.customFields.reduce(
          (acc, field) => {
            acc[field.id] = field.value;
            return acc;
          },
          {}
        );

        setClientFields(clientFieldValues);
      } catch (err) {
        console.error('Error fetching custom fields:', err);
        setError('Failed to fetch custom fields.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [clientId]);

  const handleAddSection = () => {
    const newSection = {
      id: `section-${Date.now()}`,
      name: 'New Section',
      fieldIds: [],
      isOpen: true,
    };
    updateSections([...(sections || []), newSection]);
  };

  const handleDeleteSection = (sectionId) => {
    updateSections(
      (sections || []).filter((section) => section.id !== sectionId)
    );
  };

  const handleUpdateSection = (updatedSection) => {
    updateSections(
      (sections || []).map((section) =>
        section.id === updatedSection.id ? updatedSection : section
      )
    );
  };

  const handleUpdateFieldValue = async (fieldId, value) => {
    try {
      // Update local state immediately for a responsive UI
      setClientFields((prev) => ({ ...prev, [fieldId]: value }));
      updateFieldPreferences({ ...fieldPreferences, [fieldId]: value });

      // Save to the server (no need to await here if you want to make it non-blocking)
      await updateClientCustomField(clientId, [{ id: fieldId, value }]);

      if (updateCustomFields) {
        updateCustomFields([{ id: fieldId, value }]);
      }

      // Do NOT set isEditingFields to false here
      // Ensure that isEditingFields remains unchanged during field updates
    } catch (err) {
      console.error('Error updating field value:', err);
      setError('Failed to update field value');
    }
  };

  const handleSaveSections = async () => {
    try {
      await updateSections(sections);
      setIsEditingSections(false);
    } catch (err) {
      console.error('Error saving sections:', err);
      setError('Failed to save sections');
    }
  };

  const handleSaveFieldValues = async () => {
    try {
      await updateFieldPreferences(fieldPreferences);
      setIsEditingFields(false);
    } catch (err) {
      console.error('Error saving field values:', err);
      setError('Failed to save field values');
    }
  };

  if (loading || preferencesLoading) return <div>Loading...</div>;
  if (error || preferencesError)
    return <div>Error: {error || preferencesError}</div>;

  return (
    <div className="custom-fields-manager">
      <div className="custom-fields-header">
        <h2>Custom Fields</h2>
        <div className="editing-buttons">
          <button
            onClick={() => setIsEditingSections(!isEditingSections)}
            className={`btn ${
              isEditingSections ? 'btn-secondary' : 'btn-primary'
            }`}
          >
            {isEditingSections ? 'Cancel Section Edit' : 'Edit Sections'}
          </button>
          <button
            onClick={() => setIsEditingFields(!isEditingFields)}
            className={`btn ${
              isEditingFields ? 'btn-secondary' : 'btn-primary'
            }`}
          >
            {isEditingFields ? 'Cancel Field Edit' : 'Edit Fields'}
          </button>
        </div>
      </div>
      <div className="sections">
        {(sections || []).map((section, index) => (
          <CustomFieldsSection
            key={section.id}
            section={section}
            index={index}
            globalFields={globalFields}
            fieldValues={{ ...clientFields, ...fieldPreferences }}
            isEditingSections={isEditingSections}
            isEditingFields={isEditingFields}
            onDeleteSection={handleDeleteSection}
            onUpdateSection={handleUpdateSection}
            onUpdateFieldValue={handleUpdateFieldValue}
          />
        ))}
      </div>
      {isEditingSections && (
        <div className="action-buttons">
          <button onClick={handleAddSection} className="btn btn-secondary">
            Add Section
          </button>
          <button onClick={handleSaveSections} className="btn btn-primary">
            Save Section Changes
          </button>
        </div>
      )}
      {isEditingFields && (
        <div className="action-buttons">
          <button onClick={handleSaveFieldValues} className="btn btn-primary">
            Save Field Changes
          </button>
        </div>
      )}
    </div>
  );
};

CustomFieldsManager.propTypes = {
  clientId: PropTypes.string.isRequired,
  updateCustomFields: PropTypes.func,
};

export default CustomFieldsManager;
