  // /src/components/QuoteTool.js

  import React, { useState, useEffect, useCallback } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { parseRoomsList } from '../utils/parseRoomsList';
  import {
    updateSelectedMultiplier,
    updateSelectedFrequency,
  } from '../store/actions/quoteActions';
  import useClientDistance from '../hooks/useCalculateDistance';

  const QuoteTool = ({ selectedClient }) => {
    const dispatch = useDispatch();
    const { pricing, multipliers, selectedMultiplier, selectedFrequency } = useSelector(
      (state) => state.quote
    );
    
    const [quantities, setQuantities] = useState({});
    const [roomInput, setRoomInput] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [summary, setSummary] = useState(null);

    const frequencyMinimums = {
      Weekly: 125,
      'Bi-Weekly': 150,
      Monthly: 180,
      'Bi-Monthly': 225,
      Quarterly: 300,
    };

    const {
      distance,
      duration,
      loading: distanceLoading,
      error: distanceError,
    } = useClientDistance(selectedClient);

    const calculateTotal = useCallback(() => {
      let total = 0;
      let additionalRooms = [];

      pricing.forEach((item) => {
        const quantity = quantities[item.name] || 0;
        total += item.price * quantity;
      });

      if (distance && parseFloat(distance) >= 25) {
        const tripCharge = pricing.find(
          (item) => item.name === 'Gas Mileage for 25+ (Add Total Miles)'
        );
        if (tripCharge) {
          total += tripCharge.price * parseFloat(distance);
        }
      }

      const totalWithMultiplier = total * selectedMultiplier;
      setTotalPrice(totalWithMultiplier);
      return { total: totalWithMultiplier, additionalRooms, baseTotal: total };
    }, [pricing, quantities, selectedMultiplier, distance]);

    useEffect(() => {
      calculateTotal();
    }, [calculateTotal]);

    const handleRoomInputChange = (e) => {
      setRoomInput(e.target.value);
    };

    const parseRooms = () => {
      const parsedRooms = parseRoomsList(roomInput);
      const newQuantities = { ...quantities };

      parsedRooms.forEach((room) => {
        const roomName = room.name;
        newQuantities[roomName] = (newQuantities[roomName] || 0) + room.quantity;
      });

      setQuantities(newQuantities);
    };

    const handleQuantityChange = (roomName, value) => {
      setQuantities({
        ...quantities,
        [roomName]: parseInt(value, 10) || 0,
      });
    };

    const handleMultiplierChange = (e) => {
      dispatch(updateSelectedMultiplier(parseFloat(e.target.value)));
    };

    const handleFrequencyChange = (e) => {
      dispatch(updateSelectedFrequency(e.target.value));
    };

    const getSummary = () => {
      const { total, baseTotal } = calculateTotal();

      let finalTotal = total;
      if (selectedFrequency && frequencyMinimums[selectedFrequency]) {
        finalTotal = Math.max(total, frequencyMinimums[selectedFrequency]);
      }

      const roomsList = pricing
        .filter((item) => quantities[item.name])
        .map((item) => ({
          name: item.name,
          quantity: quantities[item.name],
          totalPrice: item.price * quantities[item.name],
        }));

      const additionalRooms = roomsList.filter(
        (room) =>
          !pricing.find(
            (item) => item.name.toLowerCase() === room.name.toLowerCase()
          )
      );

      setSummary({
        customerName: `${selectedClient.firstName} ${selectedClient.lastName}`,
        address: `${selectedClient.address1}, ${selectedClient.city}, ${selectedClient.state} ${selectedClient.postalCode}`,
        distance: distance,
        duration: duration,
        rooms: roomsList,
        additionalRooms: additionalRooms.map((room) => room.name),
        multiplier: multipliers.find(
          (mult) => mult.value === selectedMultiplier
        )?.name,
        multiplierValue: selectedMultiplier,
        frequency: selectedFrequency,
        total: finalTotal,
        baseTotal,
      });
    };

    const clearForm = () => {
      setRoomInput('');
      setQuantities({});
      setSummary(null);
      setTotalPrice(0);
      dispatch(updateSelectedMultiplier(1)); // Reset to default multiplier
      dispatch(updateSelectedFrequency('')); // Reset to default frequency
    };

    return (
      <div className="quote-tool">
        <h2>Quote Tool</h2>
        {selectedClient && (
          <div className="client-info">
            <h3>Client Information</h3>
            <p>
              Name: {selectedClient.firstName} {selectedClient.lastName}
            </p>
            <p>
              Address: {selectedClient.address1}, {selectedClient.city},{' '}
              {selectedClient.state} {selectedClient.postalCode}
            </p>
            {distanceLoading && <p>Calculating distance...</p>}
            {distanceError && <p>Error calculating distance: {distanceError}</p>}
            {!distanceLoading && !distanceError && distance && (
              <p>
                Distance: {distance} | Estimated Travel Time: {duration}
              </p>
            )}
          </div>
        )}
        <div className="frequency">
          <label>Frequency:</label>
          <div>
            {Object.keys(frequencyMinimums).map((freq, index) => (
              <label key={index}>
                <input
                  type="radio"
                  name="frequency"
                  value={freq}
                  checked={selectedFrequency === freq}
                  onChange={handleFrequencyChange}
                />
                {freq}
              </label>
            ))}
          </div>
        </div>
        <div className="room-input">
          <textarea
            value={roomInput}
            onChange={handleRoomInputChange}
            placeholder="Enter room details..."
          />
          <button onClick={parseRooms} className="btn btn-primary">
            Fill
          </button>
        </div>
        <div className="pricing-table">
          <h3>Room Pricing</h3>
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price Per Unit</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {pricing.map((room, index) => (
                <tr key={index}>
                  <td>{room.name}</td>
                  <td>
                    <input
                      type="number"
                      value={quantities[room.name] || 0}
                      onChange={(e) =>
                        handleQuantityChange(room.name, e.target.value)
                      }
                      min="0"
                    />
                  </td>
                  <td>${room.price.toFixed(2)}</td>
                  <td>
                    ${((quantities[room.name] || 0) * room.price).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="multiplier">
          <label>Multiplier:</label>
          <select value={selectedMultiplier} onChange={handleMultiplierChange}>
            {multipliers.map((mult, index) => (
              <option key={index} value={mult.value}>
                {mult.name} ({mult.value}x)
              </option>
            ))}
          </select>
        </div>
        <div className="total-price">
          <h3>Total Price: ${totalPrice.toFixed(2)}</h3>
        </div>
        <div className="quote-actions">
          <button onClick={getSummary} className="btn btn-primary">
            Summary
          </button>
          <button onClick={clearForm} className="btn btn-secondary">
            Clear Form
          </button>
        </div>
        {summary && (
          <div className="quote-summary">
            <h3>Quote Summary</h3>
            <p>
              <strong>Name:</strong> {summary.customerName}
            </p>
            <p>
              <strong>Address: {summary.address} | {summary.distance} | {summary.duration}</strong>
            </p>
            <p>
              <strong>Frequency: {summary.frequency}</strong>
            </p>
            <p><strong>Room Details:</strong></p>
            <ul>
              {summary.rooms.map((room, index) => (
                <li key={index}>
                  {room.quantity} x {room.name} - ${room.totalPrice.toFixed(2)}
                </li>
              ))}
            </ul>
            {summary.additionalRooms.length > 0 && (
              <p><strong>Additional Rooms:</strong> {summary.additionalRooms.join(', ')}</p>
            )}
            <p>
              <strong>Subtotal:</strong> ${summary.baseTotal.toFixed(2)}
            </p>
            <p>
              <strong>Total Price:</strong> ${summary.total.toFixed(2)}
            </p>
          </div>
        )}
      </div>
    );
  };

  export default QuoteTool;