// src/components/Layout.js

import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';

const Layout = ({ isDarkMode, toggleDarkMode }) => {
  return (
    <div className="app-layout" data-mui-color-scheme={isDarkMode ? 'dark' : 'light'}>
      <Sidebar isDarkMode={isDarkMode} />
      <div className={`main-content ${isDarkMode ? 'dark-mode' : ''}`}>
        <Header isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        <div className="content-padding">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
