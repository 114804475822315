import { createTheme } from '@mui/material/styles';

// Utility function to fetch CSS variable values
const getCSSVar = (varName) => getComputedStyle(document.documentElement).getPropertyValue(varName).trim();

// Common component overrides for both themes
const commonComponents = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        margin: 0,
        padding: 0,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        textTransform: 'none',
        fontWeight: 500,
        padding: '8px 16px',
        height: '36px',
        minWidth: '40px',
        fontSize: '14px',
        backgroundColor: 'var(--dark-primary)',
        color: 'white',
        '&:hover': {
          backgroundColor: 'var(--dark-accent)',
        },
        '[data-mui-color-scheme="light"] &': {
          backgroundColor: 'var(--light-primary)',
          '&:hover': {
            backgroundColor: 'var(--light-accent)',
          },
        },
        // Transition for smooth hover effect
        transition: 'background-color 0.2s ease',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        padding: '8px',
        height: '36px',
        minWidth: '40px',
        backgroundColor: 'var(--dark-primary)',
        color: 'white',
        '&:hover': {
          backgroundColor: 'var(--dark-accent)',
        },
        '[data-mui-color-scheme="light"] &': {
          backgroundColor: 'var(--light-primary)',
          '&:hover': {
            backgroundColor: 'var(--light-accent)',
          },
        },
        // Add space between adjacent icon buttons
        '& + &': {
          marginLeft: '8px',
        },
        // Ensure icons are centered
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        // Transition for smooth hover effect
        transition: 'background-color 0.2s ease',
      },
      // Size the actual icon within the button
      sizeSmall: {
        padding: '6px',
        '& > *:first-child': {
          fontSize: '20px',
        },
      },
      sizeMedium: {
        padding: '8px',
        '& > *:first-child': {
          fontSize: '24px',
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&.Mui-expanded': {
          margin: '8px 0',
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: '1.25rem',
        fontWeight: 500,
      },
    },
  },
};

// Light Theme Configuration
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: getCSSVar('--light-primary'),
      contrastText: '#fff',
    },
    secondary: {
      main: getCSSVar('--light-secondary'),
      contrastText: '#fff',
    },
    background: {
      default: getCSSVar('--light-background'),
      paper: getCSSVar('--light-foreground'),
    },
    text: {
      primary: getCSSVar('--light-text'),
      secondary: getCSSVar('--light-text-light'),
    },
    error: {
      main: getCSSVar('--error'),
      contrastText: getCSSVar('--error-content'),
    },
  },
  components: {
    ...commonComponents,
    MuiBox: {
      styleOverrides: {
        root: {
          '&.CreateJobForm_CreateJobForm__RDAjx': {
            backgroundColor: 'var(--light-background)',
            color: 'var(--light-text)',
            borderColor: 'none',
            '& .MuiTextField-root': {
              backgroundColor: 'var(--light-foreground)',
              borderColor: 'none',
            },
            '& .MuiAccordion-root': {
              backgroundColor: 'var(--light-foreground)',
              borderColor: 'none',
            },
            '& .MuiAutocomplete-paper': {
              backgroundColor: 'var(--light-foreground)',
              borderColor: 'none',
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--light-background)',
          '& .MuiDialogTitle-root': {
            backgroundColor: 'var(--light-foreground)',
            color: 'var(--light-text)',
            padding: '16px 24px',
          },
          '& .MuiDialogContent-root': {
            backgroundColor: 'var(--light-foreground)',
            color: 'var(--light-text)',
            padding: '20px 24px',
          },
          '& .MuiDialogActions-root': {
            backgroundColor: 'var(--light-foreground)',
            padding: '16px 24px',
          },
          '& .MuiTextField-root': {
            backgroundColor: 'var(--light-foreground)',
          },
          '& .MuiSelect-select': {
            backgroundColor: 'var(--light-foreground)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'var(--light-background)',
            borderColor: 'none',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--light-foreground)',
          borderColor: 'none',
        },
      },
    },
  },
});

// Dark Theme Configuration
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: getCSSVar('--dark-primary'),
      contrastText: '#fff',
    },
    secondary: {
      main: getCSSVar('--dark-secondary'),
      contrastText: '#fff',
    },
    background: {
      default: getCSSVar('--dark-background'),
      paper: getCSSVar('--dark-foreground'),
    },
    text: {
      primary: getCSSVar('--dark-text'),
      secondary: getCSSVar('--dark-text-light'),
    },
    error: {
      main: getCSSVar('--error'),
      contrastText: getCSSVar('--error-content'),
    },
  },
  components: {
    ...commonComponents,
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--dark-background)',
          '& .MuiDialogTitle-root': {
            backgroundColor: 'var(--dark-foreground)',
            color: 'var(--dark-text)',
            padding: '16px 24px',
            borderColor: 'none',
          },
          '& .MuiDialogContent-root': {
            backgroundColor: 'var(--dark-foreground)',
            color: 'var(--dark-text)',
            padding: '20px 24px',
            borderColor: 'none',
          },
          '& .MuiDialogActions-root': {
            backgroundColor: 'var(--dark-foreground)',
            padding: '16px 24px',
            borderColor: 'none',
          },
          '& .MuiTextField-root': {
            backgroundColor: 'var(--dark-foreground)',
            borderColor: 'none',
          },
          '& .MuiSelect-select': {
            backgroundColor: 'var(--dark-foreground)',
            borderColor: 'none',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'var(--dark-text-area)',
            '& fieldset': {
              borderColor: 'none',
            },
            '&:hover fieldset': {
              borderColor: 'none',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'none',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--dark-foreground)',
          borderColor: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'var(--dark-text)',
          '&.Mui-selected': {
            color: 'var(--dark-primary)',
          },
        },
      },
    },
  },
});