// src/hooks/useUserPreferences.js

import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getUserPreferences, saveUserPreferences } from '../utils/api';

export const useUserPreferences = () => {
  const [sections, setSections] = useState([]);
  const [fieldPreferences, setFieldPreferences] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchPreferences();
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchPreferences = async () => {
    setLoading(true);
    try {
      const data = await getUserPreferences();
      setSections(data.sections || []);
      setFieldPreferences(data.fieldPreferences || {});
    } catch (err) {
      console.error('Error fetching user preferences:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateSections = async (newSections) => {
    try {
      await saveUserPreferences({ sections: newSections, fieldPreferences });
      setSections(newSections);
    } catch (err) {
      setError(err.message);
    }
  };

  const updateFieldPreferences = async (newFieldPreferences) => {
    try {
      await saveUserPreferences({ sections, fieldPreferences: newFieldPreferences });
      setFieldPreferences(newFieldPreferences);
    } catch (err) {
      setError(err.message);
    }
  };

  return {
    sections,
    fieldPreferences,
    loading,
    error,
    updateSections,
    updateFieldPreferences,
  };
};

export default useUserPreferences;
