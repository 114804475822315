// /src/components/CustomerManagement.js

import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { 
  Typography, Button, List, ListItem, ListItemText, Box, CircularProgress, Alert,
  IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  Pagination, Paper,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import ClientCSVUpload from './ClientCSVUpload';
import CreateCustomerModal from './CreateCustomerModal';
import styles from '../styles/CustomerManagement.module.css';

const ITEMS_PER_PAGE = 10;

const CustomerManagement = ({ orgId }) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const customersRef = collection(db, 'organizations', orgId, 'customers');
      const snapshot = await getDocs(customersRef);
      const customerList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomers(customerList.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (err) {
      setError('Error fetching customers: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

    // Filter customers based on search term
  const filteredCustomers = customers.filter(customer => 
    customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.address?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditCustomer = (customer) => {
    setEditingCustomer(customer);
    setOpenEditModal(true);
  };

  const handleUpdateCustomer = async (updatedData) => {
    setLoading(true);
    try {
      const customerRef = doc(db, 'organizations', orgId, 'customers', editingCustomer.id);
      await updateDoc(customerRef, updatedData);
      await fetchCustomers();
      setOpenEditModal(false);
      setEditingCustomer(null);
    } catch (err) {
      setError('Error updating customer: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCustomer = async (customerId) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      setLoading(true);
      try {
        await deleteDoc(doc(db, 'organizations', orgId, 'customers', customerId));
        await fetchCustomers();
      } catch (err) {
        setError('Error deleting customer: ' + err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCustomersCreated = async (newCustomers) => {
    setLoading(true);
    try {
      const customersRef = collection(db, 'organizations', orgId, 'customers');
      for (const customer of newCustomers) {
        await addDoc(customersRef, customer);
      }
      fetchCustomers();
      alert(`${newCustomers.length} customers added successfully!`);
    } catch (err) {
      setError('Error adding customers: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateCustomer = async (newCustomer) => {
    setLoading(true);
    try {
      const customersRef = collection(db, 'organizations', orgId, 'customers');
      await addDoc(customersRef, newCustomer);
      fetchCustomers();
      setOpenCreateModal(false);
    } catch (err) {
      setError('Error adding customer: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress className={styles.loader} />;
  if (error) return <Alert severity="error" className={styles.alert}>{error}</Alert>;

  const paginatedCustomers = customers.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  return (
    <Box className={styles.container}>
      <Typography variant="h4" className={styles.title}>
        Customer Management
      </Typography>
      
      <Box className={styles.actions}>
        <Button 
          variant="contained" 
          onClick={() => setOpenCreateModal(true)}
          className={styles.createButton}
        >
          Create New Customer
        </Button>
        <Box className={styles.uploadSection}>
          <ClientCSVUpload 
            onCustomersCreated={handleCustomersCreated} 
            className={styles.uploadButton}
          />
          <Typography variant="body2" className={styles.csvInfo}>
            (CSV headers: Name, Email, Phone, Address, Notes)
          </Typography>
        </Box>
      </Box>

      {/* Search Field */}
      <Box className={styles.searchContainer}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search customers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={styles.searchField}
        />
      </Box>

      <Paper className={styles.customersList}>
        <List>
          {paginatedCustomers.map((customer) => (
            <ListItem 
              key={customer.id}
              className={styles.customerItem}
              secondaryAction={
                <Box className={styles.itemActions}>
                  <IconButton 
                    onClick={() => handleEditCustomer(customer)}
                    className={styles.editButton}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton 
                    onClick={() => handleDeleteCustomer(customer.id)}
                    className={styles.deleteButton}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              }
            >
              <ListItemText 
                primary={customer.name}
                secondary={customer.address}
                className={styles.customerText}
              />
            </ListItem>
          ))}
        </List>
        
        <Box className={styles.pagination}>
          <Pagination 
            count={Math.ceil(customers.length / ITEMS_PER_PAGE)}
            page={page}
            onChange={(e, value) => setPage(value)}
            color="primary"
          />
        </Box>
      </Paper>

      <CreateCustomerModal
        open={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
        handleCreateCustomer={handleCreateCustomer}
      />

      <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <DialogTitle>Edit Customer</DialogTitle>
        <DialogContent>
          {editingCustomer && (
            <Box className={styles.editForm}>
              <TextField
                label="Name"
                defaultValue={editingCustomer.name}
                fullWidth
                margin="normal"
                onChange={(e) => setEditingCustomer({
                  ...editingCustomer,
                  name: e.target.value
                })}
              />
              <TextField
                label="Address"
                defaultValue={editingCustomer.address}
                fullWidth
                margin="normal"
                onChange={(e) => setEditingCustomer({
                  ...editingCustomer,
                  address: e.target.value
                })}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditModal(false)}>Cancel</Button>
          <Button 
            onClick={() => handleUpdateCustomer(editingCustomer)}
            variant="contained"
            color="primary"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CustomerManagement;