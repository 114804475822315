import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import SearchClients from './SearchClients';
import DatePickerComponent from './DatePicker';
import CallList from './CallList';
import MessageList from './MessageList';
import TranscribeAudio from './TranscribeAudio';
import CustomerProfile from './CustomerProfile';
import CustomFieldsManager from './CustomFieldsManager';
import useCustomFields from '../hooks/useCustomFields';
import { fetchCustomerProfile } from '../store/actions/customerActions';
import useTranscription from '../hooks/useTranscription';
import ClientDistanceFinder from './ClientDistanceFinder';
import QuoteTool from './QuoteTool';
import useClientDistance from '../hooks/useCalculateDistance';

const ClientSalesTools = () => {
  const dispatch = useDispatch();
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateFilteredConversations, setDateFilteredConversations] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const customerProfiles = useSelector((state) => state.client.customerProfiles);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [isUserIdAvailable, setIsUserIdAvailable] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserId(user.uid);
      } else {
        setCurrentUserId(null);
      }
    });
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);


  const {
    globalFields,
    clientFields,
    sections,
    setSections,
    isLoading,
    error,
    updateCustomFields,
  } = useCustomFields(selectedClientId, currentUserId);

  const transcriptionHook = useTranscription();

  const selectedClient = customerProfiles[selectedClientId];
  const { distance, duration } = useClientDistance(selectedClient);

  const handleClientSelect = (clientId) => {
    setSelectedClientId(clientId);

    if (!customerProfiles[clientId]) {
      dispatch(fetchCustomerProfile(clientId));
    }

    setHasFetched(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setHasFetched(false);
  };

  const handleConversationsFetch = (conversations) => {
    setDateFilteredConversations(conversations);
    setHasFetched(true);
  };

  return (
    <div className="client-sales-tools-container">
      <div className="top-section">
        <div className="search-section">
          <div className="search-input">
            <SearchClients onClientSelect={handleClientSelect} />
          </div>
        </div>
        <div className="datepicker-section">
          <div className="date-input">
            <DatePickerComponent
              selectedDate={selectedDate}
              onDateChange={handleDateChange}
              onConversationsFetch={handleConversationsFetch}
            />
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="card contacts-section">
          <h2>Contacts</h2>
          <CallList
            calls={hasFetched ? dateFilteredConversations : []}
            onSelectContact={handleClientSelect}
          />
        </div>
        <div className="card messages-section">
          <h2>Messages</h2>
          {selectedClientId ? (
            <MessageList {...transcriptionHook} selectedClientId={selectedClientId} />
          ) : (
            <div className="no-contact-selected">Select a contact to see conversations</div>
          )}
        </div>
      </div>
      <div className="card transcription-section">
        <h2>Transcription</h2>
        <TranscribeAudio {...transcriptionHook} />
      </div>
      <div className="profile-and-custom-fields-container">
        <div className="card profile-section">
          <h2>Client Profile</h2>
          {selectedClientId ? (
            <CustomerProfile selectedClientId={selectedClientId} />
          ) : (
            <div className="no-contact-selected">Select a contact to see client profile</div>
          )}
        </div>
        <div className="card custom-fields-section">
          <h2>Custom Fields</h2>
          {!currentUserId ? (
            <div>Error: User is not logged in. Please log in to access custom fields.</div>
          ) : isLoading ? (
            <div>Loading custom fields...</div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            selectedClientId && (
              <CustomFieldsManager
                clientId={selectedClientId}
                userId={currentUserId}
                globalFields={globalFields}
                clientFields={clientFields}
                sections={sections}
                setSections={setSections}
                updateCustomFields={updateCustomFields}
              />
            )
          )}
        </div>
      </div>
      <div className="card distance-calculator-section">
        <h2>Distance to Client</h2>
        {selectedClient ? (
          <ClientDistanceFinder client={selectedClient} />
        ) : (
          <div className="no-contact-selected">Select a contact to calculate distance</div>
        )}
      </div>
      <div className="card quote-tool-section">
        <h2>Quote Tool</h2>
        {selectedClientId ? (
          <QuoteTool 
            selectedClient={selectedClient}
            distance={distance}
            duration={duration}
          />
        ) : (
          <div className="no-contact-selected">Select a contact to use the quote tool</div>
        )}
      </div>
    </div>
  );
};

export default ClientSalesTools;