import { useState, useEffect } from 'react';
import { fetchConversationsByDate } from '../utils/api';

const useFetchCallsByDate = (selectedDate) => {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!selectedDate) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedConversations = await fetchConversationsByDate(selectedDate);
        setConversations(fetchedConversations);
      } catch (err) {
        setError('Failed to fetch conversations by date.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  return { conversations, loading, error };
};

export default useFetchCallsByDate;
