// src/components/Header.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = ({ isDarkMode, toggleDarkMode, onProfileClick }) => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    // Clear token from both localStorage and sessionStorage
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('authToken');
    
    // Redirect to login page
    navigate('/login');
  };

  return (
    <header className={`header ${isDarkMode ? 'dark-mode' : ''}`}>
      <h1 className="title">Gem City Sales Tools</h1>
      <nav className="nav-right">
        <button className="btn btn-primary" onClick={onProfileClick}>Profile</button>
        <button className="btn btn-secondary" onClick={handleSignOut}>Sign Out</button>
        <label className="switch">
          <input type="checkbox" checked={isDarkMode} onChange={toggleDarkMode} />
          <span className="slider round">
            <span className="icon">{isDarkMode ? '🌙' : '☀️'}</span>
          </span>
        </label>
      </nav>
    </header>
  );
};

export default Header;
