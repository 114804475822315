// /src/context/JobContext.js

import React, { createContext, useContext, useState } from 'react';
import { collection, query, where, getDocs, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

const JobContext = createContext();

export const useJobContext = () => useContext(JobContext);

export const JobProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);

  const fetchJobs = async (orgId, date) => {
    const jobsRef = collection(db, 'organizations', orgId, 'jobs');
    const q = query(
      jobsRef,
      where('appointmentDate', '>=', new Date(date)),
      where('appointmentDate', '<', new Date(new Date(date).setDate(new Date(date).getDate() + 1)))
    );

    const querySnapshot = await getDocs(q);
    const fetchedJobs = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    setJobs(fetchedJobs);
  };

  const startJob = async (orgId, jobId) => {
    const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
    await updateDoc(jobRef, {
      startTime: serverTimestamp(),
      status: 'in-progress'
    });
    setJobs(prevJobs => prevJobs.map(job => 
      job.id === jobId ? { ...job, startTime: new Date(), status: 'in-progress' } : job
    ));
  };

  const completeJob = async (orgId, jobId, notes) => {
    const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
    const endTime = serverTimestamp();
    await updateDoc(jobRef, {
      endTime,
      status: 'completed',
      notes,
      // Note: Duration calculation needs to be handled separately
    });
    setJobs(prevJobs => prevJobs.map(job => 
      job.id === jobId ? { ...job, endTime: new Date(), status: 'completed', notes } : job
    ));
  };

  return (
    <JobContext.Provider value={{ jobs, fetchJobs, startJob, completeJob }}>
      {children}
    </JobContext.Provider>
  );
};
