import React, { useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { prefixId } from '../utils/prefixIdTaskboard';
import styles from '../styles/TaskBoard.module.css';

const Card = React.memo(({ card, index, editCard, deleteCard }) => {
  // Ensure card has all required properties with defaults
  const safeCard = {
    id: card?.id || '',
    clientName: card?.clientName || '',
    description: card?.description || '',
    priority: card?.priority || 'Medium',
    ...card
  };

  const [showEditCardDialog, setShowEditCardDialog] = useState(false);
  const [editedCardName, setEditedCardName] = useState(safeCard.clientName);
  const [editedCardDescription, setEditedCardDescription] = useState(
    safeCard.description
  );
  const [editedCardPriority, setEditedCardPriority] = useState(
    safeCard.priority
  );

  const handleEditCard = () => {
    if (editedCardName && editedCardName.trim()) {
      editCard(
        safeCard.id,
        editedCardName,
        editedCardDescription,
        editedCardPriority
      );
      setShowEditCardDialog(false);
    }
  };

  return (
    <Draggable draggableId={prefixId('card', safeCard.id)} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${styles.card} ${styles[`priority-${safeCard.priority.toLowerCase()}`]} ${
            snapshot.isDragging ? styles['is-dragging'] : ''
          }`}
          aria-label={`Card: ${safeCard.clientName}`}
        >
          <div className={styles['card-header']}>
            <div className={styles['card-actions']}>
              <button
                onClick={() => setShowEditCardDialog(true)}
                className={styles['edit-button']}
                aria-label={`Edit Card: ${safeCard.clientName}`}
              >
                <FaEdit />
              </button>
              <button
                onClick={() => deleteCard(safeCard.id)}
                className={styles['delete-button']}
                aria-label={`Delete Card: ${safeCard.clientName}`}
              >
                <FaTrash />
              </button>
            </div>
          </div>
          <h3>{safeCard.clientName}</h3>
          <p>{safeCard.description}</p>
          <p>Priority: {safeCard.priority}</p>
          
          <Dialog
            open={showEditCardDialog}
            onClose={() => setShowEditCardDialog(false)}
            aria-labelledby="edit-card-dialog-title"
          >
            <DialogTitle id="edit-card-dialog-title">Edit Card</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Client Name"
                type="text"
                fullWidth
                value={editedCardName}
                onChange={(e) => setEditedCardName(e.target.value)}
                aria-label="Client Name"
              />
              <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                multiline
                rows={3}
                value={editedCardDescription}
                onChange={(e) => setEditedCardDescription(e.target.value)}
                aria-label="Card Description"
              />
              <Select
                value={editedCardPriority}
                onChange={(e) => setEditedCardPriority(e.target.value)}
                fullWidth
                margin="dense"
                aria-label="Card Priority"
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
              </Select>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowEditCardDialog(false)}>Cancel</Button>
              <Button onClick={handleEditCard} disabled={!editedCardName?.trim()}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </Draggable>
  );
});

export default Card;