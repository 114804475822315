// File: src/store/actions/customerActions.js

import { getClientCustomFields, getAllCustomFields, updateClientCustomField, fetchContactDetails, updateContactDetails } from '../../utils/api';
import { startLoading, setCustomer, setError, setCustomFields } from '../reducers/clientReducer';

// Fetch customer profile action
export const fetchCustomerProfile = (contactId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const customerData = await fetchContactDetails(contactId);
    dispatch(setCustomer({ clientId: contactId, profileData: customerData }));
  } catch (error) {
    console.error('Failed to fetch customer profile:', error);
    dispatch(setError('Failed to fetch customer profile.'));
  }
};

// Fetch all custom fields available for the CRM
export const fetchAvailableCustomFields = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const customFields = await getAllCustomFields();
    dispatch(setCustomFields({ clientId: 'global', customFields })); // Saving globally available custom fields
  } catch (error) {
    console.error('Error fetching available custom fields:', error);
    dispatch(setError('Failed to fetch available custom fields.'));
  }
};

// Fetch custom fields for a specific client
export const fetchCustomFields = (clientId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const customFields = await getClientCustomFields(clientId);
    dispatch(setCustomFields({ clientId, customFields }));
  } catch (error) {
    console.error('Error fetching custom fields:', error);
    dispatch(setError('Failed to fetch custom fields.'));
  }
};

// Update custom fields for a client
export const saveCustomFields = (clientId, updatedFields) => async (dispatch) => {
  dispatch(startLoading());
  try {
    for (const field of updatedFields) {
      await updateClientCustomField(clientId, field.id, field.value);
    }
    dispatch(setCustomFields({ clientId, customFields: updatedFields }));
  } catch (error) {
    console.error('Error updating custom fields:', error);
    dispatch(setError('Failed to update custom fields.'));
  }
};

// Update customer profile action
export const updateCustomerProfile = (contactId, updatedData) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const updatedCustomer = await updateContactDetails(contactId, updatedData);
    dispatch(setCustomer({ clientId: contactId, profileData: updatedCustomer }));
    return updatedCustomer; // Return updated customer data for further verification
  } catch (error) {
    console.error('Failed to update customer profile:', error);
    dispatch(setError('Failed to update customer profile.'));
    throw error; // Re-throw the error so it can be caught in the component
  }
};
