// src/components/TaskBoard/TaskBoardContext.js

import React, { createContext, useContext, useReducer, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth } from '../firebase';
import debounce from 'lodash/debounce';

const TaskBoardContext = createContext();

const initialState = {
  pipelines: [],
  cards: [],
};

// Helper function to clean undefined and null values
const cleanObject = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(item => cleanObject(item));
  }
  
  if (obj && typeof obj === 'object') {
    const cleaned = {};
    for (const key in obj) {
      if (obj[key] != null) { // removes both undefined and null
        cleaned[key] = cleanObject(obj[key]);
      }
    }
    return cleaned;
  }
  
  return obj;
};

function taskBoardReducer(state, action) {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return action.payload;
    case 'ADD_PIPELINE':
      return {
        ...state,
        pipelines: [...state.pipelines, { id: uuidv4(), name: action.payload, stages: [], availableCards: [] }],
      };
    case 'EDIT_PIPELINE':
      return {
        ...state,
        pipelines: state.pipelines.map(pipeline =>
          pipeline.id === action.payload.id ? { ...pipeline, name: action.payload.name } : pipeline
        ),
      };
    case 'DELETE_PIPELINE':
      return {
        ...state,
        pipelines: state.pipelines.filter(pipeline => pipeline.id !== action.payload),
      };
    case 'ADD_STAGE':
      return {
        ...state,
        pipelines: state.pipelines.map(pipeline =>
          pipeline.id === action.payload.pipelineId
            ? { ...pipeline, stages: [...pipeline.stages, { id: uuidv4(), name: action.payload.name, buckets: [], isBreakAway: action.payload.isBreakAway }] }
            : pipeline
        ),
      };
    case 'EDIT_STAGE':
      return {
        ...state,
        pipelines: state.pipelines.map(pipeline =>
          pipeline.id === action.payload.pipelineId
            ? {
                ...pipeline,
                stages: pipeline.stages.map(stage =>
                  stage.id === action.payload.stageId
                    ? { ...stage, name: action.payload.name, isBreakAway: action.payload.isBreakAway }
                    : stage
                ),
              }
            : pipeline
        ),
      };
    case 'DELETE_STAGE':
      return {
        ...state,
        pipelines: state.pipelines.map(pipeline =>
          pipeline.id === action.payload.pipelineId
            ? { ...pipeline, stages: pipeline.stages.filter(stage => stage.id !== action.payload.stageId) }
            : pipeline
        ),
      };
      case 'ADD_BUCKET':
        return {
          ...state,
          pipelines: state.pipelines.map(pipeline =>
            pipeline.id === action.payload.pipelineId
              ? {
                  ...pipeline,
                  stages: pipeline.stages.map(stage =>
                    stage.id === action.payload.stageId
                      ? {
                          ...stage,
                          buckets: [...stage.buckets, {
                            id: uuidv4(),
                            name: action.payload.name || '',
                            description: action.payload.description || '',
                            cards: []
                          }]
                        }
                      : stage
                  ),
                }
              : pipeline
          ),
        };
    case 'EDIT_BUCKET':
      return {
        ...state,
        pipelines: state.pipelines.map(pipeline =>
          pipeline.id === action.payload.pipelineId
            ? {
                ...pipeline,
                stages: pipeline.stages.map(stage =>
                  stage.id === action.payload.stageId
                    ? {
                        ...stage,
                        buckets: stage.buckets.map(bucket =>
                          bucket.id === action.payload.bucketId
                            ? { ...bucket, name: action.payload.name, description: action.payload.description }
                            : bucket
                        ),
                      }
                    : stage
                ),
              }
            : pipeline
        ),
      };
    case 'DELETE_BUCKET':
      return {
        ...state,
        pipelines: state.pipelines.map(pipeline =>
          pipeline.id === action.payload.pipelineId
            ? {
                ...pipeline,
                stages: pipeline.stages.map(stage =>
                  stage.id === action.payload.stageId
                    ? { ...stage, buckets: stage.buckets.filter(bucket => bucket.id !== action.payload.bucketId) }
                    : stage
                ),
              }
            : pipeline
        ),
      };
      case 'ADD_CARD':
        const newCard = {
          id: uuidv4(),
          clientName: action.payload.clientName || '',
          description: action.payload.description || '',
          priority: action.payload.priority || 'Medium',
          pipelineId: action.payload.pipelineId
        };
        return {
          ...state,
          cards: [...state.cards, newCard],
          pipelines: state.pipelines.map(pipeline =>
            pipeline.id === action.payload.pipelineId
              ? { ...pipeline, availableCards: [...pipeline.availableCards, newCard.id] }
              : pipeline
          ),
        };
    case 'EDIT_CARD':
      return {
        ...state,
        cards: state.cards.map(card =>
          card.id === action.payload.id
            ? { ...card, clientName: action.payload.clientName, description: action.payload.description, priority: action.payload.priority }
            : card
        ),
      };
    case 'DELETE_CARD':
      return {
        ...state,
        cards: state.cards.filter(card => card.id !== action.payload),
        pipelines: state.pipelines.map(pipeline => ({
          ...pipeline,
          availableCards: pipeline.availableCards.filter(id => id !== action.payload),
          stages: pipeline.stages.map(stage => ({
            ...stage,
            buckets: stage.buckets.map(bucket => ({
              ...bucket,
              cards: bucket.cards.filter(id => id !== action.payload),
            })),
          })),
        })),
      };
    case 'MOVE_CARD':
      const { source, destination, draggableId, type } = action.payload;
      
      if (type === 'PIPELINE') {
        const newPipelines = Array.from(state.pipelines);
        const [movedPipeline] = newPipelines.splice(source.index, 1);
        newPipelines.splice(destination.index, 0, movedPipeline);
        return { ...state, pipelines: newPipelines };
      }
      
      if (type === 'STAGE') {
        const sourcePipelineId = source.droppableId.split('___')[1];
        const destPipelineId = destination.droppableId.split('___')[1];
        const stageId = draggableId.split('___')[1];

        return {
          ...state,
          pipelines: state.pipelines.map(pipeline => {
            if (pipeline.id === sourcePipelineId) {
              const stageToMove = pipeline.stages.find(s => s.id === stageId);
              if (!stageToMove) return pipeline;

              const newStages = pipeline.stages.filter(s => s.id !== stageId);
              
              if (sourcePipelineId === destPipelineId) {
                newStages.splice(destination.index, 0, stageToMove);
                return { ...pipeline, stages: newStages };
              }
              return { ...pipeline, stages: newStages };
            }
            
            if (pipeline.id === destPipelineId && sourcePipelineId !== destPipelineId) {
              const sourcePipeline = state.pipelines.find(p => p.id === sourcePipelineId);
              const stageToMove = sourcePipeline.stages.find(s => s.id === stageId);
              if (!stageToMove) return pipeline;

              const newStages = [...pipeline.stages];
              newStages.splice(destination.index, 0, stageToMove);
              return { ...pipeline, stages: newStages };
            }
            
            return pipeline;
          })
        };
      }
      
      if (type === 'BUCKET') {
        const sourceStageId = source.droppableId.split('___')[1];
        const destStageId = destination.droppableId.split('___')[1];
        const bucketId = draggableId.split('___')[1];

        return {
          ...state,
          pipelines: state.pipelines.map(pipeline => ({
            ...pipeline,
            stages: pipeline.stages.map(stage => {
              if (stage.id === sourceStageId) {
                return {
                  ...stage,
                  buckets: stage.buckets.filter(bucket => bucket.id !== bucketId)
                };
              }
              if (stage.id === destStageId) {
                const bucketToMove = state.pipelines
                  .flatMap(p => p.stages)
                  .flatMap(s => s.buckets)
                  .find(b => b.id === bucketId);

                if (bucketToMove) {
                  const newBuckets = Array.from(stage.buckets);
                  newBuckets.splice(destination.index, 0, bucketToMove);
                  return { ...stage, buckets: newBuckets };
                }
              }
              return stage;
            })
          }))
        };
      }
      
      if (type === 'CARD') {
        const cardId = draggableId.split('___')[1];
        const card = state.cards.find(c => c.id === cardId);

        if (!card) return state;

        const isSourceAvailable = source.droppableId.startsWith('available-cards___');
        const isDestinationAvailable = destination.droppableId.startsWith('available-cards___');

        const sourcePipelineId = isSourceAvailable
          ? source.droppableId.split('___')[1]
          : state.pipelines.find(p => p.stages.some(s => s.buckets.some(b => b.id === source.droppableId.split('___')[1])))?.id;
        const destPipelineId = isDestinationAvailable
          ? destination.droppableId.split('___')[1]
          : state.pipelines.find(p => p.stages.some(s => s.buckets.some(b => b.id === destination.droppableId.split('___')[1])))?.id;

        if (!sourcePipelineId || !destPipelineId) return state;

        return {
          ...state,
          pipelines: state.pipelines.map(pipeline => {
            let newPipeline = { ...pipeline };
            
            if (pipeline.id === sourcePipelineId) {
              if (isSourceAvailable) {
                newPipeline.availableCards = newPipeline.availableCards.filter(id => id !== cardId);
              } else {
                newPipeline.stages = newPipeline.stages.map(stage => ({
                  ...stage,
                  buckets: stage.buckets.map(bucket => {
                    if (bucket.id === source.droppableId.split('___')[1]) {
                      return { ...bucket, cards: bucket.cards.filter(id => id !== cardId) };
                    }
                    return bucket;
                  })
                }));
              }
            }
            
            if (pipeline.id === destPipelineId) {
              if (isDestinationAvailable) {
                if (!newPipeline.availableCards.includes(cardId)) {
                  newPipeline.availableCards = [...newPipeline.availableCards, cardId];
                }
              } else {
                newPipeline.stages = newPipeline.stages.map(stage => ({
                  ...stage,
                  buckets: stage.buckets.map(bucket => {
                    if (bucket.id === destination.droppableId.split('___')[1]) {
                      if (!bucket.cards.includes(cardId)) {
                        return { ...bucket, cards: [...bucket.cards, cardId] };
                      }
                    }
                    return bucket;
                  })
                }));
              }
            }

            return newPipeline;
          }),
          cards: state.cards.map(c => 
            c.id === cardId ? { ...c, pipelineId: destPipelineId } : c
          )
        };
      }
      
      return state;
    default:
      return state;
  }
}

export function TaskBoardProvider({ children, organizationId }) {
  const [state, dispatch] = useReducer(taskBoardReducer, initialState);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Load state from Firestore
  useEffect(() => {
    const loadStateFromFirestore = async () => {
      const user = auth.currentUser;
      if (!user || !organizationId) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const docRef = doc(db, 'organizations', organizationId, 'taskBoards', user.uid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          dispatch({ type: 'SET_INITIAL_STATE', payload: data });
        }
        setError(null);
      } catch (error) {
        console.error('Error loading state from Firestore:', error);
        setError('Failed to load task board data. Please try refreshing the page.');
      } finally {
        setIsLoading(false);
      }
    };

    loadStateFromFirestore();
  }, [organizationId]);

  // Debounced save function with data cleaning
  const debouncedSave = debounce(async (newState, orgId) => {
    const user = auth.currentUser;
    if (!user || !orgId) return;

    try {
      const docRef = doc(db, 'organizations', orgId, 'taskBoards', user.uid);
      // Clean the state before saving
      const cleanedState = cleanObject(newState);
      await setDoc(docRef, cleanedState);
    } catch (error) {
      console.error('Error saving state to Firestore:', error);
      setError('Failed to save changes. Please try again.');
    }
  }, 1000);

  // Save state to Firestore
  useEffect(() => {
    if (!isLoading && state !== initialState) {
      debouncedSave(state, organizationId);
    }
    
    return () => {
      debouncedSave.cancel();
    };
  }, [state, organizationId, isLoading]);

  const wrappedDispatch = (action) => {
    try {
      dispatch(action);
    } catch (error) {
      console.error('Error dispatching action:', error);
      setError('An error occurred while updating the task board.');
    }
  };

  const contextValue = {
    state,
    dispatch: wrappedDispatch,
    isLoading,
    error,
    clearError: () => setError(null)
  };

  return (
    <TaskBoardContext.Provider value={contextValue}>
      {children}
    </TaskBoardContext.Provider>
  );
}

export function useTaskBoard() {
  const context = useContext(TaskBoardContext);
  if (!context) {
    throw new Error('useTaskBoard must be used within a TaskBoardProvider');
  }
  return context;
}