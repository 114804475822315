import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomerProfile } from '../store/actions/customerActions';

const CustomerProfile = ({ selectedClientId }) => {
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.client.customerProfiles[selectedClientId]);
  const [isEditing, setIsEditing] = useState(false);
  const [localData, setLocalData] = useState(null);

  useEffect(() => {
    if (customerData) {
      const filteredData = {
        firstName: customerData.firstName || '',
        lastName: customerData.lastName || '',
        phone: customerData.phone || '',
        email: customerData.email || '',
        address1: customerData.address1 || '',
        city: customerData.city || '',
        state: customerData.state || '',
        postalCode: customerData.postalCode || '',
      };
      setLocalData(filteredData);
    }
  }, [customerData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await dispatch(updateCustomerProfile(selectedClientId, localData));
      console.log('Update successful for client:', selectedClientId);
    } catch (error) {
      console.error('Error occurred while updating profile:', error);
    }
    setIsEditing(false);
  };

  if (!localData) {
    return <div className="no-contact-selected">Select a client to view their details.</div>;
  }

  return (
    <div className="customer-profile card">
      <h2 className="card-title">Customer Profile</h2>
      <div className="profile-fields">
        {Object.entries(localData).map(([field, value]) => (
          <div key={field} className="profile-field">
            <label>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
            <input
              type="text"
              name={field}
              value={value}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="field-input"
            />
          </div>
        ))}
      </div>
      <div className="profile-actions">
        <button 
          onClick={() => setIsEditing((prev) => !prev)}
          className="btn btn-secondary"
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </button>
        {isEditing && (
          <button 
            onClick={handleSave} 
            className="btn btn-primary save-button"
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};

CustomerProfile.propTypes = {
  selectedClientId: PropTypes.string,
};

export default CustomerProfile;