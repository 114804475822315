// /src/components/JobEditForm.jsx

import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import RecurrenceSelect from './RecurrenceSelect';
import styles from '../styles/JobsOverview.module.css';

const JobEditForm = ({
  job,
  handleEditInputChange,
  handleAddRooms,
  handleDeleteRoom,
  openAddRoomsModal,
  setOpenAddRoomsModal,
  availableRooms,
}) => {
  return (
    <Box className={styles.editForm}>
      <TextField
        label="Customer Name"
        value={job.customerName}
        onChange={(e) => handleEditInputChange('customerName', e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Cleaner Name"
        value={job.cleanerName}
        onChange={(e) => handleEditInputChange('cleanerName', e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Address"
        value={job.address}
        onChange={(e) => handleEditInputChange('address', e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Appointment Date and Time"
        type="datetime-local"
        value={job.appointmentDate}
        onChange={(e) => handleEditInputChange('appointmentDate', e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      {/* Recurring Options */}
      <FormControlLabel
        control={
          <Checkbox
            checked={job.recurring}
            onChange={(e) => handleEditInputChange('recurring', e.target.checked)}
          />
        }
        label="Recurring Job"
      />
      
      {job.recurring && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <RecurrenceSelect
            value={job.recurrenceRule}
            onChange={(value) => handleEditInputChange('recurrenceRule', value)}
          />
        </Box>
      )}

      {/* Rooms Section */}
      <Box sx={{ mt: 3, mb: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Rooms</Typography>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setOpenAddRoomsModal(true)}
          >
            Add/Edit Rooms
          </Button>
        </Box>
        
        <List>
          {job.rooms?.map((room, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleDeleteRoom(index)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`${room.name} (${room.quantity})`}
                secondary={
                  <Box>
                    {room.tasks?.map((task, taskIndex) => (
                      <Typography key={taskIndex} variant="body2" component="div">
                        • {task.description}
                      </Typography>
                    ))}
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <TextField
        label="Notes"
        value={job.notes}
        onChange={(e) => handleEditInputChange('notes', e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={3}
      />
      <TextField
        label="Important Notes"
        value={job.importantNotes}
        onChange={(e) => handleEditInputChange('importantNotes', e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={3}
      />
    </Box>
  );
};

export default JobEditForm;
