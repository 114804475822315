// useFetchConversations.js

import { useState, useEffect } from 'react';
import { getMessagesByContactId, getConversationMessages } from '../utils/api';
import config from '../config';

const useFetchConversations = (selectedContactId) => {
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConversations = async () => {
      if (!selectedContactId) return;
      setLoading(true);
      try {
        // console.log('Fetching conversations for contact:', selectedContactId);
        const fetchedConversations = await getMessagesByContactId(
          config.hlLocationId,
          selectedContactId,
          config.hlApiToken
        );
        setConversations(fetchedConversations);

        // Fetch messages for each conversation
        const messagePromises = fetchedConversations.map((conversation) =>
          getConversationMessages(conversation.id)
        );
        const allMessagesArrays = await Promise.all(messagePromises);

        // Flatten the array of arrays into a single array of messages
        const allMessages = allMessagesArrays.flat();

        // console.log('Fetched messages:', allMessages); // For debugging

        setMessages(allMessages);
      } catch (err) {
        setError('Failed to fetch conversations or messages.');
        setMessages([]);
      } finally {
        setLoading(false);
      }
    };

    fetchConversations();
  }, [selectedContactId]);

  return { conversations, messages, loading, error };
};

export default useFetchConversations;
