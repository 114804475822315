import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import AuthHeader from './AuthHeader';

const ForgotPassword = ({ isDarkMode, toggleDarkMode }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const auth = getAuth();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset link has been sent to your email.');
    } catch (error) {
      setError('Failed to send reset link. ' + error.message);
    }
  };

  return (
    <div className={`forgot-password-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="auth-card">
        <AuthHeader isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        <img 
          src={isDarkMode ? "/dmlogo.png" : "/logo.png"} 
          alt="Gem City Sales Tools Logo" 
          className="auth-logo"
        />
        <form onSubmit={handleForgotPassword}>
          <h2>Forgot Password</h2>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email"
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <button type="submit" className="btn btn-primary">Send Reset Link</button>
          {message && <div className="success-message">{message}</div>}
          {error && <div className="error-message">{error}</div>}
          <div className="links">
            <Link to="/login">Back to Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;