// src/components/StagesTaskBoard.js

import React, { useState } from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { FaEdit, FaTrash } from 'react-icons/fa';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Bucket from './BucketTaskBoard';
import { prefixId } from '../utils/prefixIdTaskboard';
import styles from '../styles/TaskBoard.module.css'; 
import { Plus } from 'lucide-react';


// **Stage Component**
const Stage = React.memo(
  ({
    stage,
    index,
    pipelineId,
    editStage,
    deleteStage,
    addBucket,
    editBucket,
    deleteBucket,
    editCard,
    deleteCard,
    handleBreakAway,
    cards,
  }) => {
    const [showEditStageDialog, setShowEditStageDialog] = useState(false);
    const [editedStageName, setEditedStageName] = useState(stage.name);
    const [editedStageIsBreakAway, setEditedStageIsBreakAway] = useState(
      stage.isBreakAway
    );

    const handleEditStage = () => {
      if (editedStageName) {
        editStage(pipelineId, stage.id, editedStageName, editedStageIsBreakAway);
        setShowEditStageDialog(false);
      }
    };

    return (
      <Draggable draggableId={prefixId('stage', stage.id)} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`${styles.stage} ${stage.isBreakAway ? styles['break-away-stage'] : ''}`}
            aria-label={`Stage: ${stage.name}`}
          >
              <div className={styles['stage-header']}>
                <div 
                  className={styles['stage-title-row']}
                  {...provided.dragHandleProps}
                  aria-label={`Drag Handle for Stage: ${stage.name}`}
                >
                  <h3>{stage.name}</h3>
                  {stage.isBreakAway && (
                    <span className={styles['break-away-indicator']}>Break Away</span>
                  )}
                </div>
                <div className={styles['stage-actions']}>
                <button
                    onClick={() => addBucket(pipelineId, stage.id, 'New Bucket', '')}
                    className={`${styles['action-button']} ${styles['icon-button']}`}
                    aria-label="Add Bucket"
                  >
                    <Plus />
                  </button>
                  <button
                    onClick={() => setShowEditStageDialog(true)}
                    className={styles['edit-button']}
                    aria-label={`Edit Stage: ${stage.name}`}
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => deleteStage(pipelineId, stage.id)}
                    className={styles['delete-button']}
                    aria-label={`Delete Stage: ${stage.name}`}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            <Droppable
              droppableId={prefixId('stage', stage.id)}
              type="BUCKET"
              direction="vertical"
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`${styles['stage-content']} ${
                    snapshot.isDraggingOver ? styles['dragging-over'] : ''
                  }`}
                  aria-label={`Buckets in Stage: ${stage.name}`}
                  style={{ minHeight: '50px' }}
                >
                  {stage.buckets.map((bucket, bucketIndex) => (
                    <Bucket
                      key={prefixId('bucket', bucket.id)}
                      bucket={bucket}
                      index={bucketIndex}
                      stageId={stage.id}
                      pipelineId={pipelineId}
                      editBucket={editBucket}
                      deleteBucket={deleteBucket}
                      editCard={editCard}
                      deleteCard={deleteCard}
                      handleBreakAway={handleBreakAway}
                      isBreakAwayStage={stage.isBreakAway}
                      cards={cards}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            {/* MUI Dialog components remain unchanged */}
            <Dialog
              open={showEditStageDialog}
              onClose={() => setShowEditStageDialog(false)}
              aria-labelledby="edit-stage-dialog-title"
            >
              <DialogTitle id="edit-stage-dialog-title">Edit Stage</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Stage Name"
                  type="text"
                  fullWidth
                  value={editedStageName}
                  onChange={(e) => setEditedStageName(e.target.value)}
                  aria-label="Stage Name"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editedStageIsBreakAway}
                      onChange={(e) =>
                        setEditedStageIsBreakAway(e.target.checked)
                      }
                      name="isBreakAway"
                      color="primary"
                    />
                  }
                  label="Break Away Stage"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowEditStageDialog(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={handleEditStage}
                  disabled={!editedStageName.trim()}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Draggable>
    );
  }
);

export default Stage;