import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useFetchCallsByDate from '../hooks/useFetchCallsByDate';

const DatePickerComponent = ({ selectedDate, onDateChange, onConversationsFetch }) => {
  const { conversations, loading, error } = useFetchCallsByDate(selectedDate);

  useEffect(() => {
    if (selectedDate) {
      // Only fetch conversations if a date is selected
      onConversationsFetch(conversations);
    }
  }, [conversations, selectedDate, onConversationsFetch]);

  return (
    <div>
      <h2>Select Date</h2>
      <DatePicker selected={selectedDate} onChange={onDateChange} />
      {loading && selectedDate && <div>Loading conversations...</div>}
      {error && selectedDate && <div>{error}</div>}
    </div>
  );
};

DatePickerComponent.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func.isRequired,
  onConversationsFetch: PropTypes.func.isRequired,
};

export default DatePickerComponent;
