// /src/components/OrgSettings.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import UserModal from './UserModal';
import styles from '../styles/OrgSettings.module.css';
import { KeyIcon, Edit2Icon, Trash2Icon } from 'lucide-react';
import { organizationApi } from '../utils/api'; // Import the API functions

const OrganizationSettings = () => {
  const { user } = useAuth();
  const [orgName, setOrgName] = useState('');
  const [orgId, setOrgId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [editUserData, setEditUserData] = useState(null); // New state for editing user

  const getAuthToken = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken(true);
      return token;
    } catch (err) {
      console.error('Error getting auth token:', err);
      throw err;
    }
  };

  useEffect(() => {
    const fetchOrgData = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
  
          if (userData?.orgId) {
            setOrgId(userData.orgId);
            const orgDoc = await getDoc(doc(db, 'organizations', userData.orgId));
  
            if (orgDoc.exists()) {
              const orgData = orgDoc.data();
              setOrgName(orgData.name);
              setIsAdmin(orgData.adminIds.includes(user.uid));
  
              // Fetch all users in the organization
              const userPromises = orgData.userIds.map(async (userId) => {
                const userRef = doc(db, 'users', userId);
                const userSnap = await getDoc(userRef);
                
                if (userSnap.exists()) {
                  const userData = userSnap.data();
                  
                  // Check if user is also in cleaners collection
                  const cleanerRef = doc(db, 'organizations', userData.orgId, 'cleaners', userId);
                  const cleanerSnap = await getDoc(cleanerRef);
                  
                  return {
                    uid: userId,
                    email: userData.email,
                    role: userData.role || (orgData.adminIds.includes(userId) ? 'admin' : 'member'),
                    payType: userData.payType,
                    payRate: userData.payRate,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    phoneNumber: userData.phoneNumber,
                    address: userData.address,
                    manHourCost: userData.manHourCost,
                    isInCleanersCollection: cleanerSnap.exists()
                  };
                }
                return null;
              });
  
              const userResults = await Promise.all(userPromises);
              setUsers(userResults.filter((user) => user !== null));
            }
          }
        } catch (err) {
          console.error('Error fetching organization data:', err);
          setError('Failed to load organization data');
        } finally {
          setLoading(false);
        }
      }
    };
  
    fetchOrgData();
  }, [user]);

  const addUser = async (userData) => {
    if (!user || !isAdmin || !userData.email || !orgId) {
      setError('Invalid request or insufficient permissions');
      return;
    }
  
    try {
      setLoading(true);
      const token = await getAuthToken();
  
      // Ensure role is properly set
      const enrichedUserData = {
        ...userData,
        orgId,
        role: userData.role || 'member' // Make sure role is explicitly set
      };
  
      const response = await organizationApi.createUser(token, enrichedUserData);
  
      if (response.data.success) {
        const newUser = {
          uid: response.data.uid,
          ...enrichedUserData,
          isInCleanersCollection: enrichedUserData.role === 'cleaner'
        };
        setUsers([...users, newUser]);
        setIsModalOpen(false);
        setSuccess('User created and invitation email sent successfully');
        setError('');
      }
    } catch (err) {
      console.error('Error adding user:', err);
      setError(err.response?.data?.error || 'Failed to add user');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (userData) => {
    if (!user || !isAdmin || !userData.uid || !orgId) {
      setError('Invalid request or insufficient permissions');
      return;
    }

    try {
      setLoading(true);
      const token = await getAuthToken();

      const response = await organizationApi.updateUser(token, {
        ...userData,
        orgId,
      });

      if (response.data.success) {
        setUsers(users.map((u) => (u.uid === userData.uid ? { ...u, ...userData } : u)));
        setIsModalOpen(false);
        setSuccess('User updated successfully');
        setError('');
        setEditUserData(null);
      }
    } catch (err) {
      console.error('Error updating user:', err);
      setError(err.response?.data?.error || 'Failed to update user');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (uid) => {
    if (!user || !isAdmin || !orgId) {
      setError('You do not have permission to delete users');
      return;
    }

    try {
      setLoading(true);
      const token = await getAuthToken();

      const response = await organizationApi.deleteUser(token, {
        uid,
        orgId,
      });

      if (response.data.success) {
        setUsers(users.filter((u) => u.uid !== uid));
        setSuccess('User removed successfully');
        setError('');
      }
    } catch (err) {
      console.error('Error deleting user:', err);
      setError(err.response?.data?.error || 'Failed to delete user');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (uid, email) => {
    if (!user || !isAdmin) {
      setError('You do not have permission to reset passwords');
      return;
    }

    try {
      setLoading(true);
      const token = await getAuthToken();

      const response = await organizationApi.resetPassword(token, {
        uid,
        orgId,
        email,
      });

      if (response.data.success) {
        setSuccess('Password reset email sent successfully');
        setError('');
      }
    } catch (err) {
      console.error('Error resetting password:', err);
      setError(err.response?.data?.error || 'Failed to send password reset email');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  const updateOrgName = async () => {
    if (!user || !isAdmin || !orgId) {
      setError('You do not have permission to update the organization name');
      return;
    }

    try {
      setLoading(true);
      const token = await getAuthToken();

      const response = await organizationApi.updateOrgName(token, {
        orgId,
        name: orgName,
      });

      if (response.data.success) {
        setSuccess('Organization name updated successfully');
        setError('');
      }
    } catch (err) {
      console.error('Error updating organization name:', err);
      setError(err.response?.data?.error || 'Failed to update organization name');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div className={styles.errorContainer}>
        You do not have admin access to view organization settings.
      </div>
    );
  }

  return (
    <div className={styles.orgContainer}>
      <h2 className={styles.orgTitle}>Organization Settings</h2>

      <div className={styles.orgContent}>
        {/* Organization Name Section */}
        <div className={styles.orgSection}>
          <label htmlFor="orgName" className={styles.label}>
            Organization Name
          </label>
          <div className={styles.orgInputGroup}>
            <input
              type="text"
              id="orgName"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              className={styles.input}
              placeholder="Enter organization name"
            />
            <button
              onClick={updateOrgName}
              disabled={loading}
              className={`${styles.orgButton} ${styles.orgButtonPrimary}`}
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>

        {/* Users List Section */}
        <div className={styles.orgSection}>
          <div className={styles.orgSectionHeader}>
            <h3 className={styles.orgSectionTitle}>Users</h3>
            <button
              onClick={() => setIsModalOpen(true)}
              className={`${styles.orgButton} ${styles.orgButtonSecondary}`}
            >
              Add New User
            </button>
          </div>

          <div className={styles.usersList}>
          {users.map((user) => (
            <div key={user.uid} className={styles.userCard}>
              <div className={styles.userInfo}>
                <div className={styles.userName}>
                  {user.firstName && user.lastName 
                    ? `${user.firstName} ${user.lastName}`
                    : user.email}
                </div>
                <div className={styles.userEmail}>{user.firstName ? user.email : null}</div>
                <div className={styles.userRole}>{user.role}</div>
                {user.payType && (
                  <div className={styles.userDetails}>
                    <span>Pay Type: {user.payType}</span>
                    <span>Pay Rate: ${user.payRate}</span>
                  </div>
                )}
              </div>
              <div className={styles.userActions}>
                <button
                  onClick={() => resetPassword(user.uid, user.email)}
                  className={`${styles.orgIconButton} ${styles.orgIconButtonPrimary}`}
                  title="Reset Password"
                >
                  <KeyIcon size={18} />
                </button>
                <button
                  onClick={() => {
                    setEditUserData(user);
                    setIsModalOpen(true);
                  }}
                  className={`${styles.orgIconButton} ${styles.orgIconButtonPrimary}`}
                  title="Edit User"
                >
                  <Edit2Icon size={18} />
                </button>
                <button
                  onClick={() => deleteUser(user.uid)}
                  className={`${styles.orgIconButton} ${styles.orgIconButtonDanger}`}
                  title="Remove User"
                >
                  <Trash2Icon size={18} />
                </button>
              </div>
            </div>
            ))}
          </div>
        </div>

        {/* Messages */}
        {error && <div className={styles.errorMessage}>{error}</div>}
        {success && <div className={styles.successMessage}>{success}</div>}
      </div>

      {/* User Modal */}
      {isModalOpen && (
        <UserModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setEditUserData(null);
          }}
          onSubmit={editUserData ? updateUser : addUser}
          loading={loading}
          initialData={editUserData}
        />
      )}
    </div>
  );
};

export default OrganizationSettings;

