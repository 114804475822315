// /src/components/CreateCustomerModal.js

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const CreateCustomerModal = ({ open, handleClose, handleCreateCustomer }) => {
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');

  const handleSubmit = () => {
    const newCustomer = {
      name: customerName,
      address: customerAddress,
      createdAt: new Date()
    };
    handleCreateCustomer(newCustomer);
    handleClose();
    // Reset form fields
    setCustomerName('');
    setCustomerAddress('');
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create New Customer</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Customer Name"
          type="text"
          fullWidth
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Address (Street, City, State, Zipcode)"
          type="text"
          fullWidth
          value={customerAddress}
          onChange={(e) => setCustomerAddress(e.target.value)}
          placeholder="123 Main St, Anytown, ST 12345"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={!customerName || !customerAddress}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCustomerModal;