// src/hooks/useCustomFields.js

import { useEffect, useState } from 'react';
import {
  getClientCustomFields,
  getAllCustomFields,
  updateClientCustomField,
} from '../utils/api';

const useCustomFields = (clientId) => {
  const [globalFields, setGlobalFields] = useState([]);
  const [clientFields, setClientFields] = useState({});
  const [sections, setSections] = useState(() => {
    const savedSections = localStorage.getItem('customFieldSections');
    return savedSections ? JSON.parse(savedSections) : [];
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch global custom fields on mount
  useEffect(() => {
    const fetchGlobalFields = async () => {
      try {
        const fields = await getAllCustomFields();
        setGlobalFields(fields);
      } catch (err) {
        console.error('Error fetching global custom fields:', err);
        setError('Failed to fetch global custom fields.');
      }
    };
    fetchGlobalFields();
  }, []);

  // Fetch client-specific custom field values when clientId changes
  useEffect(() => {
    const fetchClientFields = async () => {
      if (clientId) {
        setIsLoading(true);
        try {
          const contactData = await getClientCustomFields(clientId);
          const clientFieldValues = contactData.contact.customFields.reduce(
            (acc, field) => {
              acc[field.id] = field.value;
              return acc;
            },
            {}
          );
          // Only update if data has changed
          if (JSON.stringify(clientFieldValues) !== JSON.stringify(clientFields)) {
            setClientFields(clientFieldValues);
          }
        } catch (err) {
          setError('Failed to fetch client custom fields.');
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchClientFields();
  }, [clientId, clientFields]); // Add clientFields as a dependency

  // Save sections to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('customFieldSections', JSON.stringify(sections));
  }, [sections]);

  const updateCustomFields = async (updatedFields) => {
    if (!clientId) return;
    try {
      await updateClientCustomField(clientId, updatedFields);
      // Update clientFields state
      setClientFields((prevFields) => ({
        ...prevFields,
        ...updatedFields.reduce((acc, field) => {
          acc[field.id] = field.value;
          return acc;
        }, {}),
      }));
    } catch (err) {
      console.error('Error updating custom fields:', err);
      setError('Failed to update custom fields.');
    }
  };

  return {
    globalFields,
    clientFields,
    sections,
    setSections,
    isLoading,
    error,
    updateCustomFields,
  };
};

export default useCustomFields;