// /src/components/JobsOverview.jsx

import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, Typography, TextField, Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { rrulestr } from 'rrule';
import JobCard from './JobCard';
import JobDialog from './JobDialog';
import AddRoomsModal from './AddRoomsModal';
import WeeklyJobsView from './WeeklyJobsView';
import styles from '../styles/JobsOverview.module.css';

const JobsOverview = ({ orgId }) => {
  const [pendingJobs, setPendingJobs] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [selectedJob, setSelectedJob] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedJob, setEditedJob] = useState(null);
  const [error, setError] = useState(null);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [showingRecurringSeries, setShowingRecurringSeries] = useState(false);
  const [recurringInstances, setRecurringInstances] = useState([]);
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (orgId) {
      fetchJobs();
    }
  }, [orgId]);

  const fetchJobs = async () => {
    try {
      console.log("Fetching jobs for orgId:", orgId);
      
      // Fetch room types
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomsList = roomTypesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAvailableRooms(roomsList);
      console.log("Room types fetched:", roomsList.length);
  
      // Fetch jobs
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      
      // Create queries for pending and completed jobs
      const pendingQuery = query(
        jobsRef,
        where('status', '==', 'pending'),
        orderBy('appointmentDate', 'asc')
      );
      
      const completedQuery = query(
        jobsRef,
        where('status', '==', 'completed'),
        orderBy('appointmentDate', 'desc')
      );
  
      console.log("Executing job queries...");
      const [pendingSnapshot, completedSnapshot] = await Promise.all([
        getDocs(pendingQuery),
        getDocs(completedQuery)
      ]);
  
      // Process jobs and handle recurring instances
      const processJobs = (docs) => {
        return docs.map(doc => {
          const jobData = { id: doc.id, ...doc.data() };
          
          // Add visual indicator for recurring jobs
          if (jobData.recurring && jobData.recurrenceRule) {
            jobData.isRecurring = true;
            // Parse and format the recurrence rule for display
            jobData.formattedRecurrence = formatRecurrenceRule(jobData.recurrenceRule);
          }
          
          // Ensure rooms array is properly structured
          if (jobData.rooms) {
            jobData.rooms = jobData.rooms.map(room => ({
              ...room,
              tasks: Array.isArray(room.tasks) ? room.tasks.map(task => ({
                description: typeof task === 'string' ? task : task.description,
                completed: task.completed || false
              })) : []
            }));
          }
          
          return jobData;
        });
      };
  
      console.log("Pending jobs count:", pendingSnapshot.size);
      console.log("Completed jobs count:", completedSnapshot.size);
  
      setPendingJobs(processJobs(pendingSnapshot.docs));
      setCompletedJobs(processJobs(completedSnapshot.docs));
      
      console.log("Jobs fetched successfully");
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setError("An error occurred while fetching jobs. Please try again later.");
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleDateChange = (event) => {
    setFilterDate(event.target.value);
  };

  const filterJobs = (jobs) => {
    return jobs.filter(job => 
      (job.customerName.toLowerCase().includes(searchTerm) || 
       job.cleanerName.toLowerCase().includes(searchTerm)) &&
      (!filterDate || job.appointmentDate.toDate().toISOString().split('T')[0] === filterDate)
    );
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedJob(null);
    setIsEditing(false);
    setEditedJob(null);
    setRecurringInstances([]);
    setShowingRecurringSeries(false);
    setSuccess('');
    setError(null);
  };

  const handleMarkCompleted = async () => {
    if (selectedJob) {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
      await updateDoc(jobRef, { status: 'completed' });
      fetchJobs();
      handleCloseDialog();
    }
  };

  // Initialize edit mode
  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
    setEditedJob({
      ...selectedJob,
      customerName: selectedJob.customerName,
      cleanerName: selectedJob.cleanerName,
      address: selectedJob.address,
      notes: selectedJob.notes,
      importantNotes: selectedJob.importantNotes,
      appointmentDate: selectedJob.appointmentDate.toDate().toISOString().slice(0, 16),
      recurring: selectedJob.recurring,
      recurrenceRule: selectedJob.recurrenceRule,
      rooms: selectedJob.rooms || []
    });
  };

  // Cancel edit mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedJob(null);
  };

  // Handle input changes in edit mode
  const handleEditInputChange = (field, value) => {
    setEditedJob(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Save edited job
  const handleSaveEdit = async () => {
    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
      const updateData = {
        customerName: editedJob.customerName,
        cleanerName: editedJob.cleanerName,
        address: editedJob.address,
        notes: editedJob.notes,
        importantNotes: editedJob.importantNotes,
        appointmentDate: new Date(editedJob.appointmentDate),
        rooms: editedJob.rooms || [],
        recurring: editedJob.recurring,
        recurrenceRule: editedJob.recurring ? editedJob.recurrenceRule : null,
        updatedAt: new Date()
      };
      
      await updateDoc(jobRef, updateData);
      
      // If this is part of a recurring series, ask if they want to update all future occurrences
      if (editedJob.recurring && editedJob.recurrenceGroupId) {
        const updateAll = window.confirm(
          'Do you want to apply these changes to all future occurrences of this recurring job?'
        );
        
        if (updateAll) {
          const futureJobsQuery = query(
            collection(db, 'organizations', orgId, 'jobs'),
            where('recurrenceGroupId', '==', editedJob.recurrenceGroupId),
            where('appointmentDate', '>', new Date(editedJob.appointmentDate))
          );
          
          const futureJobsSnapshot = await getDocs(futureJobsQuery);
          
          const updatePromises = futureJobsSnapshot.docs.map(doc => {
            const futureJobData = {
              ...updateData,
              appointmentDate: doc.data().appointmentDate
            };
            return updateDoc(doc.ref, futureJobData);
          });
          
          await Promise.all(updatePromises);
        }
      }
      
      // Refresh jobs list
      fetchJobs();
      
      // Close edit mode and dialog
      setIsEditing(false);
      setEditedJob(null);
      handleCloseDialog();
      setSuccess('Job updated successfully.');
    } catch (error) {
      console.error("Error updating job:", error);
      setError("Failed to update job.");
    }
  };

  const handleDeleteJob = async () => {
    if (selectedJob) {
      const confirmDelete = window.confirm("Are you sure you want to delete this job?");
      if (!confirmDelete) return;
      try {
        const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
        await deleteDoc(jobRef);
        fetchJobs();
        handleCloseDialog();
      } catch (error) {
        console.error("Error deleting job:", error);
        setError("Failed to delete job.");
      }
    }
  };

  const handleDeleteRecurringJob = async (recurrenceGroupId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete all instances of this recurring job?"
    );
    
    if (!confirmDelete) return;
  
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const recurringJobsQuery = query(
        jobsRef,
        where('recurrenceGroupId', '==', recurrenceGroupId),
        where('status', '==', 'pending')
      );
      
      const recurringJobsSnapshot = await getDocs(recurringJobsQuery);
      
      const deletePromises = recurringJobsSnapshot.docs.map(doc => 
        deleteDoc(doc.ref)
      );
      
      await Promise.all(deletePromises);
      
      // Refresh the jobs list
      fetchJobs();
      handleCloseDialog();
      
    } catch (error) {
      console.error("Error deleting recurring jobs:", error);
      setError("Failed to delete recurring jobs.");
    }
  };

  // Add/Edit Rooms for Jobs
  const handleAddRooms = (roomsToAdd) => {
    setEditedJob(prev => ({
      ...prev,
      rooms: roomsToAdd
    }));
  };
  
  // Add this handler for room deletion
  const handleDeleteRoom = (roomIndex) => {
    setEditedJob(prev => ({
      ...prev,
      rooms: prev.rooms.filter((_, index) => index !== roomIndex)
    }));
  };

  const fetchRecurringInstances = async (recurrenceGroupId) => {
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const recurringQuery = query(
        jobsRef,
        where('recurrenceGroupId', '==', recurrenceGroupId),
        orderBy('appointmentDate', 'asc')
      );
      
      const snapshot = await getDocs(recurringQuery);
      const instances = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setRecurringInstances(instances);
      setShowingRecurringSeries(true);
    } catch (error) {
      console.error("Error fetching recurring instances:", error);
      setError("Failed to fetch recurring instances.");
    }
  };

  const generateMoreInstances = async (job) => {
    try {
      if (!job.recurring || !job.recurrenceRule) return;
  
      // Ensure the recurrenceRule is properly formatted
      let rruleString = job.recurrenceRule;
      
      // If it's just "WEEKLY", convert it to proper RRULE format
      if (!rruleString.startsWith('FREQ=')) {
        const patternMap = {
          'WEEKLY': 'FREQ=WEEKLY;INTERVAL=1',
          'BIWEEKLY': 'FREQ=WEEKLY;INTERVAL=2',
          'MONTHLY': 'FREQ=MONTHLY;INTERVAL=1',
          'BIMONTHLY': 'FREQ=MONTHLY;INTERVAL=2',
          'QUARTERLY': 'FREQ=MONTHLY;INTERVAL=3',
        };
        rruleString = patternMap[rruleString] || `FREQ=${rruleString};INTERVAL=1`;
      }
  
      console.log('Formatted rruleString:', rruleString);
      const dtstart = job.appointmentDate.toDate();
      console.log('dtstart:', dtstart);
  
      // Parse the RRULE string with dtstart option
      const rule = rrulestr(rruleString, { dtstart });
  
      // Get the last existing instance date
      const lastInstance = recurringInstances[recurringInstances.length - 1];
      const startDate = lastInstance.appointmentDate.toDate();
  
      // Generate dates for the next 3 months
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 3);
  
      // Get occurrences between startDate and endDate
      const newDates = rule.between(startDate, endDate);
  
      // Create jobs for new dates
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const jobPromises = newDates.map((date) => {
        // Create a new job object with only the fields we want to copy
        const newJobData = {
          customerName: job.customerName,
          cleanerName: job.cleanerName,
          address: job.address,
          notes: job.notes,
          importantNotes: job.importantNotes,
          appointmentDate: date,
          status: 'pending',
          recurring: job.recurring,
          recurrenceRule: job.recurrenceRule,
          recurrenceGroupId: job.recurrenceGroupId || job.id,
          rooms: job.rooms ? [...job.rooms] : [], // Create a new array if rooms exist
          organizationId: job.organizationId,
          createdAt: new Date(),
          updatedAt: new Date()
        };
  
        return addDoc(jobsRef, newJobData);
      });
  
      await Promise.all(jobPromises);
      await fetchRecurringInstances(job.recurrenceGroupId);
      setSuccess('Generated new instances successfully.');
    } catch (error) {
      console.error('Error generating more instances:', error);
      setError('Failed to generate more instances: ' + error.message);
    }
  };

  const formatRecurrenceRule = (rule) => {
    if (!rule) return 'No recurrence pattern';
    
    const frequency = rule.match(/FREQ=([^;]*)/)?.[1];
    const interval = rule.match(/INTERVAL=([^;]*)/)?.[1] || '1';
    const byDay = rule.match(/BYDAY=([^;]*)/)?.[1];
    
    let description = '';
    
    switch (frequency) {
      case 'DAILY':
        description = `Every ${interval === '1' ? 'day' : `${interval} days`}`;
        break;
      case 'WEEKLY':
        if (byDay) {
          const days = byDay.split(',').map(day => {
            const dayNames = {
              MO: 'Monday', TU: 'Tuesday', WE: 'Wednesday',
              TH: 'Thursday', FR: 'Friday', SA: 'Saturday', SU: 'Sunday'
            };
            return dayNames[day];
          });
          description = `Every ${interval === '1' ? 'week' : `${interval} weeks`} on ${days.join(', ')}`;
        } else {
          description = `Every ${interval === '1' ? 'week' : `${interval} weeks`}`;
        }
        break;
      case 'MONTHLY':
        description = `Every ${interval === '1' ? 'month' : `${interval} months`}`;
        break;
      case 'YEARLY':
        description = `Every ${interval === '1' ? 'year' : `${interval} years`}`;
        break;
      default:
        description = rule;
    }
    
    return description;
  };

  return (
    <div className={styles.JobsOverview}>
      {error && (
        <Typography className={styles.errorMessage} color="error">
          {error}
        </Typography>
      )}
      {success && (
        <Typography className={styles.successMessage} color="success.main">
          {success}
        </Typography>
      )}
      
      <Box>
        <Typography className={styles.searchSection}>
          Search By Customer or Cleaner:
        </Typography>
        <TextField
          label="Customer or Cleaner" 
          variant="outlined" 
          fullWidth 
          margin="normal"
          value={searchTerm}
          onChange={handleSearchChange}
          className={styles.searchField}
        />
      </Box>
  
      <Box className={styles.filterContainer}>
        <Typography className={styles.filterLabel}>
          Filter by date:
        </Typography>
        <TextField
          type="date"
          value={filterDate}
          onChange={handleDateChange}
          className={styles.dateFilter}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>

      {/* Weekly View */}
      <WeeklyJobsView 
        jobs={filterJobs(pendingJobs.concat(completedJobs))} 
        onJobClick={handleJobClick}
      />
      
      {/* Pending Jobs Section */}
      {/* <Typography variant="h5" className={styles.sectionTitle}>
        Pending Jobs
      </Typography>
      <Box className={styles.jobCardsGrid}>
        {filterJobs(pendingJobs).map(job => (
          <JobCard 
            key={job.id} 
            job={job} 
            onClick={handleJobClick} 
          />
        ))}
      </Box> */}

      {/* Completed Jobs Section */}
      {/* <Typography variant="h5" className={styles.sectionTitle}>
        Completed Jobs
      </Typography>
      <Box className={styles.jobCardsGrid}>
        {filterJobs(completedJobs).map(job => (
          <JobCard 
            key={job.id} 
            job={job} 
            onClick={handleJobClick} 
          />
        ))}
      </Box> */}
  
      <JobDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        job={selectedJob}
        isEditing={isEditing}
        handleEditClick={handleEditClick}
        handleDeleteJob={handleDeleteJob}
        handleMarkCompleted={handleMarkCompleted}
        handleDeleteRecurringJob={handleDeleteRecurringJob}
        fetchRecurringInstances={fetchRecurringInstances}
        showingRecurringSeries={showingRecurringSeries}
        recurringInstances={recurringInstances}
        generateMoreInstances={generateMoreInstances}
        handleSaveEdit={handleSaveEdit}
        handleCancelEdit={handleCancelEdit}
        editedJob={editedJob}
        handleEditInputChange={handleEditInputChange}
        handleAddRooms={handleAddRooms}
        handleDeleteRoom={handleDeleteRoom}
        openAddRoomsModal={openAddRoomsModal}
        setOpenAddRoomsModal={setOpenAddRoomsModal}
        availableRooms={availableRooms}
        formatRecurrenceRule={formatRecurrenceRule}
      />
  
      {openAddRoomsModal && (
        <AddRoomsModal
          open={openAddRoomsModal}
          handleClose={() => setOpenAddRoomsModal(false)}
          availableRooms={availableRooms}
          handleAddRooms={handleAddRooms}
          existingRooms={editedJob?.rooms || []}
          className={styles.modalOverlay}
        />
      )}
    </div>
  );
};

export default JobsOverview;
