// ./src/hooks/useTranscription.js
import { useState, useEffect } from 'react';
import { transcribeAudioAPI, extractDataAPI, processWithGPT4oAPI } from '../utils/api';

const useTranscription = () => {
  const [transcription, setTranscription] = useState('');
  const [outputText, setOutputText] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [error, setError] = useState(null);

  // // Log state changes for debugging
  // useEffect(() => {
  //   console.log('Transcription state updated:', transcription);
  // }, [transcription]);

  // useEffect(() => {
  //   console.log('OutputText state updated:', outputText);
  // }, [outputText]);

  // Function to transcribe audio
  const transcribeAudio = async (audioBlob) => {
    console.log('info', 'Starting audio transcription');
    setStatus('Starting Audio Transcription...');
    setLoading(true);

    try {
      const data = await transcribeAudioAPI(audioBlob);
      console.log('info', 'Transcription successful');
      setStatus('Transcription Successful');

      const plainText = data.segments.map(segment => segment.text).join(' ');
      const modifiedText = plainText.replace(
        "This call will be recorded for quality purposes",
        "System: This call will be recorded for quality purposes"
      );

      setTranscription(modifiedText);
      setStatus('Starting Data Extraction from Transcription...');
      await extractData(modifiedText);

      setStatus('Data Extraction Completed Successfully');
      setStatus('Starting Post-Processing for Speaker Recognition...');
      await processWithGPT4o(data);

      setStatus('Speaker Recognition Completed Successfully');

    } catch (error) {
      console.log('error', `Transcription failed: ${error.message}`);
      setError(`Transcription failed: ${error.message}`);
      setStatus('Error During Processing');
    } finally {
      setLoading(false);
    }
  };

  const extractData = async (text) => {
    console.log('info', 'Starting data extraction from transcription');
    try {
      const data = await extractDataAPI(text);
      if (data.choices && data.choices.length > 0) {
        let cleanedText = data.choices[0].message.content.replace(/[#*]/g, '').trim();
        setOutputText((prevText) => prevText + `Notes from Call:\n${cleanedText}\n`);
        console.log('info', 'Data extraction completed successfully');
      } else {
        throw new Error('No data returned from OpenAI');
      }
    } catch (error) {
      console.log('error', `Data extraction failed: ${error.message}`);
      setError(`Error extracting data: ${error.message}`);
    }
  };

  const processWithGPT4o = async (transcriptionData) => {
    console.log('info', 'Starting post-processing for speaker recognition');
    try {
      const transcriptText = transcriptionData.segments.map(segment => segment.text).join('\n');
      const data = await processWithGPT4oAPI(transcriptText);

      if (data.choices && data.choices.length > 0) {
        const processedText = data.choices[0].message.content;
        setTranscription((prevTranscription) => `${prevTranscription}\n\nSpeaker-identified Transcript:\n${processedText}`);
        console.log('info', 'Speaker recognition completed successfully');
      } else {
        throw new Error('No data returned from OpenAI');
      }
    } catch (error) {
      console.log('error', `Post-processing for speaker recognition failed: ${error.message}`);
      setError(`Error processing speaker recognition: ${error.message}`);
    }
  };

  const processManualText = async (text) => {
    if (text) {
      await extractData(text);
    } else {
      setError('Please enter some text to process.');
    }
  };

  return { transcription, outputText, loading, status, error, transcribeAudio, processManualText, setTranscription, setOutputText };
};

export default useTranscription;
