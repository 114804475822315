// src/components/CustomFieldsSection.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FieldSelectionModal from './FieldSelectionModal';
import fieldRenderer from '../utils/fieldRenderer';

const CustomFieldsSection = ({
  section,
  globalFields,
  fieldValues,
  isEditingSections,
  isEditingFields,
  onDeleteSection,
  onUpdateSection,
  onUpdateFieldValue,
}) => {
  const [isExpanded, setIsExpanded] = useState(section.isOpen);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Initialize localFieldValues only once
  const [localFieldValues, setLocalFieldValues] = useState(() => ({ ...fieldValues }));

  useEffect(() => {
    if (!isEditingFields) {
      // When exiting edit mode, sync localFieldValues with fieldValues
      setLocalFieldValues({ ...fieldValues });
    }
  }, [isEditingFields, fieldValues]);

  const handleAddField = () => {
    setIsModalOpen(true);
  };

  const handleFieldSelect = (selectedFields) => {
    const updatedFieldIds = [
      ...(section.fieldIds || []),
      ...selectedFields.map((field) => field.id),
    ];
    const updatedSection = {
      ...section,
      fieldIds: updatedFieldIds,
    };
    onUpdateSection(updatedSection);
    setIsModalOpen(false);
  };

  const handleFieldChange = (fieldId, value) => {
    setLocalFieldValues((prevValues) => ({
      ...prevValues,
      [fieldId]: value,
    }));
  };

  const handleFieldBlur = (fieldId) => {
    const value = localFieldValues[fieldId];
    if (onUpdateFieldValue) {
      onUpdateFieldValue(fieldId, value);
    }
  };

  const handleDeleteField = (fieldId) => {
    const updatedFieldIds = section.fieldIds.filter((id) => id !== fieldId);
    const updatedSection = { ...section, fieldIds: updatedFieldIds };
    onUpdateSection(updatedSection);
  };

  const toggleExpand = () => {
    const updatedSection = { ...section, isOpen: !isExpanded };
    setIsExpanded(!isExpanded);
    onUpdateSection(updatedSection);
  };

  const handleNameChange = (e) => {
    const updatedSection = { ...section, name: e.target.value };
    onUpdateSection(updatedSection);
  };

  // Reconstruct fields from fieldIds
  const fields = (section.fieldIds || [])
    .map((fieldId) => globalFields.find((f) => f.id === fieldId))
    .filter(Boolean);

  return (
    <div className="custom-fields-section">
      <div className="section-header" onClick={toggleExpand}>
        <span className={`expand-icon ${isExpanded ? 'expanded' : ''}`}>
          {isExpanded ? '▼' : '▶'}
        </span>
        {isEditingSections ? (
          <input
            type="text"
            value={section.name}
            onChange={handleNameChange}
            className="section-name-input"
          />
        ) : (
          <h3>{section.name}</h3>
        )}
        {isEditingSections && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDeleteSection(section.id);
            }}
            className="btn btn-secondary delete-section-button"
          >
            Delete
          </button>
        )}
      </div>
      {isExpanded && (
        <div className="fields">
          {fields.map((field) => {
            const fieldValue = localFieldValues[field.id] ?? '';
            return (
              <div key={field.id} className="field">
                {fieldRenderer(
                  field,
                  handleFieldChange,
                  isEditingFields,
                  fieldValue,
                  handleFieldBlur // Pass the blur handler
                )}
                {isEditingSections && (
                  <button
                    onClick={() => handleDeleteField(field.id)}
                    className="btn btn-secondary delete-field-button"
                  >
                    Remove Field
                  </button>
                )}
              </div>
            );
          })}
          {isEditingSections && (
            <button
              onClick={handleAddField}
              className="btn btn-secondary add-field-button"
            >
              Add Field
            </button>
          )}
        </div>
      )}
      {isModalOpen && (
        <FieldSelectionModal
          availableFields={globalFields.filter(
            (field) => !(section.fieldIds || []).includes(field.id)
          )}
          onClose={() => setIsModalOpen(false)}
          onSelect={handleFieldSelect}
        />
      )}
    </div>
  );
};

CustomFieldsSection.propTypes = {
  section: PropTypes.object.isRequired,
  globalFields: PropTypes.array.isRequired,
  fieldValues: PropTypes.object.isRequired,
  isEditingSections: PropTypes.bool.isRequired,
  isEditingFields: PropTypes.bool.isRequired,
  onDeleteSection: PropTypes.func.isRequired,
  onUpdateSection: PropTypes.func.isRequired,
  onUpdateFieldValue: PropTypes.func.isRequired,
};

export default CustomFieldsSection;
