// src/hooks/usePipelineStages.js

import { useState, useEffect } from 'react';
import { fetchPipelineStages } from '../utils/api';

const usePipelineStages = () => {
  const [stages, setStages] = useState({});
  const [hasFetched, setHasFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!hasFetched) {
      setLoading(true);
      fetchPipelineStages()
        .then((stageMap) => {
          setStages(stageMap);
          setHasFetched(true);
        })
        .catch((err) => {
          console.error('Error fetching pipeline stages:', err);
          setError(err);
          setHasFetched(true);
        })
        .finally(() => setLoading(false));
    }
  }, [hasFetched]);

  return { stages, loading, error };
};

export default usePipelineStages;
