// src/components/CleanerDashboard.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useJobContext } from '../context/JobContext';
import { useAuth } from '../context/AuthContext';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Box, 
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from '../styles/CleanerDashboard.module.css';
import MobileHeader from './MobileHeader';


const CleanerDashboard = () => {
  const { cleanerId } = useParams();
  const { user } = useAuth();
  const { jobs, fetchJobs } = useJobContext();
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (user && user.orgId) {
      fetchJobs(user.orgId, date);
    }
  }, [user, date, fetchJobs]);

  const filteredJobs = jobs.filter(job => job.cleanerId === cleanerId);

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const formatTime = (date) => {
    return new Date(date.toDate()).toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit' 
    });
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return styles.statusPending;
      case 'in_progress':
        return styles.statusInProgress;
      case 'completed':
        return styles.statusCompleted;
      default:
        return '';
    }
  };

  return (
    <Box className={styles.dashboard}>
      <MobileHeader title="Today's Jobs" showBack={false} />
      <Box className={styles.datePickerContainer}>
        <TextField
          type="date"
          value={date}
          onChange={handleDateChange}
          className={styles.datePicker}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CalendarTodayIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <List className={styles.jobList}>
        {filteredJobs.map((job, index) => (
          <React.Fragment key={job.id}>
            <ListItem
              component={Link}
              to={`/org/${user?.orgId}/job/${job.id}`}
              className={styles.jobListItem}
            >
              <Box className={styles.jobInfo}>
                <Typography variant="subtitle1" className={styles.customerName}>
                  {job.customerName}
                </Typography>
                <Typography variant="body2" className={styles.jobTime}>
                  {formatTime(job.appointmentDate)}
                </Typography>
                <Typography variant="body2" className={styles.jobAddress}>
                  {job.address}
                </Typography>
                <Typography 
                  variant="caption" 
                  className={`${styles.status} ${getStatusColor(job.status)}`}
                >
                  {job.status}
                </Typography>
              </Box>
              <ChevronRightIcon className={styles.chevron} />
            </ListItem>
            {index < filteredJobs.length - 1 && <Divider />}
          </React.Fragment>
        ))}
        {filteredJobs.length === 0 && (
          <ListItem>
            <ListItemText primary="No jobs scheduled for this date" />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default CleanerDashboard;