// src/utils/fieldRenderer.js

import React from 'react';
import CustomFieldInput from '../components/CustomInputField'; // Corrected import path

const fieldRenderer = (field, handleChange, isEditing, value, handleBlur) => {
  const { dataType, name, picklistOptions, id } = field;

  switch (dataType) {
    case 'SINGLE_LINE':
    case 'TEXT':
    case 'NUMBER':
    case 'MULTI_LINE':
    case 'LARGE_TEXT':
      return (
        <div className="form-group">
          <label>{name}</label>
          <CustomFieldInput
            fieldId={id}
            initialValue={value || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isEditing={isEditing}
            dataType={dataType}
          />
        </div>
      );

    case 'SINGLE_OPTIONS':
      return (
        <div className="form-group">
          <label>{name}</label>
          <select
            className="form-control"
            value={value || ''}
            onChange={(e) => handleChange(id, e.target.value)}
            disabled={!isEditing}
          >
            <option value="">Select an option</option>
            {picklistOptions?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      );

    case 'MULTIPLE_OPTIONS':
      return (
        <div className="form-group">
          <label>{name}</label>
          <select
            className="form-control"
            multiple
            value={Array.isArray(value) ? value : []}
            onChange={(e) => {
              const options = e.target.options;
              const selectedValues = [];
              for (let i = 0; i < options.length; i++) {
                if (options[i].selected) {
                  selectedValues.push(options[i].value);
                }
              }
              handleChange(id, selectedValues);
            }}
            disabled={!isEditing}
          >
            {picklistOptions?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      );

    default:
      return (
        <div className="form-group">
          <label>{name}</label>
          <CustomFieldInput
            fieldId={id}
            initialValue={value || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            isEditing={isEditing}
            dataType={dataType}
          />
        </div>
      );
  }
};

export default fieldRenderer;
