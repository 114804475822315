// ./src/components/TranscribeAudio.js
import React, { useState, useEffect } from 'react';

const TranscribeAudio = ({ transcription, outputText, loading, status, transcribeAudio, processManualText, setTranscription, setOutputText }) => {
  const [manualText, setManualText] = useState('');
  const [loadingText, setLoadingText] = useState('Loading');

  // // Debugging to see if props are received and updated
  // useEffect(() => {
  //   console.log('Transcription prop:', transcription);
  //   console.log('Output text prop:', outputText);
  // }, [transcription, outputText]);

  // Effect to handle loading text animation
  useEffect(() => {
    if (loading) {
      const loadingStages = ['Loading', 'Loading.', 'Loading..', 'Loading...'];
      let index = 0;

      const interval = setInterval(() => {
        setLoadingText(loadingStages[index]);
        index = (index + 1) % loadingStages.length; // Cycle through the stages
      }, 500); // Change text every 500ms

      return () => clearInterval(interval); // Clear interval on component unmount or when loading stops
    }
  }, [loading]);

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await transcribeAudio(file);
    }
  };

  const handleProcessManualText = () => {
    processManualText(manualText);
  };

  const handleClearOutput = () => {
    setTranscription('');
    setOutputText(''); // Clear the outputText state
    setManualText('');
  };

  return (
    <div className="transcription-container">
      <div className="uploader-container">
        <div className="transcription-card">
          <h3 className="text-lg font-semibold">Upload Audio File</h3>
          <input
            type="file"
            accept="audio/*"
            onChange={handleFileSelect}
            className="input mt-4"
          />
        </div>
      </div>

      <div className="transcription-processtext-container">
        <div className="transcription-card">
          <div className="card-title flex items-center justify-between mb-2">
            <h3 className="text-lg font-semibold">Transcription</h3>
            {loading && (
              <div className="status-indicator flex items-center gap-2 text-xs">
                <span className="text-gray-600">{loadingText}</span>
              </div>
            )}
          </div>
          <textarea
            value={transcription}
            readOnly
            placeholder="Transcription will appear here..."
            className="transcription-textarea"
          />
        </div>

        <div className="transcription-card">
          <h3 className="text-lg font-semibold">Manual Text Input</h3>
          <textarea
            value={manualText}
            onChange={(e) => setManualText(e.target.value)}
            placeholder="Paste text messages here..."
            className="transcription-textarea"
          />
          <button
            onClick={handleProcessManualText}
            className="btn btn-primary mt-4"
          >
            Process
          </button>
        </div>
      </div>

      <div className="transcription-output-container">
        <div className="transcription-card">
          <div className="card-title flex items-center justify-between mb-2">
            <h3 className="text-lg font-semibold">Processed Output</h3>
          </div>
          <textarea
            value={outputText}
            readOnly
            placeholder="Transcription or extracted data will appear here..."
            className="transcription-output"
          />
          <button
            onClick={handleClearOutput}
            className="btn btn-secondary mt-4 self-end"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default TranscribeAudio;
