// /src/components/CustomersCSVUpload.js

import React, { useState } from 'react';
import { Button, Typography, Box, Alert } from '@mui/material';
import Papa from 'papaparse';

const ClientCSVUpload = ({ onClientsCreated }) => {
  const [error, setError] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          if (results.errors.length > 0) {
            setError('Error parsing CSV file. Please check the format.');
            return;
          }
          
          const clients = processCSVData(results.data);
          onClientsCreated(clients);
          setError(null);
        },
        error: (error) => {
          setError('Error reading CSV file: ' + error.message);
        }
      });
    }
  };

  const processCSVData = (data) => {
    return data.map(row => ({
      name: row.Name,
      email: row.Email,
      phone: row.Phone,
      address: row.Address,
      notes: row.Notes
    }));
  };

  return (
    <Box>
      <Button variant="contained" component="label">
        Upload Client CSV
        <input type="file" hidden accept=".csv" onChange={handleFileUpload} />
      </Button>
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
    </Box>
  );
};

export default ClientCSVUpload;