// src/store/actions/quoteActions.js

export const updateQuotePricing = (pricing) => ({
    type: 'UPDATE_QUOTE_PRICING',
    payload: pricing,
  });
  
  export const updateSelectedMultiplier = (multiplier) => ({
    type: 'UPDATE_SELECTED_MULTIPLIER',
    payload: multiplier,
  });
  
  export const addRoom = (room) => ({
    type: 'ADD_ROOM',
    payload: room,
  });
  
  export const removeRoom = (index) => ({
    type: 'REMOVE_ROOM',
    payload: index,
  });

  export const updateSelectedFrequency = (frequency) => ({
    type: 'UPDATE_SELECTED_FREQUENCY',
    payload: frequency,
  });