// src/utils/prefixIdTaskboard.js

import { v4 as uuidv4 } from 'uuid';

// Generate a pure UUID
export const generateId = () => uuidv4();

// Prefix ID with type
export const prefixId = (type, id) => `${type}___${id}`;

// Extract UUID from prefixed ID
export const extractId = (prefixedId) => prefixedId.split('___')[1];