// utils/parseRoomsList.js

export function processBathrooms(bathrooms) {
  let masterBath = 0;
  let fullBath = 0;
  let halfBath = 0;

  if (typeof bathrooms === 'number') {
    // Handle cases like "3.5 Baths"
    masterBath = 1;
    let remainingBathrooms = bathrooms - 1;
    fullBath = Math.floor(remainingBathrooms);
    halfBath = (remainingBathrooms % 1 >= 0.5) ? 1 : 0;
  } else if (Array.isArray(bathrooms)) {
    // Handle cases with specific bathroom types
    bathrooms.forEach(bath => {
      if (bath.name.toLowerCase().includes('master')) {
        masterBath += bath.quantity;
      } else if (bath.name.toLowerCase().includes('full')) {
        fullBath += bath.quantity;
      } else if (bath.name.toLowerCase().includes('half')) {
        halfBath += bath.quantity;
      }
    });

    // If no master bath was specified, consider the first bathroom as master
    if (masterBath === 0 && (fullBath > 0 || halfBath > 0)) {
      if (fullBath > 0) {
        masterBath = 1;
        fullBath -= 1;
      } else {
        masterBath = 1;
        halfBath -= 1;
      }
    }
  }

  return [
    { name: 'Master Bath', quantity: masterBath },
    { name: 'Full Bath', quantity: fullBath },
    { name: 'Half Bath', quantity: halfBath },
  ];
}

export function normalizeRoomType(roomType) {
  const normalizedRoom = roomType.trim().toLowerCase();
  switch (true) {
    case /pantry/.test(normalizedRoom):
      return 'Pantry';
    case /bed|beds|bedroom|guest room/.test(normalizedRoom):
      return 'Bedrooms';
    case /office/.test(normalizedRoom):
      return 'Office';
    case /game room|gameroom/.test(normalizedRoom):
      return 'Playroom';
    case /full bath|full bathroom/.test(normalizedRoom):
      return 'Full Bath';
    case /half bath|half bathroom/.test(normalizedRoom):
      return 'Half Bath';
    case /master bath|master bathroom/.test(normalizedRoom):
      return 'Master Bath';
    case /bath|baths|bathroom/.test(normalizedRoom):
      return 'Bathroom';
    case /hallway|hall|foyer/.test(normalizedRoom):
      return 'Foyers/Halls';
    case /living room|family room/.test(normalizedRoom):
      return 'Living Room';
    case /dining room/.test(normalizedRoom):
      return 'Dining Room';
    case /kitchen/.test(normalizedRoom):
      return 'Kitchen';
    case /basement/.test(normalizedRoom):
      return 'Basement';
    case /stairs|staircase/.test(normalizedRoom):
      return 'Stairs';
    case /sqft|square feet|sq ft/.test(normalizedRoom):
      return 'Square Foot';
    case /closet|utility room/.test(normalizedRoom):
      return 'Closet/Utility Room/Pantry';
    case /entry way|entryway/.test(normalizedRoom):
      return 'Entry Way';
    case /florida room/.test(normalizedRoom):
      return 'Florida Room';
    case /in home salon/.test(normalizedRoom):
      return 'In Home Salon';
    case /laundry room/.test(normalizedRoom):
      return 'Laundry Room';
    case /loft/.test(normalizedRoom):
      return 'Loft';
    case /mini bars|mini kitchens|mini bar|mini kitchen/.test(normalizedRoom):
      return 'Mini Bars/Kitchens';
    case /monthly fee/.test(normalizedRoom):
      return 'Monthly Fee';
    case /morning room/.test(normalizedRoom):
      return 'Morning Room';
    case /mud room/.test(normalizedRoom):
      return 'Mud Room';
    case /pet fee|pet/.test(normalizedRoom):
      return 'Pet Fee (Per Pet)';
    case /sitting room/.test(normalizedRoom):
      return 'Sitting Room';
    case /workout room/.test(normalizedRoom):
      return 'Workout Room';
    default:
      return roomType;
  }
}

export function extractRoomInfo(line) {
  // Remove leading hyphens or bullet points
  line = line.replace(/^[-•*]\s*/, '');

  // Check for "sqft" or "square feet" pattern
  const sqftMatch = line.match(/(\d+(?:\.\d+)?)\s*(sqft|square\s*feet)/i);
  if (sqftMatch) {
    return {
      roomType: 'Square Foot',
      quantity: parseFloat(sqftMatch[1]),
      details: line
    };
  }

  // Try to extract room type and quantity
  const parts = line.split(':');
  let roomType, quantity, details;

  if (parts.length > 1) {
    // Format: "Room Type: quantity details"
    roomType = parts[0].trim();
    const quantityMatch = parts[1].match(/(\d+(?:\.\d+)?)/);
    quantity = quantityMatch ? parseFloat(quantityMatch[1]) : 1;
    details = parts[1].trim();
  } else {
    // Try to extract quantity and room type without colon
    const match = line.match(/^(\d+(?:\.\d+)?)\s*(.+)/);
    if (match) {
      quantity = parseFloat(match[1]);
      roomType = match[2].trim();
    } else {
      // If no quantity found, assume it's just a room type
      roomType = line;
      quantity = 1;
    }
    details = line;
  }

  return { roomType, quantity, details };
}

export function parseRoomsList(roomInput) {
  const lines = roomInput.split('\n');
  const parsedRooms = [];
  let currentCategory = '';
  let bathrooms = [];
  let totalBathrooms = 0;
  let petCount = 0;

  lines.forEach(line => {
    line = line.trim();
    if (line) {
      if (line.endsWith(':')) {
        currentCategory = line.slice(0, -1);
      } else {
        const { roomType, quantity, details } = extractRoomInfo(line);
        const normalizedRoomType = normalizeRoomType(roomType);

        if (['Master Bath', 'Full Bath', 'Half Bath'].includes(normalizedRoomType)) {
          bathrooms.push({ name: normalizedRoomType, quantity: quantity });
        } else if (normalizedRoomType === 'Bathroom') {
          totalBathrooms = quantity;
        } else if (normalizedRoomType === 'Pet Fee (Per Pet)') {
          petCount += quantity;
        } else {
          parsedRooms.push({
            category: currentCategory,
            name: normalizedRoomType,
            quantity: quantity,
            details: details
          });
        }
      }
    }
  });

  // Process bathrooms
  const processedBathrooms = processBathrooms(bathrooms.length > 0 ? bathrooms : totalBathrooms);
  parsedRooms.push(...processedBathrooms);

  // Add pet fee if pets are present
  if (petCount > 0) {
    parsedRooms.push({ name: 'Pet Fee (Per Pet)', quantity: petCount });
  }

  return parsedRooms;
}