// /src/components/EditRoomModal.js

import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

const modalStyle = (theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--light-foreground)',
  color: 'var(--light-text)',
  borderRadius: 'var(--border-radius-base)',
  boxShadow: 'var(--shadow-sm-light)',
  p: 4,
  '.dark-mode &': {
    bgcolor: 'var(--dark-foreground)',
    color: 'var(--dark-text)',
    boxShadow: 'var(--shadow-sm-dark)',
  },
});

const EditRoomModal = ({ open, handleClose, roomData, handleEditRoom }) => {
  const [roomName, setRoomName] = useState('');
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    if (roomData) {
      setRoomName(roomData.name || '');
      setTasks(roomData.tasks?.map(task => task.description) || []);
    }
  }, [roomData]);

  const handleTaskChange = (index, value) => {
    const updatedTasks = [...tasks];
    updatedTasks[index] = value;
    setTasks(updatedTasks);
  };

  const addTaskField = () => {
    setTasks([...tasks, '']);
  };

  const removeTaskField = (index) => {
    const updatedTasks = tasks.filter((_, idx) => idx !== index);
    setTasks(updatedTasks);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const cleanedTasks = tasks
      .filter(task => task.trim() !== '')
      .map(task => ({ description: task, completed: false }));
    
    handleEditRoom({
      id: roomData.id,
      name: roomName,
      tasks: cleanedTasks,
    });
    handleClose();
  };

  const textFieldSx = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'var(--light-background)',
      color: 'var(--light-text)',
      '& fieldset': {
        borderColor: 'var(--light-border)',
      },
      '&:hover fieldset': {
        borderColor: 'var(--light-accent)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--light-primary)',
      },
      '.dark-mode &': {
        backgroundColor: 'var(--dark-text-area)',
        color: 'var(--dark-text)',
        '& fieldset': {
          borderColor: 'var(--dark-border)',
        },
        '&:hover fieldset': {
          borderColor: 'var(--dark-accent)',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'var(--dark-primary)',
        },
      },
    },
    '& .MuiInputLabel-root': {
      color: 'var(--light-text)',
      '.dark-mode &': {
        color: 'var(--dark-text)',
      },
    },
  };

  const buttonSx = {
    '&.MuiButton-contained': {
      backgroundColor: 'var(--light-primary)',
      color: 'white',
      '&:hover': {
        backgroundColor: 'var(--light-accent)',
      },
      '.dark-mode &': {
        backgroundColor: 'var(--dark-primary)',
        '&:hover': {
          backgroundColor: 'var(--dark-accent)',
        },
      },
    },
    '&.MuiButton-outlined': {
      borderColor: 'var(--light-border)',
      color: 'var(--light-text)',
      '.dark-mode &': {
        borderColor: 'var(--dark-border)',
        color: 'var(--dark-text)',
      },
    },
    '&.MuiButton-text': {
      color: 'var(--light-text)',
      '.dark-mode &': {
        color: 'var(--dark-text)',
      },
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-room-modal-title"
      aria-describedby="edit-room-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography 
          id="edit-room-modal-title" 
          variant="h6" 
          component="h2" 
          gutterBottom
          sx={{
            color: 'var(--light-text)',
            '.dark-mode &': {
              color: 'var(--dark-text)',
            },
          }}
        >
          Edit Room Type
        </Typography>
        <form onSubmit={onSubmit}>
          <TextField
            label="Room Name"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
            fullWidth
            required
            margin="normal"
            sx={textFieldSx}
          />
          <Typography 
            variant="subtitle1" 
            gutterBottom
            sx={{
              color: 'var(--light-text)',
              '.dark-mode &': {
                color: 'var(--dark-text)',
              },
            }}
          >
            Tasks
          </Typography>
          {tasks.map((task, index) => (
            <Box key={index} display="flex" alignItems="center" mb={1}>
              <TextField
                label={`Task ${index + 1}`}
                value={task}
                onChange={(e) => handleTaskChange(index, e.target.value)}
                fullWidth
                required
                sx={textFieldSx}
              />
              <Button 
                onClick={() => removeTaskField(index)} 
                color="error" 
                sx={{ ml: 1, ...buttonSx }}
              >
                Remove
              </Button>
            </Box>
          ))}
          <Button 
            onClick={addTaskField} 
            variant="text" 
            sx={{ mt: 1, ...buttonSx }}
          >
            Add Task
          </Button>
          <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
            <Button 
              onClick={handleClose} 
              variant="outlined"
              sx={buttonSx}
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              sx={buttonSx}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default EditRoomModal;
