// /src/components/JobDetails.jsx

import React from 'react';
import { Typography, Box, List, ListItem, ListItemText, Chip } from '@mui/material';
import { Person as PersonIcon, LocationOn as LocationOnIcon } from '@mui/icons-material';
import styles from '../styles/JobsOverview.module.css';

const JobDetails = ({ job, formatRecurrenceRule }) => {
  return (
    <Box>
      <Typography><strong>Address:</strong> {job.address}</Typography>
      <Typography><strong>Appointment:</strong> {job.appointmentDate.toDate().toLocaleString()}</Typography>
      <Typography><strong>Cleaner:</strong> {job.cleanerName}</Typography>

      {/* Status Section */}
      <Box display="flex" alignItems="center" mt={1}>
        <Typography component="span"><strong>Status: </strong></Typography>
        <Chip
          label={job.status}
          color={job.status === 'pending' ? 'warning' : 'success'}
          size="small"
          className={styles.statusChip}
          sx={{ ml: 1 }}
        />
      </Box>

      {/* Recurring Information */}
      {job.recurring && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography><strong>Recurring Schedule:</strong></Typography>
          <Typography>
            {formatRecurrenceRule(job.recurrenceRule)}
          </Typography>
          {job.parentJobId && (
            <Typography variant="body2" color="textSecondary">
              Part of recurring series starting {job.appointmentDate.toDate().toLocaleDateString()}
            </Typography>
          )}
        </Box>
      )}

      {/* Rooms Section */}
      {job.rooms && job.rooms.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography><strong>Rooms:</strong></Typography>
          <List>
            {job.rooms.map((room, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`${room.name} (${room.quantity})`}
                  secondary={
                    <Box component="div">
                      {room.tasks?.map((task, taskIndex) => (
                        <Typography
                          key={taskIndex}
                          variant="body2"
                          component="div"
                          sx={{
                            textDecoration: task.completed ? 'line-through' : 'none',
                            color: task.completed ? 'text.secondary' : 'text.primary',
                          }}
                        >
                          • {task.description} - {task.completed ? 'Done' : 'Not Done'}
                        </Typography>
                      ))}
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Typography><strong>Notes:</strong> {job.notes}</Typography>
      <Typography><strong>Important Notes:</strong> {job.importantNotes}</Typography>

      {job.status === 'completed' && (
        <Box mt={2}>
          <Typography><strong>Start Time:</strong> {job.startTime?.toDate().toLocaleString()}</Typography>
          <Typography><strong>End Time:</strong> {job.endTime?.toDate().toLocaleString()}</Typography>
          <Typography>
            <strong>Duration:</strong>{' '}
            {job.duration
              ? `${Math.floor(job.duration / 3600000)}h ${Math.floor((job.duration % 3600000) / 60000)}m`
              : 'N/A'}
          </Typography>
          <Typography><strong>Cleaner Notes:</strong> {job.cleanerNotes || 'No notes provided'}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default JobDetails;
