// /src/components/AddCleanerModal.js

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const AddCleanerModal = ({ open, handleClose, handleAddCleaner }) => {
  const [cleanerName, setCleanerName] = useState('');
  const [cleanerEmail, setCleanerEmail] = useState('');
  const [cleanerPhone, setCleanerPhone] = useState('');

  const handleSubmit = () => {
    const newCleaner = {
      name: cleanerName,
      email: cleanerEmail,
      phone: cleanerPhone,
      createdAt: new Date()
    };
    handleAddCleaner(newCleaner);
    handleClose();
    // Reset form fields
    setCleanerName('');
    setCleanerEmail('');
    setCleanerPhone('');
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Cleaner</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Cleaner Name"
          type="text"
          fullWidth
          value={cleanerName}
          onChange={(e) => setCleanerName(e.target.value)}
          required
        />
        <TextField
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={cleanerEmail}
          onChange={(e) => setCleanerEmail(e.target.value)}
          required
        />
        <TextField
          margin="dense"
          label="Phone Number"
          type="tel"
          fullWidth
          value={cleanerPhone}
          onChange={(e) => setCleanerPhone(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={!cleanerName || !cleanerEmail || !cleanerPhone}>
          Add Cleaner
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCleanerModal;