import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { searchContacts } from '../utils/api';

const useFetchClients = (searchTerm) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const debouncedFetch = debounce(async (term) => {
      if (term.length < 3) return; // Minimize unnecessary API calls
      setLoading(true);
      try {
        const response = await searchContacts(term);
        // console.log("API Response:", response); // Debugging response
        setResults(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }, 500); // 500ms debounce time

    if (searchTerm) {
      debouncedFetch(searchTerm);
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [searchTerm]);

  return { results, loading, error };
};

export default useFetchClients;
