// src/components/FieldSelectionModal.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FieldSelectionModal = ({ availableFields, onClose, onSelect }) => {
  const [selectedFieldIds, setSelectedFieldIds] = useState([]);

  const handleToggleField = (fieldId) => {
    setSelectedFieldIds((prevSelected) =>
      prevSelected.includes(fieldId)
        ? prevSelected.filter((id) => id !== fieldId)
        : [...prevSelected, fieldId]
    );
  };

  const handleAddFields = () => {
    const selectedFields = availableFields.filter((field) =>
      selectedFieldIds.includes(field.id)
    );
    onSelect(selectedFields);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Select Fields to Add</h3>
        <ul className="field-list">
          {availableFields.map((field) => (
            <li key={field.id}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedFieldIds.includes(field.id)}
                  onChange={() => handleToggleField(field.id)}
                />
                {field.name}
              </label>
            </li>
          ))}
        </ul>
        <div className="modal-actions">
          <button onClick={handleAddFields} className="btn btn-primary">
            Add Fields
          </button>
          <button onClick={onClose} className="btn btn-secondary">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

FieldSelectionModal.propTypes = {
  availableFields: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default FieldSelectionModal;
