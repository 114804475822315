// src/components/TaskBoard/Pipeline.js

import React, { useState } from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@mui/material';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import Stage from './StagesTaskBoard';
import Card from './CardsTaskBoard';
import { prefixId } from '../utils/prefixIdTaskboard';
import styles from '../styles/TaskBoard.module.css'; // Importing CSS Module correctly

const Pipeline = React.memo(
  ({
    pipeline,
    index,
    addStage,
    editStage,
    deleteStage,
    addBucket,
    editBucket,
    deleteBucket,
    editCard,
    deleteCard,
    editPipeline,
    deletePipeline,
    handleBreakAway,
    addCard,
    cards,
  }) => {
    const [showAddStageDialog, setShowAddStageDialog] = useState(false);
    const [newStageName, setNewStageName] = useState('');
    const [newStageIsBreakAway, setNewStageIsBreakAway] = useState(false);
    const [showEditPipelineDialog, setShowEditPipelineDialog] = useState(false);
    const [editedPipelineName, setEditedPipelineName] = useState(pipeline.name);
    const [showAddCardDialog, setShowAddCardDialog] = useState(false);
    const [newCardClientName, setNewCardClientName] = useState('');
    const [newCardDescription, setNewCardDescription] = useState('');
    const [newCardPriority, setNewCardPriority] = useState('Medium');

    const handleAddStage = () => {
      if (newStageName) {
        addStage(pipeline.id, newStageName, newStageIsBreakAway);
        setNewStageName('');
        setNewStageIsBreakAway(false);
        setShowAddStageDialog(false);
      }
    };

    const handleEditPipeline = () => {
      if (editedPipelineName) {
        editPipeline(pipeline.id, editedPipelineName);
        setShowEditPipelineDialog(false);
      }
    };

    const handleAddCard = () => {
      if (newCardClientName) {
        addCard(pipeline.id, newCardClientName, newCardDescription, newCardPriority);
        setNewCardClientName('');
        setNewCardDescription('');
        setNewCardPriority('Medium');
        setShowAddCardDialog(false);
      }
    };

    return (
      <Draggable draggableId={prefixId('pipeline', pipeline.id)} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={styles.pipeline}
            aria-label={`Pipeline: ${pipeline.name}`}
          >
            <div
              className={styles.pipelineHeader}
              {...provided.dragHandleProps}
              aria-label={`Drag Handle for Pipeline: ${pipeline.name}`}
            >
              <h2>{pipeline.name}</h2>
              <div className={styles['pipeline-actions']}>
              <button
                onClick={() => setShowEditPipelineDialog(true)}
                className={styles['edit-button']}
                aria-label={`Edit Pipeline: ${pipeline.name}`}
              >
                <FaEdit />
              </button>
              <button
                onClick={() => deletePipeline(pipeline.id)}
                className={styles['delete-button']}
                aria-label={`Delete Pipeline: ${pipeline.name}`}
                >
                  <FaTrash />
                </button>
              </div>
            </div>

            <div className={styles.pipelineContent}>
              {/* Stages Section */}
              <div className={styles.stagesContainer}>
                <div className={styles.stagesHeader}>
                  <h3>Stages</h3>
                  {/* Replace with MUI Button for better theming */}
                  <Button
                    onClick={() => setShowAddStageDialog(true)}
                    variant="contained"
                    startIcon={<FaPlus />}
                    className={styles.stagesHeaderButton}
                    aria-label="Add Stage"
                  >
                  </Button>
                </div>
                <Droppable
                  droppableId={`pipeline___${pipeline.id}`}
                  direction="horizontal"
                  type="STAGE"
                >
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={styles.stages}
                      aria-label={`Stages in Pipeline: ${pipeline.name}`}
                    >
                      {pipeline.stages.map((stage, stageIndex) => (
                        <Stage
                          key={`stage___${stage.id}`}
                          stage={stage}
                          index={stageIndex}
                          pipelineId={pipeline.id}
                          editStage={editStage}
                          deleteStage={deleteStage}
                          addBucket={addBucket}
                          editBucket={editBucket}
                          deleteBucket={deleteBucket}
                          editCard={editCard}
                          deleteCard={deleteCard}
                          handleBreakAway={handleBreakAway}
                          cards={cards}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>

              {/* Available Cards Section */}
              <div className={styles.availableCardsContainer}>
                <div className={styles.availableCardsHeader}>
                  <h3>{pipeline.name} Available Cards</h3>
                  {/* Replace with MUI Button for better theming */}
                  <Button
                    onClick={() => setShowAddCardDialog(true)}
                    variant="contained"
                    startIcon={<FaPlus />}
                    className={styles.availableCardsHeaderButton}
                    aria-label="Add Card"
                  >
                  </Button>
                </div>
                <Droppable
                  droppableId={`available-cards___${pipeline.id}`}
                  type="CARD"
                >
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`${styles.availableCards} ${
                        snapshot.isDraggingOver ? styles.draggingOver : ''
                      }`}
                      aria-label={`Available Cards in Pipeline: ${pipeline.name}`}
                    >
                      {pipeline.availableCards.map((cardId, cardIndex) => {
                        const card = cards.find((c) => c.id === cardId);
                        return card ? (
                          <Card
                            key={`card___${card.id}`}
                            card={card}
                            index={cardIndex}
                            editCard={editCard}
                            deleteCard={deleteCard}
                          />
                        ) : null;
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>

            {/* Add Stage Dialog */}
            <Dialog
              open={showAddStageDialog}
              onClose={() => setShowAddStageDialog(false)}
              aria-labelledby="add-stage-dialog-title"
            >
              <DialogTitle id="add-stage-dialog-title">Add New Stage</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Stage Name"
                  type="text"
                  fullWidth
                  value={newStageName}
                  onChange={(e) => setNewStageName(e.target.value)}
                  aria-label="Stage Name"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newStageIsBreakAway}
                      onChange={(e) => setNewStageIsBreakAway(e.target.checked)}
                      name="isBreakAway"
                      color="primary"
                    />
                  }
                  label="Break Away Stage"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowAddStageDialog(false)}>Cancel</Button>
                <Button onClick={handleAddStage} disabled={!newStageName.trim()}>
                  Add
                </Button>
              </DialogActions>
            </Dialog>

            {/* Add Card Dialog */}
            <Dialog
              open={showAddCardDialog}
              onClose={() => setShowAddCardDialog(false)}
              aria-labelledby="add-card-dialog-title"
            >
              <DialogTitle id="add-card-dialog-title">Add New Card</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Client Name"
                  type="text"
                  fullWidth
                  value={newCardClientName}
                  onChange={(e) => setNewCardClientName(e.target.value)}
                  aria-label="Client Name"
                />
                <TextField
                  margin="dense"
                  label="Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  value={newCardDescription}
                  onChange={(e) => setNewCardDescription(e.target.value)}
                  aria-label="Card Description"
                />
                <Select
                  value={newCardPriority}
                  onChange={(e) => setNewCardPriority(e.target.value)}
                  fullWidth
                  margin="dense"
                  aria-label="Card Priority"
                >
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowAddCardDialog(false)}>Cancel</Button>
                <Button onClick={handleAddCard} disabled={!newCardClientName.trim()}>
                  Add
                </Button>
              </DialogActions>
            </Dialog>

            {/* Edit Pipeline Dialog */}
            <Dialog
              open={showEditPipelineDialog}
              onClose={() => setShowEditPipelineDialog(false)}
              aria-labelledby="edit-pipeline-dialog-title"
            >
              <DialogTitle id="edit-pipeline-dialog-title">Edit Pipeline</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Pipeline Name"
                  type="text"
                  fullWidth
                  value={editedPipelineName}
                  onChange={(e) => setEditedPipelineName(e.target.value)}
                  aria-label="Pipeline Name"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowEditPipelineDialog(false)}>Cancel</Button>
                <Button onClick={handleEditPipeline} disabled={!editedPipelineName.trim()}>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Draggable>
    );
  }
);

export default Pipeline;
