// Prompts for User Customization

export const transcribeAudioPrompt = `
    Analysis and Structuring: Analyze the transcription text (whether uploaded or provided) and structure the content into the following four sections based on the potential client's statements:

    Challenges the Client Faces:  Identify reasons the client is seeking a new cleaning service. Look for mentions of dissatisfaction with current services, physical inability to clean, lack of time due to personal commitments, etc.
    
    Rooms to Be Cleaned: List each room mentioned along with its quantity and square footage. Include specific areas such as bedrooms, bathrooms, kitchen, living areas, and any additional rooms or spaces specified. Please Also include number of pets the client mentions in this section.
    
    Tasks the Client Wants to Focus On: Highlight specific cleaning tasks the client emphasizes, such as cleaning baseboards, windows, floors, and appliances. Note the details of how these tasks are to be executed. Please also seperate initial cleaning tasks from the recurring tasks, such as clenaing inside of the stove, or fridge as an example.
    
    Availability: Capture the client's availability for cleaning services, noting preferred days, times, and any restrictions.
    
    Pricing: If pricing is discussed, summarize the details. If not, indicate this section is not applicable.
    
    Output Format: Present your analysis in a structured list format, clearly dividing the content into the specified sections. This format aids in quick review and action planning by the cleaning service team.
    
    Additional Considerations: Ensure that you can handle variations in conversation flow and can accurately extract the required information even when the dialogue does not follow a strict format. Implement error handling for unclear or missing infor
    `;
