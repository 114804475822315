import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaFileAlt, FaTasks, FaCog, FaPlus } from 'react-icons/fa';
import logo from '../logo.png';
import dmlogo from '../dmlogo.png';
import '../styles/global.css';

function Sidebar({ isDarkMode }) {
    const location = useLocation();
    const [activeTooltip, setActiveTooltip] = useState(null);

    const menuItems = [
        { 
            path: '/sales-tools/', 
            icon: FaFileAlt, 
            label: 'Client Sales Tools',
            subLinks: [
                { label: 'Client Sales Tools', path: '/sales-tools/' }
            ]
        },
        { 
            path: '/task-board', 
            icon: FaTasks, 
            label: 'Task Board',
            subLinks: [
                { label: 'Task Board', path: '/task-board/' }
            ]
        },
        { 
            path: '/create-job', 
            icon: FaPlus, 
            label: 'Create New Jobs',
            subLinks: [
                { label: 'Create New Job', path: '/create-job/' }
            ]
        },
        { 
            path: '/org-settings', 
            icon: FaCog, 
            label: 'Settings',
            subLinks: [
                { label: 'Organization Profile', path: '/org-settings/' }
            ]
        }
    ];

    return (
        <div className="sidebar">
            <img src={isDarkMode ? dmlogo : logo} alt="Logo" className="logo" />
            {menuItems.map(({ path, icon: Icon, label, subLinks }) => (
                <div 
                    key={path} 
                    className="tooltip-wrapper"
                    onMouseEnter={() => setActiveTooltip(path)}
                    onMouseLeave={() => setActiveTooltip(null)}
                >
                    <Link 
                        to={path} 
                        className={location.pathname === path ? 'active' : ''}
                    >
                        <Icon />
                    </Link>
                    {activeTooltip === path && (
                        <div className="tooltip">
                            <p className="tooltip-title">{label}</p>
                            <div className="tooltip-links">
                                {subLinks.map((link) => (
                                    <Link 
                                        key={link.path} 
                                        to={link.path}
                                        className="tooltip-link"
                                    >
                                        {link.label}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default Sidebar;