// ./src/components/MessageList.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useFetchConversations from '../hooks/useFetchConversations';
import usePipelineStages from '../hooks/usePipelineStages';
import useOpportunityDetails from '../hooks/useOpportunityDetails';
import { getCallRecordingByMessageId } from '../utils/api';

const MessageList = ({ selectedClientId, transcribeAudio }) => {
  const { messages, loading, error } = useFetchConversations(selectedClientId);
  const [callRecordings, setCallRecordings] = useState({});
  const [callRecordingLoading, setCallRecordingLoading] = useState({});
  const [callRecordingError, setCallRecordingError] = useState({});

  // Fetch pipeline stages
  const { stages, loading: stagesLoading, error: stagesError } = usePipelineStages();

  // Extract contact IDs from messages to fetch opportunity details
  const contactIds = messages
    .filter((message) => message.messageType === 'TYPE_ACTIVITY_OPPORTUNITY')
    .map((message) => message.contactId);
  
  const uniqueContactIds = [...new Set(contactIds)];

  // Fetch opportunity details using contact IDs and pipeline stages
  const { opportunityDetails } = useOpportunityDetails(uniqueContactIds, stages);

  // Handle call recording fetching
  const fetchCallRecording = async (messageId, locationId) => {
    if (!callRecordings.hasOwnProperty(messageId) && !callRecordingLoading[messageId]) {
      setCallRecordingLoading((prev) => ({ ...prev, [messageId]: true }));
      try {
        const recordingUrl = await getCallRecordingByMessageId(messageId, locationId);
        setCallRecordings((prev) => ({ ...prev, [messageId]: recordingUrl }));
      } catch (error) {
        console.error('Error fetching call recording:', error);
        setCallRecordingError((prev) => ({ ...prev, [messageId]: error }));
      } finally {
        setCallRecordingLoading((prev) => ({ ...prev, [messageId]: false }));
      }
    }
  };

  // Handle transcribe button click
  const handleTranscribeClick = async (messageId, locationId) => {
    try {
      console.log('Starting transcription for messageId:', messageId);
      let recordingUrl = callRecordings[messageId];

      if (!recordingUrl) {
        // Fetch the recording if not already fetched
        await fetchCallRecording(messageId, locationId);
        recordingUrl = callRecordings[messageId];
      }

      if (recordingUrl) {
        const response = await fetch(recordingUrl);
        const audioBlob = await response.blob();
        await transcribeAudio(audioBlob);
      } else {
        console.error('Recording URL not available');
      }
    } catch (error) {
      console.error('Error transcribing call:', error);
    }
  };

  if (loading || stagesLoading) return <div className="loading-message">Loading conversations...</div>;
  if (error || stagesError) return <div className="error-message">Error loading conversations or stages</div>;
  if (messages.length === 0) return <div className="no-messages-message">No conversations found</div>;

  return (
    <div className="message-list-container">
      <ul className="message-list">
        {messages.map((message) => {
          const opportunity = opportunityDetails[message.contactId];
          const recordingUrl = callRecordings[message.id];
          const recordingLoading = callRecordingLoading[message.id];
          const recordingError = callRecordingError[message.id];

          return (
            <li key={message.id} className="message-list-item">
              <div
                className={`message-bubble ${
                  message.direction === 'inbound' ? 'message-bubble-contact' : 'message-bubble-user'
                }`}
              >
                <div className="message-sender">
                  {message.direction === 'inbound' ? 'Contact' : 'User'}
                </div>
                <div className="message-content">{message.body}</div>

                {message.messageType === 'TYPE_CALL' && (
                  <div className="message-recording">
                    {recordingUrl ? (
                      <>
                        <div className="compact-audio-player">
                          <audio controls src={recordingUrl} />
                        </div>
                        <button
                          className="transcribe-button"
                          onClick={() => handleTranscribeClick(message.id, message.locationId)}
                        >
                          Transcribe
                        </button>
                      </>
                    ) : recordingLoading ? (
                      <div>Loading recording...</div>
                    ) : recordingError ? (
                      <div>Recording not available.</div>
                    ) : (
                      <button
                        className="fetch-recording-button"
                        onClick={() => fetchCallRecording(message.id, message.locationId)}
                      >
                        Fetch Recording
                      </button>
                    )}
                  </div>
                )}

                {message.messageType === 'TYPE_ACTIVITY_OPPORTUNITY' && opportunity && (
                  <div className="message-opportunity">
                    <div>
                      <strong>Stage:</strong> {opportunity.stageName || 'N/A'}
                    </div>
                    <div>
                      <strong>Status:</strong> {opportunity.status || 'N/A'}
                    </div>
                  </div>
                )}
              </div>
              <div className="message-timestamp">
                {new Date(message.dateAdded).toLocaleString()}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

MessageList.propTypes = {
  selectedClientId: PropTypes.string.isRequired,
  transcribeAudio: PropTypes.func.isRequired,
};

export default MessageList;
