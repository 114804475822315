// File: src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import clientReducer from './reducers/clientReducer';
import quoteReducer from './reducers/quoteReducer';

const store = configureStore({
  reducer: {
    client: clientReducer,
    quote: quoteReducer
  },
});

export default store;