// /src/components/JobDialog.js

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Typography } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  DeleteForever as DeleteForeverIcon,
  EventRepeat,
  Add as AddIcon,
} from '@mui/icons-material';
import JobDetails from './JobDetails';
import JobEditForm from './JobEditForm';
import RecurringInstancesList from './RecurringInstancesList';
import styles from '../styles/JobsOverview.module.css';

const JobDialog = ({
  open,
  onClose,
  job,
  isEditing,
  handleEditClick,
  handleDeleteJob,
  handleMarkCompleted,
  handleDeleteRecurringJob,
  fetchRecurringInstances,
  showingRecurringSeries,
  recurringInstances,
  generateMoreInstances,
  handleSaveEdit,
  handleCancelEdit,
  editedJob,
  handleEditInputChange,
  handleAddRooms,
  handleDeleteRoom,
  openAddRoomsModal,
  setOpenAddRoomsModal,
  availableRooms,
  formatRecurrenceRule,
}) => {

  const renderDialogActions = () => (
    <DialogActions className={styles.dialogActions}>
      {isEditing ? (
        <>
          <IconButton
            onClick={handleSaveEdit}
            className={styles.iconButton}
            title="Save changes"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={handleCancelEdit}
            className={styles.iconButton}
            title="Cancel editing"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton onClick={handleEditClick} className={styles.iconButton} title="Edit job">
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleDeleteJob} className={styles.iconButton} title="Delete job">
            <DeleteIcon />
          </IconButton>
          {job?.status === 'pending' && (
            <IconButton
              onClick={handleMarkCompleted}
              className={styles.iconButton}
              title="Mark as completed"
            >
              <CheckIcon />
            </IconButton>
          )}
          {job?.recurring && job?.status === 'pending' && (
            <IconButton
              onClick={() => handleDeleteRecurringJob(job.recurrenceGroupId)}
              className={styles.iconButton}
              title="Delete all recurring instances"
            >
              <DeleteForeverIcon />
            </IconButton>
          )}
          {job?.recurring && (
            <IconButton
              onClick={() => fetchRecurringInstances(job.recurrenceGroupId)}
              className={styles.iconButton}
              title="View recurring series"
            >
              <EventRepeat />
            </IconButton>
          )}
        </>
      )}
      <Button
        onClick={onClose}
        className={styles.closeButton}
        variant="contained"
        color="primary"
      >
        Close
      </Button>
    </DialogActions>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth className={styles.dialog}>
      {job && (
        <>
          <DialogTitle className={styles.dialogTitle}>
            {isEditing ? 'Edit Job' : job.customerName}
          </DialogTitle>
          <DialogContent className={styles.dialogContent}>
            {isEditing ? (
              <JobEditForm
                job={editedJob}
                handleEditInputChange={handleEditInputChange}
                handleAddRooms={handleAddRooms}
                handleDeleteRoom={handleDeleteRoom}
                openAddRoomsModal={openAddRoomsModal}
                setOpenAddRoomsModal={setOpenAddRoomsModal}
                availableRooms={availableRooms}
              />
            ) : (
              <>
                <JobDetails job={job} formatRecurrenceRule={formatRecurrenceRule} />
                <Typography className={styles.jobLink}>
                  <strong>Job Link:</strong>{' '}
                  {`${window.location.origin}/org/${job.organizationId}/job/${job.id}`}
                </Typography>
                {showingRecurringSeries && (
                  <>
                    <RecurringInstancesList instances={recurringInstances} />
                    <Button
                      onClick={() => generateMoreInstances(job)}
                      startIcon={<AddIcon />}
                      variant="outlined"
                      sx={{ mt: 2 }}
                    >
                      Generate More Instances
                    </Button>
                  </>
                )}
              </>
            )}
          </DialogContent>
          {renderDialogActions()}
        </>
      )}
    </Dialog>
  );
};

export default JobDialog;
