// File: src/store/reducers/clientReducer.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customerProfiles: {},
  customFields: {},
  isLoading: false,
  error: null,
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    setCustomer(state, action) {
      const { clientId, profileData } = action.payload;
      state.customerProfiles[clientId] = profileData;
      state.isLoading = false;
    },
    setCustomFields(state, action) {
      const { clientId, customFields } = action.payload;
      state.customFields[clientId] = customFields;
      state.isLoading = false;
    },
    setError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { startLoading, setCustomer, setCustomFields, setError } = clientSlice.actions;
export default clientSlice.reducer;
