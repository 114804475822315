// src/store/reducers/quoteReducer.js

const initialState = {
    pricing: [
      { name: 'Bedrooms', price: 10 },
      { name: 'Master Bath', price: 25 },
      { name: 'Full Bath', price: 20 },
      { name: 'Half Bath', price: 15 },
      { name: 'Kitchen', price: 25 },
      { name: 'Living Room', price: 10 },
      { name: 'Dining Room', price: 10 },
      { name: 'Office', price: 10 },
      { name: 'Playroom', price: 10 },
      { name: 'Foyers/Halls', price: 10 },
      { name: 'Basement', price: 15 },
      { name: 'Stairs', price: 5 },
      { name: 'Square Foot', price: 0.01 },
      { name: 'Closet/Utility Room/Pantry', price: 5 },
      { name: 'Entry Way', price: 5 },
      { name: 'Florida Room', price: 10 },
      { name: 'In Home Salon', price: 10 },
      { name: 'Laundry Room', price: 10 },
      { name: 'Loft', price: 10 },
      { name: 'Mini Bars/Kitchens', price: 15 },
      { name: 'Monthly Fee', price: 50 },
      { name: 'Morning Room', price: 10 },
      { name: 'Mud Room', price: 10 },
      { name: 'Pet Fee (Per Pet)', price: 10 },
      { name: 'Sitting Room', price: 10 },
      { name: 'Workout Room', price: 10 },
      { name: 'Additional Rooms', price: 10 },
      { name: 'Gas Mileage for 25+ (Add Total Miles)', price: 1.00 },
      { name: 'Gas Mileage 2 People (Add Total Miles)', price: 1.25 },
    ],
    multipliers: [
      { name: 'Standard', value: 1 },
      { name: 'Bi-Monthly', value: 1.5 },
    { name: 'Quareterly', value: 2 },
    ],
    selectedMultiplier: 1,
  };
  
  const quoteReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_QUOTE_PRICING':
        return {
          ...state,
          pricing: action.payload,
        };
      case 'UPDATE_SELECTED_MULTIPLIER':
        return {
          ...state,
          selectedMultiplier: action.payload,
        };
      case 'ADD_ROOM':
        return {
          ...state,
          pricing: [...state.pricing, action.payload],
        };
      case 'REMOVE_ROOM':
        return {
          ...state,
          pricing: state.pricing.filter((room, index) => index !== action.payload),
        };
        case 'UPDATE_SELECTED_FREQUENCY':
          return {
            ...state,
            selectedFrequency: action.payload,
          };
      default:
        return state;
    }
  };
  
  export default quoteReducer;