import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  Chip,
  Divider
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  Today as TodayIcon
} from '@mui/icons-material';
import moment from 'moment';

const WeeklyJobsView = ({ jobs, onJobClick }) => {
  const [currentDate, setCurrentDate] = useState(moment());

  // Navigation functions
  const nextWeek = () => setCurrentDate(moment(currentDate).add(1, 'week'));
  const prevWeek = () => setCurrentDate(moment(currentDate).subtract(1, 'week'));
  const today = () => setCurrentDate(moment());

  // Get week days
  const weekDays = [];
  const startOfWeek = moment(currentDate).startOf('week');
  for (let i = 0; i < 7; i++) {
    weekDays.push(moment(startOfWeek).add(i, 'days'));
  }

  // Group jobs by date
  const getJobsForDay = (date) => {
    return jobs.filter(job => 
      moment(job.appointmentDate.toDate()).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
    ).sort((a, b) => 
      moment(a.appointmentDate.toDate()).diff(moment(b.appointmentDate.toDate()))
    );
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Paper sx={{ p: 2, mb: 2 }}>
        {/* Header with navigation */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h5">
            Week of {currentDate.format('MMM D, YYYY')}
          </Typography>
          <Box>
            <IconButton onClick={prevWeek}>
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={today}>
              <TodayIcon />
            </IconButton>
            <IconButton onClick={nextWeek}>
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>

        {/* Week grid */}
        <Grid container spacing={2}>
          {weekDays.map((day) => (
            <Grid item xs={12} sm={6} md={3} lg={12/7} key={day.format('YYYY-MM-DD')}>
              <Paper 
                elevation={2} 
                sx={{
                  height: '100%',
                  bgcolor: moment().isSame(day, 'day') ? 'action.hover' : 'background.paper'
                }}
              >
                {/* Day header */}
                <Box sx={{ p: 1, bgcolor: 'primary.main', color: 'primary.contrastText' }}>
                  <Typography variant="subtitle2" align="center">
                    {day.format('ddd')}
                  </Typography>
                  <Typography variant="h6" align="center">
                    {day.format('MMM D')}
                  </Typography>
                </Box>

                {/* Jobs list */}
                <Box sx={{ maxHeight: 400, overflow: 'auto', p: 1 }}>
                  {getJobsForDay(day).map((job) => (
                    <Card 
                      key={job.id}
                      sx={{ 
                        mb: 1, 
                        cursor: 'pointer',
                        '&:hover': { bgcolor: 'action.hover' },
                        bgcolor: job.recurring ? 'primary.lighter' : 'background.paper'
                      }}
                      onClick={() => onJobClick(job)}
                    >
                      <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {moment(job.appointmentDate.toDate()).format('h:mm A')}
                        </Typography>
                        <Typography variant="body2" noWrap>
                          {job.customerName}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" noWrap>
                          {job.cleanerName}
                        </Typography>
                        <Box sx={{ mt: 0.5, display: 'flex', gap: 0.5 }}>
                          {job.recurring && (
                            <Chip 
                              label="Recurring" 
                              size="small" 
                              color="primary" 
                              sx={{ height: 20 }}
                            />
                          )}
                          <Chip 
                            label={job.status} 
                            size="small"
                            color={job.status === 'pending' ? 'warning' : 'success'}
                            sx={{ height: 20 }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default WeeklyJobsView;