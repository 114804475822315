// src/components/CreateJobForm.js

import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { Plus, Edit2, Trash2 } from 'lucide-react';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import CreateRoomModal from './CreateRoomModal';
import EditRoomModal from './EditRoomModal';
import AddRoomsModal from './AddRoomsModal';
import CreateCustomerModal from './CreateCustomerModal';
import AddCleanerModal from './AddCleanerModal';
import JobsOverview from './JobsOverview';
import CustomerManagement from './CustomerManagement';
import UploadRoomsTasksCSV from './UploadRoomsTasksCSV';
import RecurrenceSelect from './RecurrenceSelect';
import styles from '../styles/CreateJobForm.module.css';

const CreateJobForm = () => {
  // Core state
  const [activeTab, setActiveTab] = useState('create-job');
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [address, setAddress] = useState('');
  const [notes, setNotes] = useState('');
  const [importantNotes, setImportantNotes] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const [user, setUser] = useState(null);
  const [orgId, setOrgId] = useState(null);

  // Room management state
  const [availableRooms, setAvailableRooms] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [openCreateRoomModal, setOpenCreateRoomModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingRoom, setEditingRoom] = useState(null);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);
  const [roomAccordionOpen, setRoomAccordionOpen] = useState({});

  // Cleaner management state
  const [cleaners, setCleaners] = useState([]);
  const [selectedCleaner, setSelectedCleaner] = useState(null);
  const [openAddCleanerModal, setOpenAddCleanerModal] = useState(false);
  
  // Customer management state
  const [openCreateCustomerModal, setOpenCreateCustomerModal] = useState(false);
  
  // Form state
  const [appointmentDate, setAppointmentDate] = useState('');
  const [accordionOpen, setAccordionOpen] = useState({});
  const [recurring, setRecurring] = useState(false);
  const [recurrenceRule, setRecurrenceRule] = useState('');
  
  // Autocomplete state
  const [customerInput, setCustomerInput] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
  const [cleanerInput, setCleanerInput] = useState('');
  const [filteredCleaners, setFilteredCleaners] = useState([]);
  const [showCleanerDropdown, setShowCleanerDropdown] = useState(false);

  // Authentication effect
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserOrgId(currentUser.uid);
      } else {
        setLoading(false);
        setError('User not authenticated');
      }
    });

    return () => unsubscribe();
  }, []);

  // Data fetching functions
  const fetchUserOrgId = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
      const userOrgId = userDoc.data().orgId;
      setOrgId(userOrgId);
      fetchData(userOrgId);
    } catch (err) {
      console.error("Error fetching user's organization ID:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  const generateRecurringDates = (startDate, recurrenceRule, count = 52) => { // Default to 1 year of weekly occurrences
    if (!recurrenceRule) return [startDate];
    
    const rruleString = `DTSTART:${startDate.toISOString().replace(/[-:]/g, '').split('.')[0]}Z\nRRULE:${recurrenceRule}`;
    const rule = RRule.fromString(rruleString);
    
    return rule.all((date, i) => i < count); // Limit to specified number of occurrences
  };

  const fetchData = async (organizationId) => {
    setLoading(true);
    setError(null);
    try {
      console.log("Fetching data for organization:", organizationId);
  
      // Fetch customers
      const customersRef = collection(db, 'organizations', organizationId, 'customers');
      const customersSnapshot = await getDocs(customersRef);
      const customersList = customersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomers(customersList);
      console.log("Customers fetched:", customersList.length);
  
      // Fetch room types
      const roomTypesRef = collection(db, 'organizations', organizationId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomTypesList = roomTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAvailableRooms(roomTypesList);
      console.log("Room types fetched:", roomTypesList.length);
  
      // Fetch cleaners from users collection instead
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const cleanersList = usersSnapshot.docs
        .map(doc => ({ 
          id: doc.id,  // Make sure we're getting the id
          ...doc.data() 
        }))
        .filter(user => 
          user.orgId === organizationId && 
          user.role === 'cleaner' &&
          user.isActive !== false
        );
      setCleaners(cleanersList);
      console.log("Cleaners fetched:", cleanersList.length);
  
    } catch (err) {
      console.error("Error in fetchData:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Event handlers
  const handleCustomerInputChange = (e) => {
    const value = e.target.value;
    setCustomerInput(value);
    if (value.length > 1) {
      const filtered = customers.filter(customer =>
        customer.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCustomers(filtered);
      setShowCustomerDropdown(true);
    } else {
      setShowCustomerDropdown(false);
    }
  };

  const handleCleanerInputChange = (e) => {
    const value = e.target.value;
    setCleanerInput(value);
    if (value.length > 1) {
      const filtered = cleaners.filter(cleaner => {
        const name = cleaner.firstName && cleaner.lastName
          ? `${cleaner.firstName} ${cleaner.lastName}`
          : cleaner.email;
        return name.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredCleaners(filtered);
      setShowCleanerDropdown(true);
    } else {
      setShowCleanerDropdown(false);
    }
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setCustomerInput(customer.name);
    setAddress(customer.address || '');
    setShowCustomerDropdown(false);
  };

  const handleCleanerSelect = (cleaner) => {
    setSelectedCleaner(cleaner);
    const name = cleaner.firstName && cleaner.lastName
      ? `${cleaner.firstName} ${cleaner.lastName}`
      : cleaner.email;
    setCleanerInput(name);
    setShowCleanerDropdown(false);
  };

  const toggleAccordion = (index) => {
    setAccordionOpen(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const toggleRoomAccordion = (roomId) => {
    setRoomAccordionOpen(prev => ({
      ...prev,
      [roomId]: !prev[roomId]
    }));
  };


// Task management
const handleTaskChange = (roomIndex, taskIndex, completed) => {
  setSelectedRooms(prevRooms => {
    const updatedRooms = [...prevRooms];
    if (updatedRooms[roomIndex] && updatedRooms[roomIndex].tasks) {
      updatedRooms[roomIndex].tasks[taskIndex].completed = completed;
    }
    return updatedRooms;
  });
};

// Room management
const handleCreateRoom = async (newRoom) => {
  try {
    if (!orgId) throw new Error("Organization ID not found");
    
    const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
    const docRef = await addDoc(roomTypesRef, newRoom);
    await fetchData(orgId);
    setSuccess("Room created successfully!");
    setOpenCreateRoomModal(false);
  } catch (error) {
    setError("Failed to create new room type");
  }
};

const handleEditRoomClick = (room) => {
  setEditingRoom(room);
  setIsEditModalOpen(true);
};

const handleCloseEditModal = () => {
  setIsEditModalOpen(false);
  setEditingRoom(null);
};

const handleEditRoom = async (updatedRoom) => {
  try {
    if (!orgId) throw new Error("Organization ID not found");
    
    const roomDocRef = doc(db, 'organizations', orgId, 'roomTypes', updatedRoom.id);
    await updateDoc(roomDocRef, {
      name: updatedRoom.name,
      tasks: updatedRoom.tasks,
      updatedAt: new Date()
    });
    await fetchData(orgId);
    setSuccess("Room updated successfully!");
    handleCloseEditModal();
  } catch (error) {
    setError("Failed to edit room type");
  }
};

const handleDeleteRoom = async (roomId) => {
  const confirmDelete = window.confirm("Are you sure you want to delete this room?");
  if (!confirmDelete) return;

  try {
    if (!orgId) throw new Error("Organization ID not found");
    
    const roomDocRef = doc(db, 'organizations', orgId, 'roomTypes', roomId);
    await deleteDoc(roomDocRef);
    await fetchData(orgId);
    setSuccess("Room deleted successfully!");
  } catch (error) {
    setError("Failed to delete room type");
  }
};


  // Handle adding rooms from AddRoomsModal
  const handleAddRooms = (roomsToAdd) => {
    setSelectedRooms(prevRooms => {
      const updatedRooms = [...prevRooms];
      roomsToAdd.forEach(newRoom => {
        // Ensure tasks array exists and is properly formatted
        const formattedTasks = (newRoom.tasks || []).map(task => {
          if (typeof task === 'string') {
            return { description: task, completed: false };
          }
          // If it's already an object, ensure it has all required properties
          return {
            description: task.description || '',
            completed: task.completed || false,
            ...task // preserve any other properties
          };
        });
  
        const roomToAdd = {
          ...newRoom,
          tasks: formattedTasks,
          quantity: newRoom.quantity || 1
        };
        
        // Find existing room
        const existingIndex = updatedRooms.findIndex(room => room.name === newRoom.name);
        if (existingIndex !== -1) {
          // Merge existing room with new room data
          const existingRoom = updatedRooms[existingIndex];
          updatedRooms[existingIndex] = {
            ...existingRoom,
            ...roomToAdd,
            // Preserve existing tasks if new room has no tasks
            tasks: roomToAdd.tasks.length > 0 ? roomToAdd.tasks : existingRoom.tasks,
            // Preserve existing quantity if new room has none
            quantity: roomToAdd.quantity || existingRoom.quantity || 1
          };
        } else {
          updatedRooms.push(roomToAdd);
        }
      });
      
      return updatedRooms;
    });
  };

// Customer management
const handleCreateCustomer = async (newCustomer) => {
  try {
    if (!orgId) throw new Error("Organization ID not found");
    
    const customersRef = collection(db, 'organizations', orgId, 'customers');
    await addDoc(customersRef, newCustomer);
    await fetchData(orgId);
    setSuccess("Customer created successfully!");
    setOpenCreateCustomerModal(false);
  } catch (error) {
    setError("Failed to create new customer");
  }
};

// Cleaner management
const handleAddCleaner = async (newCleaner) => {
  try {
    if (!orgId) throw new Error("Organization ID not found");
    
    const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
    await addDoc(cleanersRef, newCleaner);
    await fetchData(orgId);
    setSuccess("Cleaner added successfully!");
    setOpenAddCleanerModal(false);
  } catch (error) {
    setError("Failed to add new cleaner");
  }
};



// CSV handling
const handleCSVUpload = (roomsFromCSV) => {
  setSelectedRooms(prevRooms => [...prevRooms, ...roomsFromCSV]);
  setSuccess("Rooms imported from CSV successfully!");
};


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!selectedCustomer) throw new Error("Please select a customer");
      if (!selectedCleaner) throw new Error("Please assign a cleaner");
      if (!appointmentDate) throw new Error("Please set an appointment date");
      if (!orgId) throw new Error("Organization ID not found");
  
      // Generate a unique ID for this recurring group
      const recurrenceGroupId = recurring ? new Date().getTime().toString() : null;
  
      // Base job data
      const baseJobData = {
        customerId: selectedCustomer.id,
        customerName: selectedCustomer.name,
        address,
        rooms: selectedRooms,
        notes,
        importantNotes,
        status: 'pending',
        createdAt: new Date(),
        updatedAt: new Date(),
        organizationId: orgId,
        cleanerId: selectedCleaner.id,
        cleanerName: selectedCleaner.firstName && selectedCleaner.lastName 
          ? `${selectedCleaner.firstName} ${selectedCleaner.lastName}`
          : selectedCleaner.email,
        cleanerEmail: selectedCleaner.email,
        recurring,
        recurrenceRule: recurring ? recurrenceRule : null,
        recurrenceGroupId
      };
  
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
  
      if (recurring && recurrenceRule) {
        // Parse the appointment date
        const startDate = new Date(appointmentDate);
        
        // Create RRule instance
        const rruleString = `DTSTART:${startDate.toISOString().replace(/[-:]/g, '').split('.')[0]}Z\nRRULE:${recurrenceRule}`;
        const rule = rrulestr(rruleString);
  
        // Generate dates for the next 6 months (or adjust as needed)
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 6);
        
        const dates = rule.between(startDate, endDate);
  
        // Create a job for each date
        const jobPromises = dates.map(date => {
          const jobData = {
            ...baseJobData,
            appointmentDate: date
          };
          return addDoc(jobsRef, jobData);
        });
  
        await Promise.all(jobPromises);
        setSuccess(`Created ${dates.length} recurring jobs successfully!`);
  
      } else {
        // Non-recurring job - just create one instance
        const jobData = {
          ...baseJobData,
          appointmentDate: new Date(appointmentDate)
        };
        await addDoc(jobsRef, jobData);
        setSuccess('Job created successfully!');
      }
  
      // Reset form
      setSelectedCustomer(null);
      setCustomerInput('');
      setAddress('');
      setSelectedRooms([]);
      setNotes('');
      setImportantNotes('');
      setSelectedCleaner(null);
      setCleanerInput('');
      setAppointmentDate('');
      setRecurring(false);
      setRecurrenceRule('');
      
    } catch (error) {
      setError(error.message);
    }
  };

  // Loading and error states
  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>{error}</div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className={styles.errorContainer}>
        Please sign in to create a job.
      </div>
    );
  }

  return (
    <div className={styles.formContainer}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${activeTab === 'create-job' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('create-job')}
        >
          Create Job
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'jobs-overview' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('jobs-overview')}
        >
          Jobs Overview
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'rooms-tasks' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('rooms-tasks')}
        >
          Rooms/Tasks
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'clients' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('clients')}
        >
          Clients
        </button>
      </div>

      {activeTab === 'create-job' && (
        <div className={styles.formContent}>
          <div className={styles.formHeader}>
            <h2 className={styles.formTitle}>Create New Job</h2>
            <button
              type="button"
              onClick={() => setOpenCreateCustomerModal(true)}
              className={styles.button}
            >
              Create Customer
            </button>
          </div>
          
          <form onSubmit={handleSubmit} className={styles.form}>
            {/* Customer Selection */}
            <div className={styles.formSection}>
              <div className={styles.inputGroup}>
                <div className={styles.autocompleteContainer}>
                  <label className={styles.label}>Customer</label>
                  <input
                    type="text"
                    placeholder="Search For A Customer"
                    value={customerInput}
                    onChange={handleCustomerInputChange}
                    className={styles.input}
                    required
                  />
                  {showCustomerDropdown && (
                    <div className={styles.dropdown}>
                      {filteredCustomers.map(customer => (
                        <div
                          key={customer.id}
                          className={styles.dropdownItem}
                          onClick={() => handleCustomerSelect(customer)}
                        >
                          {customer.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Address */}
            <div className={styles.formSection}>
              <label className={styles.label}>Address</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={styles.input}
                required
              />
            </div>

            {/* Cleaner Selection */}
            <div className={styles.formSection}>
                  <label className={styles.label}>Assign Cleaner</label>
                  <select
                    value={selectedCleaner?.id || ''}
                    onChange={(e) => {
                      const cleaner = cleaners.find(c => c.id === e.target.value);
                      setSelectedCleaner(cleaner);
                    }}
                    className={styles.select}
                    required
                  >
                    <option value="">Select a cleaner</option>
                    {cleaners.map(cleaner => (
                      <option key={cleaner.id} value={cleaner.id}>
                        {cleaner.firstName && cleaner.lastName
                          ? `${cleaner.firstName} ${cleaner.lastName}`
                          : cleaner.email}
                      </option>
                    ))}
                  </select>
            </div>

            {/* Appointment Date */}
            <div className={styles.formSection}>
              <label className={styles.label}>Appointment Date and Time</label>
              <input
                type="datetime-local"
                value={appointmentDate}
                onChange={(e) => setAppointmentDate(e.target.value)}
                className={styles.input}
                required
              />
            </div>

            {/* Recurrence Rule */}
            <div className={styles.formGroup}>
            <label>
              <input
                type="checkbox"
                checked={recurring}
                onChange={(e) => setRecurring(e.target.checked)}
              />
              Recurring Event
            </label>
            {recurring && (
              <RecurrenceSelect
                value={recurrenceRule}
                onChange={setRecurrenceRule}
              />
            )}
          </div>

            {/* Rooms Section */}
            <div className={styles.formSection}>
              <div className={styles.sectionHeader}>
                <h3 className={styles.sectionTitle}>Rooms</h3>
                <button
                  type="button"
                  onClick={() => setOpenAddRoomsModal(true)}
                  className={styles.button}
                >
                  <Plus size={16} /> Add Rooms
                </button>
              </div>

              <div className={styles.roomsList}>
                {selectedRooms.map((room, index) => (
                  <div key={index} className={styles.roomCard}>
                    <div
                      className={styles.roomHeader}
                      onClick={() => toggleAccordion(index)}
                    >
                      <h4 className={styles.roomName}>{room.name}</h4>
                      <span>{accordionOpen[index] ? '-' : '+'}</span>
                    </div>
                    {accordionOpen[index] && (
                      <div className={styles.tasksList}>
                        {room.tasks?.map((task, taskIndex) => (
                          <div key={taskIndex} className={styles.taskItem}>
                            <label className={styles.checkboxLabel}>
                            <input
                                type="checkbox"
                                checked={task.completed || false}
                                onChange={(e) => handleTaskChange(index, taskIndex, e.target.checked)}
                                className={styles.checkbox}
                              />
                              <span>{task.description}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Notes Fields */}
            <div className={styles.formSection}>
              <label className={styles.label}>Notes</label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className={styles.textarea}
                rows={3}
              />
            </div>

            <div className={styles.formSection}>
              <label className={styles.label}>Important Notes</label>
              <textarea
                value={importantNotes}
                onChange={(e) => setImportantNotes(e.target.value)}
                className={styles.textarea}
                rows={3}
              />
            </div>

            {success && (
              <div className={styles.successMessage}>{success}</div>
            )}

            <button type="submit" className={styles.submitButton}>
              Create Job
            </button>
          </form>
        </div>
      )}

      {activeTab === 'jobs-overview' && (
        <div className={styles.formContent}>
          <JobsOverview orgId={orgId} />
        </div>
      )}

      {activeTab === 'rooms-tasks' && (
        <div className={styles.formContent}>
          <h2 className={styles.formTitle}>Rooms and Tasks Management</h2>
          
          <UploadRoomsTasksCSV orgId={orgId} onRoomsCreated={handleCSVUpload} />
          
          <button
            onClick={() => setOpenCreateRoomModal(true)}
            className={styles.button}
          >
            Create New Room Type
          </button>

          <div className={styles.roomsList}>
            {availableRooms.map((room) => (
              <div key={room.id} className={styles.roomCard}>
                <div className={styles.roomHeader}>
                  <h4 className={styles.roomName}>{room.name}</h4>
                  <div className={styles.roomActions}>
                    <button
                      onClick={() => handleEditRoomClick(room)}
                      className={styles.iconButton}
                    >
                      <Edit2 size={16} />
                    </button>
                    <button
                      onClick={() => handleDeleteRoom(room.id)}
                      className={styles.iconButton}
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </div>
                <div className={styles.tasksList}>
                  {room.tasks?.map((task, index) => (
                    <div key={index} className={styles.taskItem}>
                      {typeof task === 'object' ? task.description : task}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {activeTab === 'clients' && (
        <div className={styles.formContent}>
          <CustomerManagement orgId={orgId} />
        </div>
      )}

      {/* Modals */}
      {openCreateRoomModal && (
        <CreateRoomModal
          open={openCreateRoomModal}
          handleClose={() => setOpenCreateRoomModal(false)}
          handleCreateRoom={handleCreateRoom}
        />
      )}

      {isEditModalOpen && (
        <EditRoomModal
          open={isEditModalOpen}
          handleClose={handleCloseEditModal}
          roomData={editingRoom}
          handleEditRoom={handleEditRoom}
        />
      )}

      {openAddRoomsModal && (
        <AddRoomsModal
          open={openAddRoomsModal}
          handleClose={() => setOpenAddRoomsModal(false)}
          availableRooms={availableRooms}
          handleAddRooms={handleAddRooms}
          existingRooms={selectedRooms}
        />
      )}

      {openCreateCustomerModal && (
        <CreateCustomerModal
          open={openCreateCustomerModal}
          handleClose={() => setOpenCreateCustomerModal(false)}
          handleCreateCustomer={handleCreateCustomer}
        />
      )}

      {openAddCleanerModal && (
        <AddCleanerModal
          open={openAddCleanerModal}
          handleClose={() => setOpenAddCleanerModal(false)}
          handleAddCleaner={handleAddCleaner}
        />
      )}
    </div>
  );
};

export default CreateJobForm;