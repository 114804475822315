import React from 'react';
import darkLogo from '../dmlogo.png';
import lightLogo from '../logo.png';

const AuthHeader = ({ isDarkMode, toggleDarkMode }) => {
  return (
    <header className={`auth-header ${isDarkMode ? 'dark-mode' : ''}`}>
      <img 
        src={isDarkMode ? darkLogo : lightLogo} 
        alt="Gem City Sales Tools" 
        className="logo"
      />
      <label className="switch">
        <input type="checkbox" checked={isDarkMode} onChange={toggleDarkMode} />
        <span className="slider round">
          <span className="icon">{isDarkMode ? '🌙' : '☀️'}</span>
        </span>
      </label>
    </header>
  );
};

export default AuthHeader;