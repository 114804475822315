import React, { useState, useEffect } from 'react';
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Box, 
  Typography,
  Grid 
} from '@mui/material';

const RecurrenceSelect = ({ value, onChange }) => {
  // Parse initial value if exists
  const parseInitialValue = (valueString) => {
    if (!valueString) return {};
    
    try {
      // Handle existing RRULE format
      if (valueString.startsWith('FREQ=')) {
        const freq = valueString.match(/FREQ=([^;]*)/)?.[1] || '';
        const interval = valueString.match(/INTERVAL=([^;]*)/)?.[1] || '1';
        const byday = valueString.match(/BYDAY=([^;]*)/)?.[1];
        
        // Map frequency and interval back to pattern
        let pattern = '';
        if (freq === 'WEEKLY') {
          switch (interval) {
            case '1': pattern = 'WEEKLY'; break;
            case '2': pattern = 'BIWEEKLY'; break;
            case '3': pattern = 'EVERY_3_WEEKS'; break;
            case '4': pattern = 'EVERY_4_WEEKS'; break;
            case '5': pattern = 'EVERY_5_WEEKS'; break;
            case '6': pattern = 'EVERY_6_WEEKS'; break;
            case '7': pattern = 'EVERY_7_WEEKS'; break;
            case '8': pattern = 'EVERY_8_WEEKS'; break;
            case '12': pattern = 'EVERY_12_WEEKS'; break;
          }
        } else if (freq === 'MONTHLY') {
          pattern = interval === '1' ? 'MONTHLY' : 
                   interval === '2' ? 'BIMONTHLY' : 
                   interval === '3' ? 'QUARTERLY' : '';
        }
        
        // Parse position and weekday from BYDAY if present
        let position = 'first';
        let weekday = 'monday';
        if (byday) {
          const match = byday.match(/(\d)([A-Z]{2})/);
          if (match) {
            const posNum = parseInt(match[1]);
            const day = match[2];
            
            position = ['first', 'second', 'third', 'fourth', 'last'][posNum - 1] || 'first';
            weekday = {
              'MO': 'monday', 'TU': 'tuesday', 'WE': 'wednesday',
              'TH': 'thursday', 'FR': 'friday', 'SA': 'saturday',
              'SU': 'sunday'
            }[day] || 'monday';
          }
        }
        
        return { pattern, position, weekday };
      }
      
      // Handle legacy format if needed
      const [patternValue, positionValue, weekdayValue] = valueString.split(' | ');
      return {
        pattern: patternValue || '',
        position: positionValue || 'first',
        weekday: weekdayValue || 'monday'
      };
    } catch (error) {
      console.error('Error parsing recurrence value:', error);
      return {};
    }
  };

  const initialValues = parseInitialValue(value);
  const [pattern, setPattern] = useState(initialValues.pattern);
  const [position, setPosition] = useState(initialValues.position);
  const [weekday, setWeekday] = useState(initialValues.weekday);

  const patterns = [
    { value: 'WEEKLY', label: 'Weekly', rrule: 'FREQ=WEEKLY;INTERVAL=1' },
    { value: 'BIWEEKLY', label: 'Every 2 weeks', rrule: 'FREQ=WEEKLY;INTERVAL=2' },
    { value: 'EVERY_3_WEEKS', label: 'Every 3 weeks', rrule: 'FREQ=WEEKLY;INTERVAL=3' },
    { value: 'EVERY_4_WEEKS', label: 'Every 4 weeks', rrule: 'FREQ=WEEKLY;INTERVAL=4' },
    { value: 'EVERY_5_WEEKS', label: 'Every 5 weeks', rrule: 'FREQ=WEEKLY;INTERVAL=5' },
    { value: 'EVERY_6_WEEKS', label: 'Every 6 weeks', rrule: 'FREQ=WEEKLY;INTERVAL=6' },
    { value: 'EVERY_7_WEEKS', label: 'Every 7 weeks', rrule: 'FREQ=WEEKLY;INTERVAL=7' },
    { value: 'EVERY_8_WEEKS', label: 'Every 8 weeks', rrule: 'FREQ=WEEKLY;INTERVAL=8' },
    { value: 'MONTHLY', label: 'Monthly', rrule: 'FREQ=MONTHLY;INTERVAL=1' },
    { value: 'BIMONTHLY', label: 'Bi-monthly', rrule: 'FREQ=MONTHLY;INTERVAL=2' },
    { value: 'EVERY_12_WEEKS', label: 'Every 12 weeks', rrule: 'FREQ=WEEKLY;INTERVAL=12' },
    { value: 'QUARTERLY', label: 'Quarterly', rrule: 'FREQ=MONTHLY;INTERVAL=3' }
  ];

  const positions = [
    { value: 'first', label: 'First', rrule: '1' },
    { value: 'second', label: 'Second', rrule: '2' },
    { value: 'third', label: 'Third', rrule: '3' },
    { value: 'fourth', label: 'Fourth', rrule: '4' },
    { value: 'last', label: 'Last', rrule: '-1' }
  ];

  const weekdays = [
    { value: 'monday', label: 'Monday', rrule: 'MO' },
    { value: 'tuesday', label: 'Tuesday', rrule: 'TU' },
    { value: 'wednesday', label: 'Wednesday', rrule: 'WE' },
    { value: 'thursday', label: 'Thursday', rrule: 'TH' },
    { value: 'friday', label: 'Friday', rrule: 'FR' },
    { value: 'saturday', label: 'Saturday', rrule: 'SA' },
    { value: 'sunday', label: 'Sunday', rrule: 'SU' }
  ];

  const generateRRule = (patternValue, positionValue, weekdayValue) => {
    const selectedPattern = patterns.find(p => p.value === patternValue);
    if (!selectedPattern) return '';

    let rrule = selectedPattern.rrule;

    if (['MONTHLY', 'BIMONTHLY', 'QUARTERLY'].includes(patternValue)) {
      const selectedWeekday = weekdays.find(w => w.value === weekdayValue);
      const selectedPosition = positions.find(p => p.value === positionValue);
      if (selectedWeekday && selectedPosition) {
        rrule += `;BYDAY=${selectedPosition.rrule}${selectedWeekday.rrule}`;
      }
    }

    return rrule;
  };

  useEffect(() => {
    if (pattern) {
      const rrule = generateRRule(pattern, position, weekday);
      onChange(rrule);
    }
  }, [pattern, position, weekday]);

  const handlePatternChange = (newPattern) => {
    setPattern(newPattern);
    if (!['MONTHLY', 'BIMONTHLY', 'QUARTERLY'].includes(newPattern)) {
      setPosition('first');
      setWeekday('monday');
    }
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Frequency</InputLabel>
            <Select
              value={pattern}
              label="Frequency"
              onChange={(e) => handlePatternChange(e.target.value)}
            >
              <MenuItem value="">
                <em>Select Frequency</em>
              </MenuItem>
              {patterns.map(p => (
                <MenuItem key={p.value} value={p.value}>
                  {p.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {['MONTHLY', 'BIMONTHLY', 'QUARTERLY'].includes(pattern) && (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Position</InputLabel>
                <Select
                  value={position}
                  label="Position"
                  onChange={(e) => setPosition(e.target.value)}
                >
                  {positions.map(pos => (
                    <MenuItem key={pos.value} value={pos.value}>
                      {pos.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Day of Week</InputLabel>
                <Select
                  value={weekday}
                  label="Day of Week"
                  onChange={(e) => setWeekday(e.target.value)}
                >
                  {weekdays.map(day => (
                    <MenuItem key={day.value} value={day.value}>
                      {day.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>

      {pattern && (
        <Typography sx={{ mt: 2, color: 'text.secondary' }}>
          {`This job will repeat ${patterns.find(p => p.value === pattern).label.toLowerCase()}` +
           (['MONTHLY', 'BIMONTHLY', 'QUARTERLY'].includes(pattern) 
            ? ` on the ${position} ${weekday} of the month`
            : '')}
        </Typography>
      )}
    </Box>
  );
};

export default RecurrenceSelect;