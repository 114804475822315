// src/config.js

const config = {
  hlApiToken: process.env.REACT_APP_HL_API_TOKEN,
  hlLocationId: process.env.REACT_APP_HL_LOCATION_ID,
  hlBaseUrl: process.env.REACT_APP_HL_BASE_URL,
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  openaiApiKey: process.env.REACT_APP_OPENAI_API_KEY,
  vectorStoreId: process.env.REACT_APP_VECTOR_STORE_ID,
  deepLApiKey: process.env.REACT_APP_DEEPL_API_KEY,
  baseAddress: "278 Mertland Ave, Dayton, OH 45431",
};

module.exports = config;
