// ./src/hooks/useOpportunityDetails.js

import { useState, useEffect } from 'react';
import { fetchOpportunityDetails } from '../utils/api';

const useOpportunityDetails = (contactIds, stages) => {
  const [opportunityDetails, setOpportunityDetails] = useState({});
  const [hasFetched, setHasFetched] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!hasFetched && contactIds.length > 0) {
      const fetchAllOpportunities = async () => {
        try {
          const details = {};
          for (let contactId of contactIds) {
            const opportunity = await fetchOpportunityDetails(contactId);
            if (opportunity) {
              // Map stageId to stageName using the stages map
              opportunity.stageName = stages[opportunity.stageId] || 'Unknown Stage';
            }
            details[contactId] = opportunity || null;
          }
          setOpportunityDetails(details);
          setHasFetched(true);
        } catch (err) {
          console.error('Error fetching opportunity details:', err);
          setError(err);
          setHasFetched(true);
        }
      };

      fetchAllOpportunities();
    }
  }, [contactIds, hasFetched, stages]);

  return { opportunityDetails, error };
};

export default useOpportunityDetails;
