// /src/components/CleanerJobView.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase';
import { Card, CardContent, Typography, Checkbox, Button, TextField, FormControlLabel, Box, CircularProgress, Dialog, DialogTitle, 
    DialogContent, DialogActions, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useJobContext } from '../context/JobContext';
import styles from'../styles/CleanerJobView.module.css';
import MobileHeader from './MobileHeader';

const CleanerJobView = () => {
  // Extract both orgId and jobId from URL parameters
  const { orgId, jobId } = useParams();
  console.log(`URL Parameters - orgId: ${orgId}, jobId: ${jobId}`);

  const [user, setUser] = useState(null); // Define user state
  const [job, setJob] = useState(null);
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isStarted, setIsStarted] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { startJob, completeJob } = useJobContext();

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("Auth state changed. Current user:", currentUser ? currentUser.uid : "No user");
      setUser(currentUser);
      if (currentUser) {
        // Optionally, verify user's membership in the organization here
        fetchJob(currentUser.uid);
      } else {
        setLoading(false);
        setError("User not authenticated");
      }
    });

    return () => unsubscribe();
  }, [orgId, jobId]);

  // Fetch job data
  const fetchJob = async (userId) => {
    if (orgId && jobId) {
      try {
        console.log(`Fetching job with jobId: ${jobId} under orgId: ${orgId}`);
        const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
        console.log(`Job Reference Path: organizations/${orgId}/jobs/${jobId}`);

        const jobSnap = await getDoc(jobRef);
        console.log('Job document fetched from Firestore.');

        if (jobSnap.exists()) {
          const jobData = { id: jobSnap.id, ...jobSnap.data() };
          console.log('Job Data:', jobData);

          // Ensure rooms is an array; default to empty array if undefined
          jobData.rooms = Array.isArray(jobData.rooms) ? jobData.rooms : [];
          
          setJob(jobData);
          setIsStarted(!!jobData.startTime);
          setIsCompleted(jobData.status === 'completed');
          console.log(`Job Status - Started: ${isStarted}, Completed: ${isCompleted}`);
        } else {
          console.error('No such job exists.');
          setError('Job not found.');
        }
      } catch (err) {
        console.error('Error fetching job:', err);
        setError('Error fetching job: ' + err.message);
      } finally {
        setLoading(false);
        console.log('Job fetch process completed.');
      }
    } else {
      console.error('orgId or jobId is missing from URL parameters.');
      setError('Invalid URL parameters.');
      setLoading(false);
    }
  };

  // Handle room completion toggle
  const handleCheckRoom = async (roomId) => {
    if (!job || !orgId) {
      console.error('Job data or orgId is missing.');
      return;
    }

    console.log(`Toggling completion status for roomId: ${roomId}`);
    const updatedRooms = job.rooms.map(room => 
      room.id === roomId ? { ...room, completed: !room.completed } : room
    );
    console.log('Updated Rooms:', updatedRooms);

    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      await updateDoc(jobRef, { rooms: updatedRooms });
      console.log(`Room ${roomId} updated successfully in Firestore.`);
      setJob({ ...job, rooms: updatedRooms });
    } catch (err) {
      console.error('Error updating room:', err);
      setError('Error updating room: ' + err.message);
    }
  };

  // Handle starting the job
  const handleStartJob = async () => {
    if (!job || !orgId) {
      console.error("Job or orgId is missing");
      return;
    }
    console.log(`Starting job: ${jobId} for org: ${orgId}`);
    try {
      await startJob(orgId, jobId);
      setIsStarted(true);
      console.log("Job started successfully");
    } catch (err) {
      setError('Error starting job: ' + err.message);
      console.error("Error starting job:", err);
    }
  };

  // Handle opening the completion confirmation dialog
  const handleCompleteJob = () => {
    console.log('Opening confirmation dialog for job completion.');
    setOpenDialog(true);
  };

  // Handle confirming job completion
  const handleConfirmComplete = async () => {
    if (!job || !orgId) {
      console.error("Job or orgId is missing");
      return;
    }
    console.log(`Completing job: ${jobId} for org: ${orgId} with notes: ${notes}`);
    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      const jobSnap = await getDoc(jobRef);
      if (!jobSnap.exists()) {
        throw new Error('Job not found during completion.');
      }

      const jobData = jobSnap.data();

      // Ensure startTime exists and is a Firestore Timestamp
      if (!jobData.startTime || !jobData.startTime.toMillis) {
        throw new Error('Invalid startTime in job data.');
      }

      const startTimeMillis = jobData.startTime.toMillis();
      const endTimeMillis = new Date().getTime();
      const durationMillis = endTimeMillis - startTimeMillis;
      const durationHours = durationMillis / (1000 * 60 * 60);
      const durationDecimal = parseFloat(durationHours.toFixed(2));

      console.log(`Calculated Duration: ${durationDecimal} hours`);

      // Update the job document with endTime, status, notes, and duration
      await updateDoc(jobRef, { 
        endTime: serverTimestamp(), 
        status: 'completed', 
        notes,
        duration: durationDecimal
      });
      setIsCompleted(true);
      console.log("Job completed successfully");
      setOpenDialog(false);
    } catch (err) {
      console.error("Error completing job:", err);
      setError('Error completing job: ' + err.message);
    }
  };

  const handleTaskChange = async (roomId, taskIndex, completed) => {
    if (!job || !orgId) {
      console.error('Job data or orgId is missing.');
      return;
    }
  
    try {
      const updatedRooms = job.rooms.map(room => {
        if (room.id === roomId) {
          const updatedTasks = [...room.tasks];
          updatedTasks[taskIndex] = {
            ...updatedTasks[taskIndex],
            completed: completed
          };
          return { ...room, tasks: updatedTasks };
        }
        return room;
      });
  
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      await updateDoc(jobRef, { rooms: updatedRooms });
      setJob({ ...job, rooms: updatedRooms });
    } catch (err) {
      console.error('Error updating task:', err);
      setError('Error updating task: ' + err.message);
    }
  };

  // Render loading state
  if (loading) {
    console.log('Loading job data...');
    return (
      <Box className={`${styles.cleanerJobView} ${styles.loadingContainer}`}>
        <CircularProgress />
      </Box>
    );
  }

  // Render error state
  if (error) {
    console.error('Rendering error message:', error);
    return (
      <Box className={`${styles.cleanerJobView} ${styles.errorContainer}`}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  // Render no job found state
  if (!job) {
    console.warn('No job data available to display.');
    return (
      <Box className={`${styles.cleanerJobView} ${styles.noJobContainer}`}>
        <Typography>No job found.</Typography>
      </Box>
    );
  }

  // Main render
  return (
    <div className={styles.cleanerJobView}>
      <MobileHeader title={job?.customerName || 'Job Details'} />
      <Card className={styles.jobCard}>
        <CardContent>
          <Typography variant="h5" className={styles.customerName}>{job.customerName}</Typography>
          <Typography variant="body2" className={styles.address}>{job.address}</Typography>
          
          <Typography variant="h6" className={styles.sectionTitle}>Job Details:</Typography>
          <Typography>
            <strong>Appointment:</strong> {job.appointmentDate.toDate().toLocaleString()}
          </Typography>
          <Typography>
            <strong>Status:</strong> {job.status}
          </Typography>
          
          <Typography variant="h6" className={styles.sectionTitle}>Rooms to Clean:</Typography>
          {job.rooms && job.rooms.length > 0 ? (
            job.rooms.map(room => (
              <Accordion key={room.id} className={styles.roomAccordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={room.completed}
                        onChange={() => handleCheckRoom(room.id)}
                        disabled={isCompleted || !isStarted}
                      />
                    }
                    label={room.name}
                    className={styles.roomLabel}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{room.description}</Typography>
                  {/* Check if room.tasks exists and is an array */}
                  {Array.isArray(room.tasks) && room.tasks.length > 0 ? (
                  <ul className={styles.taskList}>
                    {room.tasks.map((task, index) => (
                      <li key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={task.completed || false}
                              onChange={() => handleTaskChange(room.id, index, !task.completed)}
                              disabled={isCompleted || !isStarted}
                            />
                          }
                          label={task.description || task} // Fallback to task if it's a string
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography>No tasks assigned to this room.</Typography>
                )}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography>No rooms assigned to this job.</Typography>
          )}
          
          <Box className={styles.notesSection}>
            <Typography variant="h6" className={styles.sectionTitle}>Notes:</Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              disabled={isCompleted || !isStarted}
              className={styles.notesField}
            />
          </Box>

          <Typography variant="h6" className={styles.sectionTitle}>Notes:</Typography>
          {job.notes && (
            <Typography className={styles.notes}>
              <strong>General Notes:</strong> {job.notes}
            </Typography>
          )}
          {job.importantNotes && (
            <Typography className={styles.importantNotes}>
              <strong>Important Notes:</strong> {job.importantNotes}
            </Typography>
          )}
          
          <Box className={styles.notesSection}>
            <Typography variant="h6" className={styles.sectionTitle}>Cleaner Notes:</Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              disabled={isCompleted || !isStarted}
              className={styles.notesField}
            />
          </Box>
          
          <Box className={styles.actionButtons}>
            {!isStarted && (
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleStartJob}
                className={styles.startButton}
              >
                Start Job
              </Button>
            )}
            {isStarted && !isCompleted && (
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleCompleteJob}
                className={styles.completeButton}
              >
                Complete Job
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
  
      {/* Confirmation Dialog for Job Completion */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Job Completion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to mark this job as completed?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} className={styles.cancelButton}>Cancel</Button>
          <Button onClick={handleConfirmComplete} color="primary" className={styles.confirmButton}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CleanerJobView;
