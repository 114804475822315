import React, { useState } from 'react';
import { Button, Typography, Box, Alert } from '@mui/material';
import Papa from 'papaparse';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

const CSVUpload = ({ orgId, onRoomsCreated }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const processCSVData = (data) => {
    console.log('Processing CSV data:', data);
    const rooms = {};
    
    data.forEach((row, index) => {
      console.log(`Processing row ${index}:`, row);
      
      if (!row.RoomName && !row.TaskDescription) {
        console.log('Skipping empty row');
        return;
      }

      const roomName = row.RoomName?.trim();
      const taskDescription = row.TaskDescription?.trim();

      if (roomName && !rooms[roomName]) {
        console.log(`Creating new room: ${roomName}`);
        rooms[roomName] = {
          name: roomName,
          tasks: [],
          createdAt: new Date(),
          updatedAt: new Date()
        };
      }

      if (roomName && taskDescription) {
        console.log(`Adding task "${taskDescription}" to room "${roomName}"`);
        if (!rooms[roomName]) {
          rooms[roomName] = { 
            name: roomName, 
            tasks: [],
            createdAt: new Date(),
            updatedAt: new Date()
          };
        }
        rooms[roomName].tasks.push({
          description: taskDescription,
          completed: false
        });
      }
    });

    const roomsArray = Object.values(rooms);
    console.log('Processed rooms:', roomsArray);
    return roomsArray;
  };

  const uploadRoomsToFirestore = async (rooms) => {
    console.log('Uploading rooms to Firestore for org:', orgId);
    
    try {
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      
      // Upload each room
      const uploadPromises = rooms.map(async (room) => {
        // Check if room with same name already exists
        const q = query(roomTypesRef, where("name", "==", room.name));
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          console.log(`Adding new room type: ${room.name}`);
          const docRef = await addDoc(roomTypesRef, room);
          console.log('Room added with ID:', docRef.id);
          return docRef.id;
        } else {
          console.log(`Room ${room.name} already exists, skipping`);
          return null;
        }
      });

      const results = await Promise.all(uploadPromises);
      const addedRooms = results.filter(id => id !== null);
      console.log(`Successfully added ${addedRooms.length} new rooms`);
      
      return addedRooms.length;
    } catch (error) {
      console.error('Error uploading to Firestore:', error);
      throw error;
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setError(null);
    setSuccess(null);
    setLoading(true);
    
    if (!file) {
      console.log('No file selected');
      setLoading(false);
      return;
    }

    if (!orgId) {
      setError('Organization ID is required');
      setLoading(false);
      return;
    }

    console.log('Starting file upload:', file.name);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        console.log('Papa Parse results:', results);
        
        try {
          if (results.errors.length > 0) {
            throw new Error('Error parsing CSV file. Please check the format.');
          }

          if (!results.data || results.data.length === 0) {
            throw new Error('No data found in CSV file.');
          }

          if (!results.meta.fields.includes('RoomName') || !results.meta.fields.includes('TaskDescription')) {
            throw new Error('CSV must include "RoomName" and "TaskDescription" columns.');
          }

          const rooms = processCSVData(results.data);
          
          if (rooms.length === 0) {
            throw new Error('No valid rooms found in CSV file.');
          }

          // Upload to Firestore
          const addedCount = await uploadRoomsToFirestore(rooms);
          
          console.log('Calling onRoomsCreated with processed rooms:', rooms);
          onRoomsCreated(rooms);
          setSuccess(`Successfully uploaded ${addedCount} new rooms to database.`);
          
          // Reset input
          event.target.value = '';
          
        } catch (err) {
          console.error('Error processing CSV data:', err);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      },
      error: (error) => {
        console.error('Papa Parse error:', error);
        setError('Error reading CSV file: ' + error.message);
        setLoading(false);
      }
    });
  };

  return (
    <Box>
      <Button 
        variant="contained" 
        component="label"
        disabled={loading}
      >
        {loading ? 'Uploading...' : 'Upload CSV'}
        <input 
          type="file" 
          hidden 
          accept=".csv" 
          onChange={handleFileUpload}
          disabled={loading}
        />
      </Button>
      
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {success}
        </Alert>
      )}
      
      <Typography variant="body2" sx={{ mt: 2 }}>
        CSV should have headers: RoomName, TaskDescription
      </Typography>
    </Box>
  );
};

export default CSVUpload;