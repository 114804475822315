// src/components/AddRoomsModal.js

import React, { useState, useEffect } from 'react';
import { ChevronDown, Edit2, Trash2 } from 'lucide-react';
import styles from '../styles/AddRoomsModal.module.css';

const AddRoomsModal = ({ open, handleClose, availableRooms = [], handleAddRooms, existingRooms = [], className }) => {
  const [selectedRooms, setSelectedRooms] = useState({});
  const [editMode, setEditMode] = useState({});
  const [accordionState, setAccordionState] = useState({});

  // Initialize selected rooms with available rooms' tasks
  useEffect(() => {
    const initialSelectedRooms = {};
    
    // First, initialize with available rooms and their default tasks
    availableRooms.forEach(room => {
      initialSelectedRooms[room.name] = {
        ...room,
        quantity: 0,
        tasks: room.tasks?.map(task => ({
          description: typeof task === 'string' ? task : task.description,
          completed: false
        })) || []
      };
    });

    // Then overlay any existing room data
    if (Array.isArray(existingRooms)) {
      existingRooms.forEach(room => {
        if (initialSelectedRooms[room.name]) {
          initialSelectedRooms[room.name] = {
            ...initialSelectedRooms[room.name],
            ...room,
            quantity: room.quantity || 1,
            tasks: room.tasks || initialSelectedRooms[room.name].tasks
          };
        }
      });
    }

    setSelectedRooms(initialSelectedRooms);
  }, [availableRooms, existingRooms]);

  const handleRoomSelect = (room, quantity) => {
    setSelectedRooms(prev => {
      // When selecting a room, ensure we're preserving the original tasks
      const existingRoom = prev[room.name];
      const tasks = existingRoom?.tasks || room.tasks?.map(task => ({
        description: typeof task === 'string' ? task : task.description,
        completed: false
      })) || [];

      return {
        ...prev,
        [room.name]: {
          ...room,
          quantity: quantity || 0,
          tasks: tasks
        }
      };
    });

    // Automatically open accordion when room is selected
    if (quantity > 0) {
      setAccordionState(prev => ({
        ...prev,
        [room.name]: true
      }));
    }
  };

  const toggleAccordion = (roomName) => {
    setAccordionState(prev => ({
      ...prev,
      [roomName]: !prev[roomName]
    }));
  };

  const handleEditRoom = (roomName) => {
    setEditMode(prev => ({ ...prev, [roomName]: true }));
  };

  const handleSaveEdit = (roomName) => {
    setEditMode(prev => ({ ...prev, [roomName]: false }));
  };

  const handleDeleteRoom = (roomName) => {
    setSelectedRooms(prev => {
      const newRooms = { ...prev };
      delete newRooms[roomName];
      return newRooms;
    });
  };

  const handleTaskChange = (roomName, taskIndex, newDescription) => {
    setSelectedRooms(prev => ({
      ...prev,
      [roomName]: {
        ...prev[roomName],
        tasks: prev[roomName].tasks.map((task, index) => 
          index === taskIndex ? { ...task, description: newDescription } : task
        )
      }
    }));
  };

  const handleSubmit = () => {
    const roomsToAdd = Object.values(selectedRooms)
      .filter(room => room.quantity > 0)
      .map(room => ({
        ...room,
        tasks: room.tasks.map(task => ({
          description: typeof task === 'string' ? task : task.description,
          completed: false
        }))
      }));
    handleAddRooms(roomsToAdd);
    handleClose();
  };

  if (!open) return null;

  return (
    <div className={`${styles.modalOverlay} ${className || ''}`}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>Add/Edit Rooms</h2>
          <button onClick={handleClose} className={styles.closeButton}>&times;</button>
        </div>
        
        <div className={styles.modalContent}>
          {availableRooms.length > 0 ? (
            <div className={styles.roomsList}>
              {availableRooms.map((room) => (
                <div key={room.name} className={styles.roomItem}>
                  <div 
                    className={styles.roomHeader}
                    onClick={() => toggleAccordion(room.name)}
                  >
                    <div className={styles.roomInfo}>
                      <input
                        type="checkbox"
                        checked={selectedRooms[room.name]?.quantity > 0}
                        onChange={(e) => handleRoomSelect(room, e.target.checked ? 1 : 0)}
                        className={styles.checkbox}
                      />
                      <span className={styles.roomName}>{room.name}</span>
                    </div>

                    <div className={styles.roomControls}>
                      <input
                        type="number"
                        value={selectedRooms[room.name]?.quantity || 0}
                        onChange={(e) => handleRoomSelect(room, parseInt(e.target.value) || 0)}
                        disabled={!selectedRooms[room.name]}
                        className={styles.quantityInput}
                        min="0"
                      />
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditRoom(room.name);
                        }}
                        className={styles.iconButton}
                      >
                        <Edit2 size={16} />
                      </button>
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteRoom(room.name);
                        }}
                        className={styles.iconButton}
                      >
                        <Trash2 size={16} />
                      </button>
                      <ChevronDown 
                        size={16} 
                        className={`${styles.chevron} ${accordionState[room.name] ? styles.chevronOpen : ''}`} 
                      />
                    </div>
                  </div>

                  {accordionState[room.name] && (
                    <div className={styles.roomContent}>
                      <h4 className={styles.tasksTitle}>Tasks:</h4>
                      <div className={styles.tasksList}>
                        {selectedRooms[room.name]?.tasks?.length > 0 ? (
                          selectedRooms[room.name].tasks.map((task, index) => (
                            <input
                              key={index}
                              type="text"
                              value={typeof task === 'string' ? task : task.description}
                              onChange={(e) => handleTaskChange(room.name, index, e.target.value)}
                              disabled={!editMode[room.name]}
                              className={styles.taskInput}
                            />
                          ))
                        ) : (
                          <p className={styles.noTasks}>No tasks assigned to this room.</p>
                        )}
                      </div>
                      {editMode[room.name] && (
                        <button 
                          onClick={() => handleSaveEdit(room.name)}
                          className={`${styles.button} ${styles.secondaryButton}`}
                        >
                          Save Changes
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className={styles.noRooms}>No available rooms to add.</p>
          )}
        </div>

        <div className={styles.modalFooter}>
          <button onClick={handleClose} className={`${styles.button} ${styles.secondaryButton}`}>
            Cancel
          </button>
          <button onClick={handleSubmit} className={`${styles.button} ${styles.primaryButton}`}>
            Add/Update Rooms
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddRoomsModal;